// @ts-check
import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from 'undercurrent/Stack';
import combineSx from 'undercurrent/utility/combineSx';

/** @param {import('@mui/material').StackOwnProps} props */
const SuspenseFallback = ({ children, sx, ...rest }) => (
	<Stack
		sx={combineSx(
			{
				justifyContent: 'center',
				alignItems: 'center',
				flexGrow: 1,
			},
			sx,
		)}
		{...rest}
	>
		<CircularProgress />

		{children}
	</Stack>
);

export default SuspenseFallback;
