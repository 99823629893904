// @ts-check
import React from 'react';

import Skeleton from '@mui/material/Skeleton';

const AttributesLoader = () => {
	return (
		<>
			<Skeleton
				variant="rounded"
				sx={(theme) => ({ height: theme.size.sm, width: theme.size.xXL })}
			/>
			<Skeleton
				variant="rounded"
				sx={(theme) => ({ height: theme.size.sm, width: theme.size.xXL })}
			/>
			<Skeleton
				variant="rounded"
				sx={(theme) => ({ height: theme.size.sm, width: theme.size.xXL })}
			/>
			<Skeleton
				variant="rounded"
				sx={(theme) => ({ height: theme.size.sm, width: theme.size.xXL })}
			/>
		</>
	);
};

export default AttributesLoader;
