// @ts-check
import React, { useState } from 'react';

import Box from 'undercurrent/Box';
import Stack from 'undercurrent/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import DrawerSupportCall from 'components/standard/DrawerSupportCall';
import PageLayout from 'components/standard/PageLayout';
import TabBar from 'components/standard/TabBar';
import { useLeftHandLayout } from 'containers/LeftHandLayout';
import { Link as RouterLink } from 'react-router-dom';
import Button from 'undercurrent/Button';
import MessageSquareIcon from 'undercurrent/icons/lucide/MessageSquare';
import startChatProgrammatically from 'utility/chat/startChatProgrammatically';

/**
 * A layout for support pages.
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {'/support' | '/support/status' | '/support/mycases'} props.tabsValue
 * @param {boolean} [props.isLoading]
 */
const SupportLayout = ({ children, tabsValue, isLoading }) => {
	const { topInnerX } = useLeftHandLayout();

	const [isSupportCallOpen, setIsSupportCallOpen] = useState(false);

	return (
		<PageLayout
			title="Support Center"
			actionsSlot={
				<Box sx={{ display: 'flex', gap: 100 }}>
					<Button
						startIcon={<MessageSquareIcon />}
						onClick={startChatProgrammatically}
						sx={{ flex: { xs: 1, md: 'none' } }}
					>
						Chat with us
					</Button>
					<Button
						variant="secondary"
						onClick={() => setIsSupportCallOpen(true)}
						sx={{ flex: { xs: 1, md: 'none' } }}
					>
						Call us
					</Button>
				</Box>
			}
			isLoading={isLoading}
		>
			<TabBar sx={{ marginBottom: 600 }} isLoading={isLoading}>
				<Tabs value={tabsValue} variant="scrollable">
					<Tab
						component={RouterLink}
						value="/support"
						to="/support"
						label="Common issues"
					/>
					<Tab
						component={RouterLink}
						value="/support/status"
						to="/support/status"
						label="Service status"
					/>
					<Tab
						component={RouterLink}
						value="/support/mycases"
						to="/support/mycases"
						label="My cases"
					/>
				</Tabs>
			</TabBar>
			<Stack sx={{ paddingX: { xs: `${topInnerX}px`, md: 0 }, flexGrow: 1 }}>
				{children}
			</Stack>

			<DrawerSupportCall
				open={isSupportCallOpen}
				onClose={() => setIsSupportCallOpen(false)}
			/>
		</PageLayout>
	);
};

export default SupportLayout;
