// @ts-check
import React from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import FullScreenError from 'components/molecules/FullScreenError';
import Actions from 'components/standard/HostingDetailsActions';
import Attributes from 'components/standard/HostingDetailsAttributes';
import PageLayout from 'components/standard/PageLayout';
import TabBar from 'components/standard/TabBar';
import { useLeftHandLayout } from 'containers/LeftHandLayout';
import hostingDetailsAlsowith from 'containers/pages/servers/details/hostingDetailsAlsowith';
import { usePollAssetDetails } from 'modules/queries/asset/useDetails';
import NotFound from 'pages/error/NotFound';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import Stack from 'undercurrent/Stack';
import { getErrorStringHelper } from 'utility/redux/selectorHelperFunctions/apiModules';

/**
 * A layout for hosting details pages.
 * @param {object} props
 * @param {React.ReactNode} props.children
 */
const HostingDetailsLayout = ({ children }) => {
	/** @type {{uniqId: string}} */
	const { uniqId } = useParams();
	const { pathname } = useLocation();
	const {
		data: assetDetailsData,
		isLoading: assetDetailsIsLoading,
		isError: assetDetailsIsError,
		error: assetDetailsError,
	} = usePollAssetDetails({
		uniq_id: uniqId,
		alsowith: hostingDetailsAlsowith,
	});

	const { topInnerX } = useLeftHandLayout();

	if (
		assetDetailsIsError &&
		// @ts-expect-error
		assetDetailsError.statusCode === 404
	) {
		return <NotFound />;
	}

	if (assetDetailsIsError) {
		return (
			<FullScreenError content={getErrorStringHelper(assetDetailsIsError)} />
		);
	}

	const { domain, assetType, canBackupsAndImages, canConsole, disableConsole } =
		assetDetailsData || {};

	const favoritesDisplayNames = {
		[`/servers/${uniqId}`]: `${domain} - Overview`,
		[`/servers/${uniqId}/backups`]: `${domain} - Backups`,
		[`/servers/${uniqId}/activity`]: `${domain} - Activity`,
		[`/servers/${uniqId}/console`]: `${domain} - Console`,
	};

	return (
		<PageLayout
			title={domain || ''}
			attributesSlot={<Attributes />}
			actionsSlot={
				<Actions favoritesDisplayName={favoritesDisplayNames[pathname]} />
			}
			isLoading={assetDetailsIsLoading}
		>
			<TabBar sx={{ marginBottom: 600 }} isLoading={assetDetailsIsLoading}>
				<Tabs value={pathname} variant="scrollable">
					<Tab
						component={RouterLink}
						value={`/servers/${uniqId}`}
						to={`/servers/${uniqId}`}
						label="Overview"
					/>
					<Tab
						component={RouterLink}
						value={`/servers/${uniqId}/monitoring`}
						to={`/servers/${uniqId}/monitoring`}
						label="Monitoring"
					/>
					<Tab
						component={RouterLink}
						value={`/servers/${uniqId}/networking`}
						to={`/servers/${uniqId}/networking`}
						label="Networking"
					/>
					{canBackupsAndImages && (
						<Tab
							component={RouterLink}
							value={`/servers/${uniqId}/backups`}
							to={`/servers/${uniqId}/backups`}
							label={assetType === 'dedicated' ? 'Backups' : 'Backups & Images'}
						/>
					)}
					<Tab
						component={RouterLink}
						value={`/servers/${uniqId}/activity`}
						to={`/servers/${uniqId}/activity`}
						label="Activity"
					/>
					{canConsole && !disableConsole && (
						<Tab
							component={RouterLink}
							value={`/servers/${uniqId}/console`}
							to={`/servers/${uniqId}/console`}
							label="Console"
						/>
					)}
				</Tabs>
			</TabBar>
			<Stack sx={{ paddingX: { xs: `${topInnerX}px`, md: 0 }, flexGrow: 1 }}>
				{children}
			</Stack>
		</PageLayout>
	);
};

export default HostingDetailsLayout;
