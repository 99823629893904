// @ts-check
import React from 'react';

import AlmaLinuxIcon from 'undercurrent/icons/platform/AlmaLinux';
import CentOSIcon from 'undercurrent/icons/platform/CentOS';
import DebianIcon from 'undercurrent/icons/platform/Debian';
import FedoraIcon from 'undercurrent/icons/platform/Fedora';
import LinuxIcon from 'undercurrent/icons/platform/Linux';
import RockyIcon from 'undercurrent/icons/platform/Rocky';
import UbuntuIcon from 'undercurrent/icons/platform/Ubuntu';
import WindowsIcon from 'undercurrent/icons/platform/Windows';

/** @param {import('undercurrent/types/svgTypes').FixedColorSvgProps & { os: import('modules/queries/asset/useDetails').AssetDetails['os'] }} props */
const OSIcon = ({ os, ...rest }) => {
	const osLowerCase = os.toLowerCase();

	if (osLowerCase.includes('alma')) {
		return <AlmaLinuxIcon {...rest} />;
	}

	if (osLowerCase.includes('centos')) {
		return <CentOSIcon {...rest} />;
	}

	if (osLowerCase.includes('debian')) {
		return <DebianIcon {...rest} />;
	}

	if (osLowerCase.includes('fedora')) {
		return <FedoraIcon {...rest} />;
	}

	if (osLowerCase.includes('rocky')) {
		return <RockyIcon {...rest} />;
	}

	if (osLowerCase.includes('ubuntu')) {
		return <UbuntuIcon {...rest} />;
	}

	if (osLowerCase.includes('windows')) {
		return <WindowsIcon {...rest} />;
	}

	if (
		osLowerCase.includes('linux') ||
		osLowerCase.includes('alpine') ||
		osLowerCase.includes('arch')
	) {
		return <LinuxIcon {...rest} />;
	}

	return null;
};

export default OSIcon;
