// @ts-check
import React from 'react';

import Box from 'undercurrent/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import Stack from 'undercurrent/Stack';
import Typography from 'undercurrent/Typography';
import { stripOsName } from 'components/standard//DrawerReimage/helpers';
import AssetTypeIcon from 'components/standard/AssetTypeIcon';
import KeySpec from 'components/standard/KeySpec';
import OSIcon from 'components/standard/OSIcon';
import CpuIcon from 'undercurrent/icons/lucide/Cpu';
import MemoryIcon from 'undercurrent/icons/lucide/MemoryStick';
import TypographyOverflow from 'undercurrent/TypographyOverflow';

/**
 * @param {import('@mui/material').CardProps & {
 *   cardHeaderTitle?: import('@mui/material').CardHeaderProps['title']
 *   cardHeaderProps?: import('@mui/material').CardHeaderProps
 * }} props
 */
const Wrapper = ({ cardHeaderTitle, cardHeaderProps, children, ...rest }) => {
	return (
		<Card {...rest}>
			{cardHeaderTitle && (
				<CardHeader title={cardHeaderTitle} {...cardHeaderProps} />
			)}

			{children}
		</Card>
	);
};

/**
 * @param {import('react').ComponentProps<typeof Wrapper> & {
 *   animate?: boolean
 * }} props
 */
const Indeterminate = ({ animate, ...props }) => {
	return (
		<Wrapper {...props}>
			<CardContent component={Stack} spacing={200}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: 100,
					}}
				>
					<Skeleton
						animation={animate ? 'pulse' : false}
						variant="rounded"
						sx={{ width: '112px', height: ({ size }) => size.sm }}
					/>

					<Skeleton
						animation={animate ? 'pulse' : false}
						variant="rounded"
						sx={{ width: '35px', height: ({ size }) => size.sm }}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						gap: 100,
					}}
				>
					<KeySpec
						label="Type"
						value={
							<Skeleton
								animation={animate ? 'pulse' : false}
								variant="rounded"
								sx={{ width: '56px', height: ({ size }) => size.xS }}
							/>
						}
						minWidth={0}
					/>
					<KeySpec
						label="OS"
						value={
							<Skeleton
								animation={animate ? 'pulse' : false}
								variant="rounded"
								sx={{ width: '56px', height: ({ size }) => size.xS }}
							/>
						}
						minWidth={0}
						maxWidth="33%"
					/>

					<KeySpec
						label="CPU"
						value={
							<Skeleton
								animation={animate ? 'pulse' : false}
								variant="rounded"
								sx={{ width: '56px', height: ({ size }) => size.xS }}
							/>
						}
						minWidth={0}
					/>

					<KeySpec
						label="RAM"
						value={
							<Skeleton
								animation={animate ? 'pulse' : false}
								variant="rounded"
								sx={{ width: '56px', height: ({ size }) => size.xS }}
							/>
						}
						minWidth={0}
					/>
				</Box>
			</CardContent>
		</Wrapper>
	);
};
/**
 * @param {object} props
 * @param {import('modules/queries/asset/useDetails').AssetDetails['assetType']} [props.assetType]
 * @param {import('@mui/material').CardHeaderProps} [props.cardHeaderProps]
 * @param {import('@mui/material').CardHeaderProps['title']} [props.cardHeaderTitle]
 * @param {import('@mui/material').CardProps} [props.cardProps]
 * @param {React.ReactNode} [props.children]
 * @param {import('modules/queries/asset/useDetails').AssetDetails['cpu']} [props.cpu]
 * @param {import('modules/queries/asset/useDetails').AssetDetails['displayType']} [props.displayType]
 * @param {import('modules/queries/asset/useDetails').AssetDetails['domain']} [props.domain]
 * @param {import('modules/queries/asset/useDetails').AssetDetails['ip']} [props.ip]
 * @param {boolean} [props.isIndeterminate] similar to loading state but skeletons do not animate
 * @param {boolean} [props.isLoading] shows skeletons instead of content
 * @param {string} [props.osName]
 * @param {string} [props.osVersion]
 * @param {import('modules/queries/asset/useDetails').AssetDetails['ramFormatted']} [props.ramFormatted]
 * @param {import('modules/queries/asset/useDetails').AssetDetails['type']} [props.type]
 */
const ServerSpecsCard = ({
	assetType,
	cardHeaderProps,
	cardHeaderTitle,
	cardProps,
	children,
	cpu,
	displayType,
	domain,
	ip,
	isIndeterminate,
	isLoading,
	osName,
	osVersion,
	ramFormatted,
	type,
}) => {
	if (isIndeterminate || isLoading) {
		return (
			<Indeterminate
				cardHeaderTitle={cardHeaderTitle}
				cardHeaderProps={cardHeaderProps}
				animate={isLoading}
				{...cardProps}
			/>
		);
	}

	const hasKeySpecs = displayType || osName || cpu || ramFormatted;
	const osNameFormatted = stripOsName(osName || '');

	return (
		<Wrapper
			cardHeaderTitle={cardHeaderTitle}
			cardHeaderProps={cardHeaderProps}
			{...cardProps}
		>
			<CardContent component={Stack} spacing={200}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: 100,
					}}
				>
					<TypographyOverflow variant="title-xs" fontWeight={500}>
						{domain}
					</TypographyOverflow>

					<Typography
						variant="body-sm"
						sx={{
							color: (theme) => theme.palette.uc.text.weak,
							flexShrink: 0,
							whiteSpace: 'nowrap',
						}}
					>
						{ip}
					</Typography>
				</Box>

				{hasKeySpecs && (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: 100,
						}}
					>
						{displayType && (
							<KeySpec
								label="Type"
								value={displayType}
								icon={
									<AssetTypeIcon
										assetType={assetType || ''}
										type={type || ''}
										sx={{ flexShrink: 0 }}
									/>
								}
								minWidth={0}
							/>
						)}

						{osName && (
							<KeySpec
								label="OS"
								value={`${osNameFormatted} ${osVersion}`}
								icon={
									<OSIcon
										os={osName}
										sx={(theme) => ({ fontSize: theme.size.xS, flexShrink: 0 })}
									/>
								}
								minWidth={0}
								maxWidth="33%"
							/>
						)}

						{cpu && (
							<KeySpec
								label="CPU"
								value={cpu}
								icon={<CpuIcon sx={{ flexShrink: 0 }} />}
								minWidth={0}
							/>
						)}

						{ramFormatted && (
							<KeySpec
								label="RAM"
								value={ramFormatted}
								icon={<MemoryIcon sx={{ flexShrink: 0 }} />}
								minWidth={0}
							/>
						)}
					</Box>
				)}
			</CardContent>
			{children}
		</Wrapper>
	);
};

export default ServerSpecsCard;
