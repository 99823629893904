// @ts-check
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSnackbarError from 'utility/hooks/useSnackbarError';
import api from 'modules/queries/api';
import useInvalidateAssets from 'modules/queries/invalidateQueries/useInvalidateAssets';

const useServerPowerReboot = () => {
	const snackbarError = useSnackbarError();
	const invalidateAssets = useInvalidateAssets();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/server/power.raw').LWApiPublicServerPowerRebootParamsRawI} params */
			(params) => api.server.power.apiRaw.reboot(params),
		onSuccess: (data, params) => {
			invalidateAssets(params.uniq_id);

			queryClient.invalidateQueries(['/usertask/list']);
		},
		onError: snackbarError,
	});
};

export default useServerPowerReboot;
