// @ts-check
import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from 'undercurrent/Typography';
import { fromApiBoolean } from 'club-sauce';
import DialogShutdownServer from 'components/standard/DialogShutdownServer';
import DialogStartServer from 'components/standard/DialogStartServer';
import DrawerCloneServer from 'components/standard/DrawerCloneServer';
import DrawerEditServerHostname from 'components/standard/DrawerEditServerHostname';
import DrawerReboot from 'components/standard/DrawerReboot';
import DrawerReimage from 'components/standard/DrawerReimage';
import DrawerSupportAccess from 'components/standard/DrawerSupportAccess';
import IconButtonMenu, {
	useIconButtonMenu,
} from 'components/standard/IconButtonMenu/IconButtonMenu';
import useVmWareVCloudVdcLoginUrl from 'modules/queries/vmWare/vCloud/vdc/useLogin';
import { useHistory } from 'react-router-dom';
import ArrowUpRightIcon from 'undercurrent/icons/lucide/ArrowUpRight';
import CopyIcon from 'undercurrent/icons/lucide/Copy';
import EllipsisIcon from 'undercurrent/icons/lucide/Ellipsis';
import HeadsetIcon from 'undercurrent/icons/lucide/Headset';
import PencilIcon from 'undercurrent/icons/lucide/Pencil';
import PowerIcon from 'undercurrent/icons/lucide/Power';
import RefreshCcwIcon from 'undercurrent/icons/lucide/RefreshCcw';
import ScalingIcon from 'undercurrent/icons/lucide/Scaling';
import TrashIcon from 'undercurrent/icons/lucide/Trash2';
import ActionsContainer from './ActionsContainer';
import FavoriteMenuItem from './FavoriteMenuItem';

/**
 * @typedef {
 * | ''
 * | 'clone'
 * | 'editHostname'
 * | 'reboot'
 * | 'reimage'
 * | 'shutdown'
 * | 'start'
 * | 'supportAccess'
 * } ActionKey
 * */

const SArrowUpRight = () => (
	<ArrowUpRightIcon
		sx={(theme) => ({ height: theme.size.xS, width: theme.size.xS })}
	/>
);
const SHeadset = () => (
	<HeadsetIcon
		sx={(theme) => ({ height: theme.size.xS, width: theme.size.xS })}
	/>
);
const SScaling = () => (
	<ScalingIcon
		sx={(theme) => ({ height: theme.size.xS, width: theme.size.xS })}
	/>
);
const SCopy = () => (
	<CopyIcon sx={(theme) => ({ height: theme.size.xS, width: theme.size.xS })} />
);
const SRefreshCcw = () => (
	<RefreshCcwIcon
		sx={(theme) => ({ height: theme.size.xS, width: theme.size.xS })}
	/>
);
const SPencil = () => (
	<PencilIcon
		sx={(theme) => ({ height: theme.size.xS, width: theme.size.xS })}
	/>
);
const SPower = () => (
	<PowerIcon
		sx={(theme) => ({ height: theme.size.xS, width: theme.size.xS })}
	/>
);
const STrash = () => (
	<TrashIcon
		sx={(theme) => ({
			height: theme.size.xS,
			width: theme.size.xS,
			color: theme.palette.uc.danger.icon.strong,
		})}
	/>
);

/** @param {import('modules/queries/asset/useDetails').AssetDetails & { favoritesDisplayName?: string }} props */
const ActionsContent = ({
	capabilities,
	domain,
	favoritesDisplayName,
	ip,
	isVmware,
	powerStatus,
	status,
	uniq_id: uniqId,
	isPrivateParent,
}) => {
	const history = useHistory();

	const [actionContentKey, setActionContentKey] = useState(
		/** @type {ActionKey} */ (''),
	);
	const { mutateAsync: fetchVmWareLogin } = useVmWareVCloudVdcLoginUrl();

	const { handleAnchorClick, handleCloseMenu, menuAnchorEl, open } =
		useIconButtonMenu();

	const isActive = status === 'Active';
	const isCpanel = fromApiBoolean(capabilities?.cpanelLogin);
	const isInterworx = fromApiBoolean(capabilities?.interworxLogin);
	const isPlesk = fromApiBoolean(capabilities?.pleskLogin);
	const autoRename = fromApiBoolean(capabilities?.autoRename);
	const manualRename = fromApiBoolean(capabilities?.manualRename);

	const showGoToSupportAccess = !fromApiBoolean(
		capabilities?.credentialsNotCustomerFacing,
	);
	const showEditHostname = !isPrivateParent && (autoRename || manualRename);
	const showResize = fromApiBoolean(capabilities?.resize);
	const showReimage = fromApiBoolean(capabilities?.reimage);
	const showCloneServer = fromApiBoolean(capabilities?.clone);
	const showStartServer = !isPrivateParent && isActive && powerStatus === 'off';
	const showShutdownServer =
		!isPrivateParent && isActive && powerStatus === 'on';
	const showDestroyServer = isActive;
	const showReboot = !isPrivateParent;

	const hasTopGroup =
		showGoToSupportAccess || isCpanel || isInterworx || isPlesk || isVmware;
	const hasBottomGroup =
		showStartServer || showShutdownServer || showDestroyServer;

	const rebootDisabled = !isActive || powerStatus !== 'on';
	const cloneDisabled = !isActive;
	const resizeDisabled = !isActive || powerStatus !== 'on';
	const reimageDisabled = !isActive || powerStatus !== 'on';

	const handleVmWareVCloudVdcLogin = async () => {
		try {
			const data = await fetchVmWareLogin({ uniq_id: uniqId });

			if (data.url) {
				window.open(data.url, '_blank', 'noopener');
			}
		} catch {
			// handled in hook definition
		}
	};

	/** @param {ActionKey} key */
	const handleMenuItemClick = (key) => {
		handleCloseMenu();
		setActionContentKey(key);
	};

	return (
		<ActionsContainer>
			{showReboot && (
				<Button
					variant="primary"
					size="medium"
					disabled={rebootDisabled}
					onClick={() => setActionContentKey('reboot')}
					sx={{ width: { xs: '100%', md: 'auto' } }}
				>
					Reboot
				</Button>
			)}

			<IconButtonMenu
				icon={
					<EllipsisIcon
						sx={(theme) => ({ height: theme.size.xS, width: theme.size.xS })}
					/>
				}
				IconButtonProps={{
					onClick: handleAnchorClick,
					sx: { flexGrow: 1 },
				}}
				MenuProps={{
					open,
					onClose: handleCloseMenu,
					anchorEl: menuAnchorEl,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				}}
			>
				{showGoToSupportAccess && (
					<MenuItem
						disabled={!isActive}
						onClick={() => handleMenuItemClick('supportAccess')}
					>
						<ListItemIcon>
							<SHeadset />
						</ListItemIcon>
						<Typography variant="label-lg">Support access</Typography>
					</MenuItem>
				)}
				{isCpanel && (
					<MenuItem
						disabled={!isActive}
						onClick={() => {
							window.open(`https://${ip}:2083`, '_blank', 'noopener');
						}}
					>
						<ListItemIcon>
							<SArrowUpRight />
						</ListItemIcon>
						<Typography variant="label-lg">Go to cPanel</Typography>
					</MenuItem>
				)}
				{isCpanel && (
					<MenuItem
						disabled={!isActive}
						onClick={() => {
							window.open(`https://${ip}:2087`, '_blank', 'noopener');
						}}
					>
						<ListItemIcon>
							<SArrowUpRight />
						</ListItemIcon>
						<Typography variant="label-lg">Go to WHM</Typography>
					</MenuItem>
				)}
				{isInterworx && (
					<MenuItem
						disabled={!isActive}
						onClick={() => {
							window.open(`https://${ip}:2443`, '_blank', 'noopener');
						}}
					>
						<ListItemIcon>
							<SArrowUpRight />
						</ListItemIcon>
						<Typography variant="label-lg">Go to Interworx</Typography>
					</MenuItem>
				)}
				{isPlesk && (
					<MenuItem
						disabled={!isActive}
						onClick={() => {
							window.open(`https://${ip}:8443`, '_blank', 'noopener');
						}}
					>
						<ListItemIcon>
							<SArrowUpRight />
						</ListItemIcon>
						<Typography variant="label-lg">Go to Plesk</Typography>
					</MenuItem>
				)}
				{isVmware && (
					<MenuItem disabled={!isActive} onClick={handleVmWareVCloudVdcLogin}>
						<ListItemIcon>
							<SArrowUpRight />
						</ListItemIcon>
						<Typography variant="label-lg">Go to VMWare</Typography>
					</MenuItem>
				)}
				{showEditHostname && (
					<MenuItem
						disabled={!isActive}
						onClick={() => handleMenuItemClick('editHostname')}
					>
						<ListItemIcon>
							<SPencil />
						</ListItemIcon>
						<Typography variant="label-lg">Edit Hostname</Typography>
					</MenuItem>
				)}
				{hasTopGroup && <Divider sx={{ marginX: { sm: -50 } }} />}

				<FavoriteMenuItem
					onClick={handleCloseMenu}
					favoritesDisplayName={favoritesDisplayName}
				/>
				{showCloneServer && (
					<MenuItem
						disabled={cloneDisabled}
						onClick={() => handleMenuItemClick('clone')}
					>
						<ListItemIcon>
							<SCopy />
						</ListItemIcon>
						<Typography variant="label-lg">Clone server</Typography>
					</MenuItem>
				)}
				{showResize && (
					<MenuItem
						disabled={resizeDisabled}
						onClick={() => {
							handleCloseMenu();
							history.push(`/servers/${uniqId}/resize`);
						}}
					>
						<ListItemIcon>
							<SScaling />
						</ListItemIcon>
						<Typography variant="label-lg">Resize</Typography>
					</MenuItem>
				)}
				{showReimage && (
					<MenuItem
						disabled={reimageDisabled}
						onClick={() => handleMenuItemClick('reimage')}
					>
						<ListItemIcon>
							<SRefreshCcw />
						</ListItemIcon>
						<Typography variant="label-lg">Reimage</Typography>
					</MenuItem>
				)}
				{hasBottomGroup && <Divider sx={{ marginX: { sm: -50 } }} />}

				{showStartServer && (
					<MenuItem onClick={() => handleMenuItemClick('start')}>
						<ListItemIcon>
							<SPower />
						</ListItemIcon>
						<Typography variant="label-lg">Start server</Typography>
					</MenuItem>
				)}
				{showShutdownServer && (
					<MenuItem onClick={() => handleMenuItemClick('shutdown')}>
						<ListItemIcon>
							<SPower />
						</ListItemIcon>
						<Typography variant="label-lg">Shutdown server</Typography>
					</MenuItem>
				)}
				{showDestroyServer && (
					<MenuItem
						onClick={() => {
							handleCloseMenu();
							history.push(`/servers/${uniqId}/destroy`);
						}}
						sx={{
							':hover, :focus-visible': {
								backgroundColor: ({ palette }) => palette.uc.danger.bg.main,
							},
						}}
					>
						<ListItemIcon>
							<STrash />
						</ListItemIcon>
						<Typography color="uc.danger.icon.strong" variant="label-lg">
							Destroy server
						</Typography>
					</MenuItem>
				)}
			</IconButtonMenu>

			<DrawerCloneServer
				open={actionContentKey === 'clone'}
				onClose={() => setActionContentKey('')}
				uniqId={uniqId}
			/>
			<DrawerReboot
				open={actionContentKey === 'reboot'}
				onClose={() => setActionContentKey('')}
				uniqId={uniqId}
			/>
			<DialogShutdownServer
				uniqId={uniqId}
				open={actionContentKey === 'shutdown'}
				onClose={() => setActionContentKey('')}
				domain={domain}
				ip={ip || ''}
			/>
			<DialogStartServer
				uniqId={uniqId}
				open={actionContentKey === 'start'}
				onClose={() => setActionContentKey('')}
				domain={domain}
				ip={ip || ''}
			/>
			<DrawerSupportAccess
				open={actionContentKey === 'supportAccess'}
				onClose={() => setActionContentKey('')}
				uniqId={uniqId}
			/>
			<DrawerEditServerHostname
				open={actionContentKey === 'editHostname'}
				onClose={() => setActionContentKey('')}
				uniqId={uniqId}
			/>
			<DrawerReimage
				open={actionContentKey === 'reimage'}
				onClose={() => setActionContentKey('')}
				uniqId={uniqId}
			/>
		</ActionsContainer>
	);
};

export default ActionsContent;
