// @ts-check
import React from 'react';

import Box from 'undercurrent/Box';

/** @param {import('@mui/material').BoxProps} props */
const ActionsContainer = (props) => {
	return (
		<Box
			sx={{
				display: 'flex',
				gap: 100,
				alignItems: 'center',
				justifyContent: 'flex-end',
			}}
			{...props}
		/>
	);
};

export default ActionsContainer;
