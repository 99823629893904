import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useInput } from 'components/LWForm/LWForm';
import FormControlLabel from 'undercurrent/FormControlLabel';

/**
 * @typedef PropsI
 * @property {string} name
 * @property {import('react').ReactNode} [helperText]
 * @property {import('@mui/material').FormControlProps} [FormControlProps]
 * @property {import('undercurrent/FormControlLabel').FormControlLabelPropsI['label']} [label]
 * @property {import('undercurrent/FormControlLabel').FormControlLabelPropsI['description']} [description]
 * @property {import('undercurrent/FormControlLabel').FormControlLabelPropsI} [FormControlLabelProps]
 * @property {import('@mui/material').FormHelperTextProps} [FormHelperTextProps]
 * */

/**
 * A checkbox input component to be used within the context of LWForm
 * @param {Omit<import('@mui/material').CheckboxProps, 'name'> & PropsI} props
 */
const HookedCheckbox = ({
	required,
	helperText,
	label,
	description,
	name,
	onBlur,
	onChange,
	FormControlProps,
	FormControlLabelProps,
	FormHelperTextProps,
	...rest
}) => {
	const { value, onValueChange, error, validate } = useInput(name);

	if (!name) {
		return null;
	}

	/** @param {React.ChangeEvent<HTMLInputElement>} event */
	const handleChange = (event) => {
		if (!event.target) {
			return;
		}

		if (onChange) {
			onChange(event);
		}

		onValueChange(event.target.checked);

		// validate on input change only if there is an existing error
		if (!error) {
			return;
		}

		validate(event.target);
	};

	/** @param {React.FocusEvent<HTMLInputElement, Element>} event */
	const handleBlur = (event) => {
		if (!event.target) {
			return;
		}

		if (onBlur) {
			onBlur(event);
		}

		validate(event.target);
	};

	return (
		<FormControl
			{...FormControlProps}
			required={required}
			error={Boolean(error)}
		>
			<FormControlLabel
				{...FormControlLabelProps}
				label={label}
				description={description}
				control={
					<Checkbox
						name={name}
						onBlur={handleBlur}
						onChange={handleChange}
						required={required}
						{...rest}
						checked={value}
					/>
				}
			/>
			{(helperText || error) && (
				<FormHelperText {...FormHelperTextProps}>
					{helperText || error}
				</FormHelperText>
			)}
		</FormControl>
	);
};

export default HookedCheckbox;
