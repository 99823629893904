// @ts-check
import React from 'react';

import Box from 'undercurrent/Box';
import ArrowDownIcon from 'undercurrent/icons/lucide/ArrowDown';

/**
 * @param {object} props
 * @param {({ strokeWidth, ...rest }: import('undercurrent/types/svgTypes').LucideSvgProps) => JSX.Element} [props.Icon] defaults to ArrowDownIcon
 * @returns
 */
const SpecsCardsDivider = ({ Icon = ArrowDownIcon }) => (
	<Box
		sx={{
			display: 'flex',
			gap: 200,
			justifyContent: 'space-around',
			paddingY: 100,
		}}
	>
		<Icon
			sx={{
				height: ({ size }) => size.sm,
				width: ({ size }) => size.sm,
			}}
		/>
		<Icon
			sx={{
				height: ({ size }) => size.sm,
				width: ({ size }) => size.sm,
			}}
		/>
	</Box>
);

export default SpecsCardsDivider;
