// @ts-check
import React from 'react';

import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import AssetTypeIcon from 'components/standard/AssetTypeIcon';
import CircleFilledIcon from 'undercurrent/icons/custom/CircleFilled';
import CircleIcon from 'undercurrent/icons/lucide/Circle';
import FolderIcon from 'undercurrent/icons/lucide/Folder';
import XIcon from 'undercurrent/icons/lucide/X';
import createServerStatus from 'utility/createServerStatus';

/**
 * @param {import('club-sauce/public/asset').LWApiPublicAssetStatusT} status
 * @param {import('modules/queries/asset/useDetails').AssetDetails['powerStatus']} [powerStatus]
 * @return {{ color: import('@mui/material').ChipProps['color'], label: string, icon?: import('@mui/material').ChipProps['icon'] }}
 * */
const getStatusProps = (status, powerStatus) => {
	const { level, label } = createServerStatus(status, powerStatus);

	switch (level) {
		case 'on':
			return {
				label,
				color: 'success',
				icon: <CircleFilledIcon />,
			};

		case 'settingUp':
			return {
				label,
				color: 'warning',
				icon: <CircularProgress size={16} />,
			};

		case 'off':
			return {
				label,
				color: 'default',
				icon: <CircleIcon />,
			};

		case 'problem':
			return {
				label,
				color: 'error',
				icon: <XIcon />,
			};

		default:
			return { label, color: 'default' };
	}
};

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const AttributesContent = ({
	assetType,
	displayType,
	managedLevel,
	powerStatus,
	project_name: projectName,
	status,
	type,
}) => (
	<>
		<Chip size="small" {...getStatusProps(status, powerStatus)} />
		{displayType && (
			<Chip
				label={displayType}
				size="small"
				icon={<AssetTypeIcon assetType={assetType} type={type} />}
			/>
		)}
		{managedLevel && <Chip label={managedLevel} size="small" />}
		{projectName && (
			<Chip label={projectName} size="small" icon={<FolderIcon />} />
		)}
	</>
);

export default AttributesContent;
