import React from 'react';

import Skeleton from '@mui/material/Skeleton';
import ActionsContainer from './ActionsContainer';

const ActionsLoader = () => {
	return (
		<ActionsContainer>
			<Skeleton
				variant="rounded"
				width={79}
				sx={(theme) => ({ height: theme.size.lg })}
			/>
			<Skeleton
				variant="rounded"
				sx={(theme) => ({ width: theme.size.lg, height: theme.size.lg })}
			/>
		</ActionsContainer>
	);
};

export default ActionsLoader;
