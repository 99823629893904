import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import Stack from 'undercurrent/Stack';
import DrawerContent from 'undercurrent/DrawerContent';
import DrawerFooter from 'undercurrent/DrawerFooter';
import Wrapper from './Wrapper';

/** @param {import('@mui/material').DrawerProps} props */
const Loading = (props) => (
	<Wrapper {...props}>
		<DrawerContent>
			<Stack gap={200}>
				<Skeleton variant="rounded" sx={{ height: '455px', width: '100%' }} />
			</Stack>
		</DrawerContent>
		<DrawerFooter sx={{ gap: 100 }}>
			<Skeleton
				variant="rounded"
				sx={{ height: ({ size }) => size.lg, width: '100%' }}
			/>
			<Skeleton
				variant="rounded"
				sx={{ height: ({ size }) => size.lg, width: '100%' }}
			/>
		</DrawerFooter>
	</Wrapper>
);

export default Loading;
