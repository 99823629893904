const uat = {
	baseURL: 'https://api-public.newman.dev.liquidweb.com/',
	session: {
		length: 900,
		warningTime: 150,
	},
	hosts: {
		www: 'dev-marketing.liquidweb.com',
		cart: 'cart.newman.dev.liquidweb.com',
		manage: 'manage.newman.dev.liquidweb.com',
		salesforce: 'liquidweb--developer.sandbox.lightning.force.com/lightning/r',
		customer_community: 'liquidweb--developer.sandbox.my.site.com/customer/s',
		managed_apps: 'app.dev.liquidweb.com',
		authorize_net: 'test.authorize.net',
		gator: 'gator.dev.liquidweb.com',
	},
	gaTrackerId: 'UA-106560627-3',
	gtmContainerId: 'GTM-KJQGDTD',
	cartStack: {
		siteId: 'k5FaX1lK',
		trackingScriptUrl:
			'https://api.cartstack.com/js/customer-tracking/cart.msmallwood.dev.liquidweb.com:20250_e26c5744de270f74b9fb65dd70ae774c.js',
	},
	guestAuth: {
		username: 'remote-signup',
		password: 'r3M0t3s|gnup',
	},
	cart: {
		checkoutURL: 'https://dev-marketing.liquidweb.com/cart/checkout',
	},
	chatConfig: {
		enabled: true,
		orgId: '00DWe000001HXF7',
		deploymentId: 'Liquid_Web_Support_Portal_MIAW',
		communityURL:
			'https://liquidweb--developer.sandbox.my.site.com/ESWLiquidWebSupportPor1738619732706',
		scrt2URL: 'https://liquidweb--developer.sandbox.my.salesforce-scrt.com',
		messagingScriptURL:
			'https://liquidweb--developer.sandbox.my.site.com/ESWLiquidWebSupportPor1738619732706/assets/js/bootstrap.min.js',
		settings: {
			language: 'en_US',
			chatButtonPosition: '32px,32px',
		},
		storageDomain: 'myuat.dev.liquidweb.com',
	},
	openId: {
		client_id: 'Pnxy9HvmTjqjaXofYfTweg',
		authority: 'https://login.dev.liquidweb.com',
		accessTokenExpiringNotificationTime: 1200,
	},
	algolia: {
		applicationId: 'PW72AQI7GT',
		searchOnlyApiKey: '68dddee3207e5fc6a1d4b936de592fbd',
	},
};

export default uat;
