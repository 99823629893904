import React, { useEffect, useState } from 'react';

import Box from 'undercurrent/Box';
import Drawer from '@mui/material/Drawer';
import Typography from 'undercurrent/Typography';
import LWForm from 'components/LWForm';
import hostingDetailsAlsowith from 'containers/pages/servers/details/hostingDetailsAlsowith';
import useAssetDetails from 'modules/queries/asset/useDetails';
import useServerUpdate from 'modules/queries/server/useUpdate';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { useDispatch } from 'react-redux';
import Button from 'undercurrent/Button';
import DrawerContent from 'undercurrent/DrawerContent';
import DrawerFooter from 'undercurrent/DrawerFooter';
import DrawerHeader from 'undercurrent/DrawerHeader';
import { validateDomain } from 'utility/lwForm/validators';
import { HookedSubmit, HookedTextField } from '../Form';

/**
 * @param {object} props
 * @param {string} props.domain
 * @param {() => void} props.onClose
 * @param {string} props.uniqId
 * */
const EditServerHostnameForm = ({ domain, onClose, uniqId }) => {
	const dispatch = useDispatch();

	const [formValues, setFormValues] = useState({ domain });
	const [formErrors, setFormErrors] = useState({});

	useEffect(() => {
		if (domain) {
			setFormValues((prev) => ({ ...prev, domain }));
		}
	}, [domain]);

	const { mutate: updateServer, isLoading: updateServerIsLoading } =
		useServerUpdate();

	const handleSubmit = (vals) => {
		updateServer(
			{
				uniq_id: uniqId,
				domain: vals.domain,
			},
			{
				onSuccess: () => {
					onClose();
					dispatch(
						snackbarActions.pushMessage({
							variant: 'success',
							message: 'Hostname updated successfully.',
						}),
					);
				},
			},
		);
	};

	return (
		<LWForm
			values={formValues}
			onValuesChange={setFormValues}
			errors={formErrors}
			onErrorsChange={setFormErrors}
			onSubmit={handleSubmit}
			validators={{ domain: validateDomain }}
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				height: '100%',
			}}
		>
			<DrawerContent sx={{ gap: 300 }}>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 200 }}>
					<Typography>What will this do?</Typography>
					<Typography>
						Updating your server&apos;s hostname will change the unique name
						assigned to your server within the Liquid Web platform. It will not
						affect your domain name, but may need to be updated in your DNS
						records.
					</Typography>
				</Box>
				<HookedTextField
					name="domain"
					label="Server hostname"
					helperText="Cannot contain spaces or special characters"
				/>
			</DrawerContent>
			<DrawerFooter sx={{ display: 'flex', flexDirection: 'column', gap: 100 }}>
				<HookedSubmit loading={updateServerIsLoading}>Update</HookedSubmit>
				<Button onClick={onClose} variant="ghost">
					Cancel
				</Button>
			</DrawerFooter>
		</LWForm>
	);
};

/** @param {Omit<import('@mui/material').DrawerProps, 'onClose'> & { onClose: () => void, uniqId: string }} drawerProps */
const DrawerEditServerHostname = ({
	onClose,
	uniqId,
	open,
	...drawerProps
}) => {
	const { data: assetDetailsData } = useAssetDetails(
		{
			uniq_id: uniqId,
			alsowith: hostingDetailsAlsowith,
		},
		{ enabled: Boolean(open) },
	);

	const { domain } = assetDetailsData || {};

	return (
		<Drawer anchor="right" onClose={onClose} open={open} {...drawerProps}>
			<DrawerHeader onClose={onClose}>
				<Typography fontWeight={500} variant="title-xs">
					Update hostname
				</Typography>
				<Typography variant="body-sm">
					Change the hostname used by this sever
				</Typography>
			</DrawerHeader>
			<EditServerHostnameForm
				domain={domain || ''}
				onClose={onClose}
				uniqId={uniqId}
			/>
		</Drawer>
	);
};

export default DrawerEditServerHostname;
