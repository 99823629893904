// @ts-check
import { useQuery } from '@tanstack/react-query';
import { getIsStorm } from 'banana-stand/parsers';
import { fromApiBoolean } from 'club-sauce';
import api from 'modules/queries/api';
import displayName from 'utility/assetDisplayNames';
import createAssetType from 'utility/createAssetType';
import formatBytes from 'utility/formatBytes';
import { getBeyondHostingPowerStatus, isUnknown } from 'utility/powerStatus';

/**
 * @param {string} assetType
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetFeatureDetailsRawT} [featureDetails]
 * */
const getManagedDetails = (assetType, featureDetails) => {
	if (!featureDetails) {
		return { managedType: '', managedLevel: '' };
	}

	if (assetType === 'dedicated') {
		const { value: managedType = '', description: managedLevel = '' } =
			featureDetails.ControlPanel || {};
		return { managedType, managedLevel };
	}

	if (assetType === 'cloud') {
		const {
			extra_software: managedType = '',
			support_level: managedLevel = '',
		} = featureDetails.Template || {};
		return { managedType, managedLevel };
	}

	if (assetType === 'open stack') {
		const { value: managedType = '', description: managedLevel = '' } =
			featureDetails.CloudManagement || {};
		return { managedType, managedLevel };
	}

	return { managedType: '', managedLevel: '' };
};

/**
 * @param {object} param0
 * @param {string} param0.assetType
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetFeatureDetailsRawT} [param0.featureDetails]
 * @param {boolean} [param0.isBeyondHosting]
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['nocworxRemoteDetails']} [param0.nocworxRemoteDetails]
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['machine']} [param0.machine]
 * */
const getOS = ({
	assetType,
	featureDetails,
	nocworxRemoteDetails,
	isBeyondHosting,
	machine,
}) => {
	if (isBeyondHosting) {
		return nocworxRemoteDetails?.os?.identity || '';
	}

	if (!featureDetails) {
		return '';
	}

	if (assetType === 'dedicated') {
		return (
			featureDetails.centos?.description || featureDetails.OS?.description || ''
		);
	}

	if (assetType === 'cloud') {
		return featureDetails.Template?.description || '';
	}

	if (assetType === 'open stack') {
		return (
			Object.values(featureDetails)?.find((option) =>
				fromApiBoolean(option.capabilities.nocworxImage),
			)?.description || ''
		);
	}

	if (assetType === 'bare metal') {
		return machine?.template_description || '';
	}

	return '';
};

/**
 * @param {object} param0
 * @param {string} param0.assetType
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetFeatureDetailsRawT} [param0.featureDetails]
 * @param {boolean} [param0.isBeyondHosting]
 * @param {boolean} param0.isPrivateParent
 * @param {boolean} param0.isVmware
 * @param {boolean} param0.isChild
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['nocworxRemoteDetails']} [param0.nocworxRemoteDetails]
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['machine']} [param0.machine]
 * */
const getCpu = ({
	assetType,
	featureDetails,
	isBeyondHosting,
	isChild,
	isPrivateParent,
	isVmware,
	nocworxRemoteDetails,
	machine,
}) => {
	if (isBeyondHosting) {
		const cores = nocworxRemoteDetails?.server_details?.cpu;

		if (!cores) return '';

		return cores + (cores > 1 ? ' cores' : ' core');
	}

	if (assetType === 'bare metal') {
		if (!machine) return '';

		const { cpu_model: cpuModel, cpu_count: cpuCount } = machine;

		if (!cpuModel) return '';

		if (cpuCount === 2) return `Dual ${cpuModel}`;
		if (cpuCount === 4) return `Quad ${cpuModel}`;

		return cpuModel;
	}

	if (!featureDetails) {
		return '';
	}

	if (isChild) {
		const cores = featureDetails.ConfigId?.vcpu || 0;

		if (!cores) return '';
		return cores + (cores > 1 ? ' cores' : ' core');
	}

	if (isPrivateParent) {
		const { cpu_cores_per_socket: cores = 0, cpu_sockets: sockets = 0 } =
			featureDetails.ConfigId || {};
		const displayedCores = cores * sockets;

		if (!displayedCores) return '';
		return displayedCores + (displayedCores > 1 ? ' cores' : ' core');
	}

	if (isVmware) {
		const vCpus = featureDetails.vDCvCPU?.num_units;

		return vCpus ? `${vCpus} vCPU's` : '';
	}

	if (assetType === 'open stack') {
		return (
			Object.values(featureDetails).find((option) =>
				fromApiBoolean(option.capabilities.nocworxCPU),
			)?.description || ''
		);
	}

	if (assetType === 'cloud') {
		const { cpu_cores: cpuCores, vcpu } = featureDetails.ConfigId || {};
		const cores = vcpu || cpuCores;

		if (!cores) return '';
		return cores + (cores > 1 ? ' cores' : ' core');
	}

	if (assetType === 'dedicated') {
		return featureDetails.Processor?.description || '';
	}

	return '';
};

/**
 * @param {object} param0
 * @param {string} param0.assetType
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['machine']} [param0.machine]
 */
const getGpu = ({ assetType, machine }) => {
	if (assetType === 'bare metal') {
		if (!machine) return '';

		const { gpu_name: gpuName, gpu_count: gpuCount } = machine;

		if (!gpuName) return '';

		if (typeof gpuCount === 'number' && gpuCount > 1) {
			return `${gpuCount} x ${gpuName}`;
		}

		return gpuName;
	}

	return '';
};

/**
 * @param {object} param0
 * @param {string} param0.assetType
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetFeatureDetailsRawT} [param0.featureDetails]
 * @param {boolean} [param0.isBeyondHosting]
 * @param {boolean} param0.isPrivateParent
 * @param {boolean} param0.isChild
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['nocworxRemoteDetails']} [param0.nocworxRemoteDetails]
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['machine']} [param0.machine]
 * */
const getRam = ({
	assetType,
	featureDetails,
	isBeyondHosting,
	isPrivateParent,
	isChild,
	nocworxRemoteDetails,
	machine,
}) => {
	if (isBeyondHosting && nocworxRemoteDetails?.server_details?.ram) {
		return `${nocworxRemoteDetails?.server_details?.ram}MB`;
	}

	if (assetType === 'bare metal') {
		return machine?.memory ? `${machine.memory} GB` : '';
	}

	if (!featureDetails) {
		return '';
	}

	if (isPrivateParent || isChild || assetType === 'cloud') {
		return featureDetails.ConfigId?.memory;
	}

	if (assetType === 'open stack') {
		return (
			Object.values(featureDetails).find((option) =>
				fromApiBoolean(option.capabilities.nocworxMemory),
			)?.description || ''
		);
	}

	if (assetType === 'dedicated') {
		return featureDetails.RAM?.description || '';
	}

	return '';
};

/** @param {AssetDetails['ram']} ram */
const getRamFormatted = (ram) => {
	if (!ram) {
		return '';
	}

	if (typeof ram === 'number') {
		return formatBytes(ram * 1e6).withUnit;
	}

	return ram;
};

/**
 * @param {object} param0
 * @param {string} param0.assetType
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetFeatureDetailsRawT} [param0.featureDetails]
 * @param {boolean} [param0.isBeyondHosting]
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['nocworxRemoteDetails']} [param0.nocworxRemoteDetails]
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['machine']} [param0.machine]
 * */
const getPrimaryStorage = ({
	assetType,
	featureDetails,
	isBeyondHosting,
	nocworxRemoteDetails,
	machine,
}) => {
	if (isBeyondHosting) {
		if (!nocworxRemoteDetails?.server_details) {
			return '';
		}
		return `${nocworxRemoteDetails.server_details.disk_space}GB`;
	}

	if (assetType === 'bare metal') {
		if (!machine) return '';

		const { disk, disk_type: diskType, raid_level: raidLevel } = machine;

		if (!disk) return '';

		const storageParts = [];

		if (disk) {
			storageParts.push(`${disk} GB`);
		}

		if (diskType) {
			storageParts.push(diskType);
		}

		if (typeof raidLevel === 'number') {
			storageParts.push(`RAID${raidLevel}`);
		}

		return storageParts.join(', ');
	}

	if (!featureDetails) {
		return '';
	}

	if (assetType === 'open stack') {
		const diskOption = Object.values(featureDetails).find((option) =>
			fromApiBoolean(option.capabilities.nocworxVolumeSize),
		);
		const { num_units: diskValue, description: diskDescription } =
			diskOption || {};

		return diskValue ? `${diskValue} GB` : diskDescription || '';
	}

	const disk = featureDetails.ConfigId?.disk;

	return disk ? `${disk} GB` : '';
};

/**
 * @param {object} param0
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetFeatureDetailsRawT} [param0.featureDetails]
 * */
const getHardDrives = ({ featureDetails }) => {
	if (!featureDetails) {
		return [];
	}

	const features = Object.entries(featureDetails);

	return features
		.filter(
			([key, { description }]) =>
				key.search(/HD\d/) === 0 && description !== 'No Tertiary Drive',
		)
		.map(([key, { option_key_display_text: title, ptov_id: ptovId }]) => {
			const [, childOption] =
				features.find(
					([childKey, { parent_ptov_id: parentPtovId }]) =>
						childKey.toLowerCase().includes('drivesize') &&
						parentPtovId === ptovId,
				) || [];

			const childDescription = childOption ? childOption?.description : 'None';

			return {
				title: title || key,
				description: childDescription,
			};
		});
};

/**
 * @param {object} param0
 * @param {string} param0.assetType
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['machine']} [param0.machine]
 */
const getLinkSpeed = ({ assetType, machine }) => {
	if (assetType === 'bare metal') {
		if (!machine) return '';

		const { link_speed: linkSpeed } = machine;

		if (typeof linkSpeed === 'number' && linkSpeed > 0) {
			return `${linkSpeed / 1000} Gbps`;
		}

		return '';
	}

	return '';
};

/**
 * @param {object} params
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['resourceState']} [params.resourceState]
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['diskDetails']} [params.diskDetails]
 */
const getParentDiskResources = ({
	resourceState,
	diskDetails = { allocated: 0, snapshots: 0 },
}) => {
	if (!resourceState) return null;

	const data = resourceState.find((value) => value.type === 'disk.lvm.volume');
	return data
		? {
				// for display purposes, we need to remove reserved snapshot space from the amounts
				free: Number(data.free) - diskDetails.snapshots,
				used: Number(data.used) - diskDetails.snapshots,
				total: Number(data.total) - diskDetails.snapshots,
			}
		: null;
};

/** @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI['resourceState']} resourceState */
const getParentMemoryResources = (resourceState) => {
	if (!resourceState) return null;
	return resourceState.find((value) => value.type === 'memory') || null;
};

/**
 * @param {boolean} hasAcronis
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetFeatureDetailsRawT} [featureDetails]
 * */
const getBackupPlan = (hasAcronis, featureDetails) => {
	const backupPlanRaw = featureDetails?.LiquidWebBackupPlan;
	if (hasAcronis) return 'Acronis';
	switch (backupPlanRaw?.value) {
		case 'BackupDay':
		case 'CloudDaily':
		case 'Daily':
			return 'Daily';
		case 'BackupQuota':
		case 'Quota':
			return 'Quota';
		default:
			return 'None';
	}
};

/**
 * @param {boolean} canAcronisBackup
 * @param {"Acronis" | "Daily" | "Quota" | "None"} currentBackupPlan
 * */

const getBackupsState = (canAcronisBackup, currentBackupPlan) => ({
	canAcronis: canAcronisBackup,
	currentPlan: currentBackupPlan,
	hasAcronis: currentBackupPlan === 'Acronis',
	hasBackups: currentBackupPlan !== 'None',
	hasLegacyBackups:
		currentBackupPlan === 'Daily' || currentBackupPlan === 'Quota',
});

/**
 * @typedef ExtendedAssetDetails
 * @property {string} [acronisId]
 * @property {string} [acronisStatus]
 * @property {string} assetType
 * @property {number} bandwidthInGB
 * @property {string} cpu
 * @property {string} gpu
 * @property {string} displayType
 * @property {string} linkSpeed
 * @property {{title: string, description: string}[]} hardDrives
 * @property {boolean} hasBlockStorage
 * @property {boolean} [isBeyondHosting]
 * @property {boolean} isMWPv1
 * @property {boolean} [isOpenStack]
 * @property {boolean} [isBareMetal]
 * @property {boolean} isPrivateParent
 * @property {boolean} isStorm
 * @property {boolean} isVmware
 * @property {boolean} [isWindows]
 * @property {string} managedLevel
 * @property {string} managedType
 * @property {string} [mesRole]
 * @property {string} [mesType]
 * @property {string} os
 * @property {{free: number; used: number; total: number;} | null} parentDiskResources
 * @property {import('club-sauce/public/asset/index.raw').ResourceStateRawI | null} parentMemoryResources
 * @property {string} [powerStatus]
 * @property {string} primaryStorage
 * @property {string | number} [ram]
 * @property {string} [ramFormatted]
 * @property {boolean} [hasAcronis]
 * @property {"Acronis" | "Daily" | "Quota" | "None"} [backupPlan]
 * @property {{canAcronis: boolean, currentPlan: "Acronis" | "Daily" | "Quota" | "None", hasAcronis: boolean, hasBackups: boolean, hasLegacyBackups: boolean}} [backupsState]
 * @property {string} [backupQuotaValue]
 * @property {boolean} canBackupsAndImages
 * @property {boolean} canConsole
 * @property {boolean} disableConsole
 */

/**
 * @typedef {Omit<import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI, 'powerStatus'> & ExtendedAssetDetails} AssetDetails
 * */

/**
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsResultRawI} data
 * @return {AssetDetails}
 * */
const supplementDetails = (data) => {
	const { uniq_id: acronisId, status: acronisStatus } =
		data.backupsAndStorage?.acronis || {};
	const assetType = createAssetType(data.categories || []);
	const displayType = displayName(assetType, data.type);
	const hasBlockStorage = Boolean(
		data.backupsAndStorage?.block_storage?.length,
	);
	const backupsAndStorage = data?.backupsAndStorage || {};
	const hasAcronis = 'acronis' in backupsAndStorage;
	const backupPlan = getBackupPlan(hasAcronis, data.featureDetails || {});
	const canAcronisBackup = data?.capabilities?.canAcronisBackup;
	const backupsState = getBackupsState(canAcronisBackup === 1, backupPlan);
	const backupQuotaValue = data.featureDetails?.BackupQuota?.value;
	const isBeyondHosting = data.categories?.includes('BeyondHostingVPS');
	const isChild = Boolean(data.privateParent);
	const isMWPv1 = data.type === 'WP.VM';
	const isOpenStack = data.categories?.includes('NocworxOpenstack');
	const isBareMetal = data.categories?.includes('BareMetalMAAS');
	const isPrivateParent = data.type === 'SS.PP';
	const isStorm = getIsStorm(data.type);
	const isVmware = data.type === 'VMware.vCloud.vDC';
	const isWindows = data.template?.os_type.toLowerCase().includes('windows');
	const mesRole = data.mesDetails?.mes_role;
	const mesType = data.mesDetails?.mes_type;
	const canBackupsAndImages =
		fromApiBoolean(data.capabilities?.image) ||
		fromApiBoolean(data.capabilities?.provBackup) ||
		fromApiBoolean(data.capabilities?.canAcronisBackup);
	const canConsole =
		fromApiBoolean(data.capabilities?.canUseSpiceConsole) ||
		fromApiBoolean(data.capabilities?.canUseVNCConsole);
	const disableConsole = isUnknown(data.powerStatus);

	const { managedType, managedLevel } = getManagedDetails(
		assetType,
		data.featureDetails,
	);
	const os = getOS({
		assetType,
		featureDetails: data.featureDetails,
		nocworxRemoteDetails: data.nocworxRemoteDetails,
		isBeyondHosting,
		machine: data.machine,
	});
	const cpu = getCpu({
		assetType,
		featureDetails: data.featureDetails,
		isBeyondHosting,
		isChild,
		isPrivateParent,
		isVmware,
		machine: data.machine,
	});
	const gpu = getGpu({
		assetType,
		machine: data.machine,
	});
	const ram = getRam({
		assetType,
		featureDetails: data.featureDetails,
		isBeyondHosting,
		isChild,
		isPrivateParent,
		machine: data.machine,
	});
	const ramFormatted = getRamFormatted(ram);
	const primaryStorage = getPrimaryStorage({
		assetType,
		featureDetails: data.featureDetails,
		isBeyondHosting,
		nocworxRemoteDetails: data.nocworxRemoteDetails,
		machine: data.machine,
	});
	const hardDrives = getHardDrives({
		featureDetails: data.featureDetails,
	});
	const bandwidthInGB = Number(
		data.featureDetails?.Bandwidth?.value.split('.')[1],
	);
	const linkSpeed = getLinkSpeed({
		assetType,
		machine: data.machine,
	});
	const powerStatus = isBeyondHosting
		? getBeyondHostingPowerStatus(data.nocworxRemoteDetails?.power_status || '')
		: data?.powerStatus;
	const parentDiskResources = getParentDiskResources({
		resourceState: data.resourceState,
		diskDetails: data.diskDetails,
	});
	const parentMemoryResources = getParentMemoryResources(data.resourceState);

	return {
		...data,
		acronisId,
		acronisStatus,
		assetType,
		bandwidthInGB,
		cpu,
		disableConsole,
		gpu,
		displayType,
		hardDrives,
		hasBlockStorage,
		isBeyondHosting,
		isMWPv1,
		isOpenStack,
		isBareMetal,
		isPrivateParent,
		isStorm,
		isVmware,
		isWindows,
		linkSpeed,
		managedLevel,
		managedType,
		mesRole,
		mesType,
		os,
		parentDiskResources,
		parentMemoryResources,
		powerStatus,
		primaryStorage,
		ram,
		ramFormatted,
		hasAcronis,
		backupPlan,
		backupsState,
		backupQuotaValue,
		canBackupsAndImages,
		canConsole,
	};
};

/**
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsParamsRawI} params
 * @param {object} [options]
 * @param {import('@tanstack/react-query').UseQueryOptions['enabled']} [options.enabled]
 */
const useAssetDetails = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['/asset/details', params],
		queryFn: () => api.asset.apiRaw.details(params),
		enabled,
		select: supplementDetails,
	});

/**
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetDetailsParamsRawI} params
 */
export const usePollAssetDetails = (params) =>
	useQuery({
		queryKey: ['/asset/details', params],
		queryFn: () => api.asset.apiRaw.details(params),
		refetchInterval: 30000,
		select: supplementDetails,
	});

export default useAssetDetails;
