import React from 'react';

import { getAuthToken } from 'modules/auth/authSelectors';
import { useSelector } from 'react-redux';
import appConfigSelectors from 'modules/appConfig/selectors';
import useChatMiaw from './useChatMiaw';

/** @typedef  ChatMiawGateProps
 * @property {React.ReactNode} children - The child elements to render
 */

/**
 * Gate component that initializes and manages the chat service across the application
 * @param {ChatMiawGateProps} props
 */
const ChatMiawGate = ({ children }) => {
	const config = useSelector(appConfigSelectors.getAppConfig);
	const { chatConfig } = config || {};
	const authToken = useSelector(getAuthToken);

	const { ready } = useChatMiaw(chatConfig, {
		authToken,
		enabled: chatConfig?.enabled,
	});

	if (!ready) return children;

	return (
		<>
			{children}
			<chat-widget popups={false} color="#25A864" />
		</>
	);
};

export default ChatMiawGate;
