import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';
import get from 'lodash/get';

import SubNavRoutes from 'components/routers/SubNavRoutes';
import { roles } from 'utility/constants/roles';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import HostingDetailsLayout from 'components/standard/HostingDetailsLayout';

const ServerResize = lazy(() => import('containers/pages/servers/Resize'));

const Activity = lazy(() => import('containers/pages/servers/Activity'));

const BackupsAndImages = lazy(
	() => import('containers/pages/servers/BackupsAndImages'),
);

const SupportAccess = lazy(
	() => import('containers/pages/servers/SupportAccess'),
);

const Bandwidth = lazy(() => import('containers/pages/servers/Bandwidth'));

const DestroyServer = lazy(
	() => import('containers/pages/servers/DestroyServer'),
);

const ChangeCpanelLicence = lazy(
	() => import('containers/pages/servers/ChangeCpanelLicence'),
);

const Overview = lazy(() => import('containers/pages/servers/Overview'));

const HostingConsole = lazy(() => import('containers/pages/servers/console'));

const ServerReboot = lazy(
	() => import('containers/pages/servers/details/ServerReboot'),
);

const ScheduledTasks = lazy(
	() => import('containers/pages/servers/ScheduledTasks'),
);

const MigrationCenter = lazy(
	() => import('containers/pages/servers/MigrationCenter'),
);
const SSHKeys = lazy(() => import('containers/pages/servers/SSHKeys/Page'));

const PublicIP = lazy(() => import('containers/pages/servers/PublicIP'));

const Networking = lazy(() => import('containers/pages/servers/Networking'));

const Monitoring = lazy(() => import('containers/pages/servers/Monitoring'));

const PrivateParent = lazy(
	() => import('containers/pages/servers/PrivateParent'),
);

const Images = lazy(() => import('containers/pages/servers/Images'));

const ServersRouter = (props) => {
	const { navData } = props;

	return (
		<React.Fragment>
			<Switch>
				<RoleRestrictedRoute
					exact
					path={get(navData, 'path')}
					component={get(navData, 'component')}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					// legacy route
					path={`${get(navData, 'path')}/:serverType/:uniqId/backups`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/backups`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					// legacy route
					path={`${get(navData, 'path')}/:serverType/:uniqId/acronis`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/acronis`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					// legacy route
					path={`${get(navData, 'path')}/:serverType/:uniqId/images`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/images`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					// legacy route
					path={`${get(navData, 'path')}/:serverType/:uniqId/templates`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/templates`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					// legacy route
					path={`${get(navData, 'path')}/:serverType/:uniqId/activity`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/activity`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					exact
					path={`${get(navData, 'path')}/scheduled-tasks`}
					component={ScheduledTasks}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					path={`${get(navData, 'path')}/:uniqId/scheduled-tasks`}
					component={ScheduledTasks}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					// legacy route
					path={`${get(navData, 'path')}/:serverType/:uniqId/scheduled-tasks`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/scheduled-tasks`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					// legacy route
					exact
					path={`${get(navData, 'path')}/:serverType/:uniqId/console`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/console`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					path={`${get(navData, 'path')}/:uniqId/resize`}
					component={ServerResize}
					whitelistedRoles={[roles.PURCHASER]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					// legacy route
					path={`${get(navData, 'path')}/:serverType/:uniqId/resize`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/resize`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					path={`${get(navData, 'path')}/:uniqId/destroy`}
					component={DestroyServer}
					whitelistedRoles={[roles.PURCHASER]}
				/>
				<RoleRestrictedRoute
					// legacy route
					path={`${get(navData, 'path')}/:serverType/:uniqId/destroy`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/destroy`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER]}
				/>
				<RoleRestrictedRoute
					path={`${get(navData, 'path')}/:uniqId/cpanel`}
					component={ChangeCpanelLicence}
					whitelistedRoles={[roles.PURCHASER]}
				/>
				<RoleRestrictedRoute
					// legacy route
					path={`${get(navData, 'path')}/:serverType/:uniqId/cpanel`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/cpanel`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER]}
				/>
				<RoleRestrictedRoute
					path={`${get(navData, 'path')}/:uniqId/reboot`}
					component={ServerReboot}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${get(navData, 'path')}/:type/:uniqId/reboot`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/reboot`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${get(navData, 'path')}/:uniqId/ips/public`}
					component={PublicIP}
					whitelistedRoles={[roles.PURCHASER]}
				/>
				<RoleRestrictedRoute
					// legacy route
					exact
					path={`${get(navData, 'path')}/:serverType/:uniqId/ips/public`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/ips/public`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${get(navData, 'path')}/:uniqId/bandwidth`}
					component={Bandwidth}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					// legacy route
					exact
					path={`${get(navData, 'path')}/:serverType/:uniqId/bandwidth`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/bandwidth`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${get(navData, 'path')}/:uniqId/support-access`}
					component={SupportAccess}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${get(navData, 'path')}/:type/:uniqId/support-access`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/support-access`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${get(navData, 'path')}/:type/:uniqId/firewall`}
					component={({ match }) => (
						<Redirect
							to={`${get(navData, 'path')}/${match.params.uniqId}/firewall`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					path={`${get(navData, 'path')}/ssh-keys`}
					component={SSHKeys}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					path={`${get(navData, 'path')}/migration-center`}
					component={MigrationCenter}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					// legacy route
					exact
					path={`${get(
						navData,
						'path',
					)}/:serverType/:uniqId/acronis/:acronisId/update`}
					component={({ match }) => (
						<Redirect
							to={`/services/acronis/${match.params.acronisId}/update`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					// legacy route
					exact
					path={`${get(
						navData,
						'path',
					)}/:serverType/:uniqId/acronis/:acronisId/manage`}
					component={({ match }) => (
						<Redirect to={`/services/acronis/${match.params.acronisId}`} />
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					path={`${get(navData, 'path')}/:uniqId/private-parent`}
					component={PrivateParent}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					path={`${get(navData, 'path')}/images`}
					component={Images}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<Route path="/servers/:uniqId">
					<HostingDetailsLayout>
						<Switch>
							<RoleRestrictedRoute
								exact
								path="/servers/:uniqId/console"
								component={HostingConsole}
								whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
							/>
							<RoleRestrictedRoute
								exact
								path="/servers/:uniqId/activity"
								component={Activity}
								whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
							/>
							<RoleRestrictedRoute
								exact
								path="/servers/:uniqId/backups"
								component={BackupsAndImages}
								whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
								parent={navData}
							/>
							<RoleRestrictedRoute
								exact
								path="/servers/:uniqId/acronis"
								component={BackupsAndImages}
								whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
								parent={navData}
							/>
							<RoleRestrictedRoute
								path="/servers/:uniqId/images"
								component={BackupsAndImages}
								whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
								parent={navData}
							/>
							<RoleRestrictedRoute
								path="/servers/:uniqId/templates"
								component={BackupsAndImages}
								whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
								parent={navData}
							/>
							<RoleRestrictedRoute
								path="/servers/:uniqId/networking"
								component={Networking}
								whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
								parent={navData}
							/>
							<RoleRestrictedRoute
								path="/servers/:uniqId/monitoring"
								component={Monitoring}
								whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
							/>
							<RoleRestrictedRoute
								exact
								path="/servers/:uniqId"
								component={Overview}
								whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
							/>
						</Switch>
					</HostingDetailsLayout>
				</Route>

				<SubNavRoutes
					subNavs={get(navData, 'subNav')}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
			</Switch>
		</React.Fragment>
	);
};

ServersRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};

export default ServersRouter;
