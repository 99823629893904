import React from 'react';

import Chip from '@mui/material/Chip';
import WordPressIcon from 'undercurrent/icons/platform/WordPress';

const WPOptimizedChip = () => (
	<Chip icon={<WordPressIcon />} label="Optimized" />
);

export default WPOptimizedChip;
