// @ts-check
import React from 'react';

import Box from 'undercurrent/Box';
import Card from '@mui/material/Card';
import Typography from 'undercurrent/Typography';
import { useInput } from 'components/LWForm';
import HookedCheckbox from 'components/standard/Form/HookedCheckbox';

/**
 * @param {object} props
 * @param {string} [props.inputName]
 */
const DataLossConfirmation = ({ inputName = 'confirm' }) => {
	const { error } = useInput(inputName);

	return (
		<Card
			sx={{
				padding: 200,
				display: 'flex',
				flexDirection: 'column',
				gap: 200,
				borderColor: ({ palette }) =>
					error ? palette.uc.danger.border.strong : undefined,
			}}
		>
			<Box>
				<Typography component="p" variant="body-md" fontWeight={500}>
					Data will be overwritten
				</Typography>
				<Typography component="p" variant="body-sm">
					Current data on the selected server will be overwritten once reimaging
					has started
				</Typography>
			</Box>
			<HookedCheckbox
				name={inputName}
				label="Confirm and acknowledge"
				required
			/>
		</Card>
	);
};

export default DataLossConfirmation;
