// @ts-check
import React from 'react';

import Button from 'undercurrent/Button';
import Dialog from 'undercurrent/Dialog';
import DialogActions from 'undercurrent/DialogActions';
import DialogContent from 'undercurrent/DialogContent';
import DialogTitle from 'undercurrent/DialogTitle';
import Typography from 'undercurrent/Typography';

import useResolve from 'modules/queries/notification/useResolve';

/**
 * @typedef DismissAlertDialogProps
 * @property {number} notificationId
 * @property {() => void} onCloseClick
 */

/**
 * @param {DismissAlertDialogProps & import('undercurrent/Dialog').DialogProps} props
 */
const DismissAlertDialog = ({ notificationId, onCloseClick, ...rest }) => {
	const { mutate: resolve, isLoading } = useResolve();

	const handleSubmit = () =>
		resolve({ id: notificationId }, { onSuccess: onCloseClick });

	return (
		<Dialog onClose={onCloseClick} {...rest}>
			<DialogTitle onClose={onCloseClick}>Dismiss alert</DialogTitle>
			<DialogContent>
				<Typography variant="body-md">
					Are you sure you wish to dismiss this alert? You won&apos;t be able to
					access it again once dismissed.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button variant="ghost" onClick={onCloseClick} disabled={isLoading}>
					Cancel
				</Button>
				<Button color="danger" onClick={handleSubmit} loading={isLoading}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DismissAlertDialog;
