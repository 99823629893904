// @ts-check
import React, { useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import AnimatedEyeIcon from 'undercurrent/icons/animated/Eye';
import HookedTextField from '../HookedTextField';

/** @type {typeof HookedTextField} */
const HookedTextFieldPassword = (props) => {
	const { slotProps, ...rest } = props;
	const { input: inputSlotProps, ...restSlotProps } = slotProps || {};
	const [showPassword, setShowPassword] = useState(false);

	return (
		<HookedTextField
			{...rest}
			type={showPassword ? 'text' : 'password'}
			slotProps={{
				...restSlotProps,
				input: {
					...inputSlotProps,
					endAdornment: (
						<InputAdornment
							position="end"
							onClick={() => setShowPassword((prev) => !prev)}
							sx={{ cursor: 'pointer' }}
						>
							<AnimatedEyeIcon
								show={showPassword}
								sx={{
									height: (theme) => theme.size.xS,
									width: (theme) => theme.size.xS,
								}}
							/>
						</InputAdornment>
					),
				},
			}}
		/>
	);
};

export default HookedTextFieldPassword;
