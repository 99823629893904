// @ts-check
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSnackbarError from 'utility/hooks/useSnackbarError';
import api from 'modules/queries/api';

const useServerAuthUpdate = () => {
	const snackbarError = useSnackbarError();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/server/auth/index.raw').LWApiPublicServerAuthUpdateParamsRawI} params */
			(params) => api.server.auth.apiRaw.update(params),
		onSuccess: (data, params) => {
			queryClient.invalidateQueries({
				queryKey: ['/server/auth/details', { uniq_id: params.uniq_id }],
			});
		},
		onError: snackbarError,
	});
};

export default useServerAuthUpdate;
