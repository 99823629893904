// @ts-check

import React from 'react';
import { styled } from '@mui/material/styles';
import Box from 'undercurrent/Box';
import Link from '@mui/material/Link';
import Typography from 'undercurrent/Typography';
import ReturnIcon from 'undercurrent/icons/lucide/CornerDownLeft';
import ArrowUpIcon from 'undercurrent/icons/lucide/ArrowUp';
import ArrowDownIcon from 'undercurrent/icons/lucide/ArrowDown';
import { useSelector } from 'react-redux';
import { selectors as appConfigSelectors } from 'modules/appConfig';

import { SEARCH_PATH } from '../hooks/useSearchAutocomplete';

/**
 * @typedef {import('@mui/material/styles').Theme} Theme
 */

/**
 * @typedef SearchFooterProps
 * @property {string} query - Current search query string
 * @property {boolean} activeInput - Whether the input is currently active/focused
 */

const Footer = styled(Box)(({ theme }) => ({
	paddingTop: theme.spacing(300),
	display: 'flex',
	[theme.breakpoints.up('md')]: {
		backgroundColor: theme.palette.uc.bg.weak,
		padding: `${theme.spacing(100)} ${theme.spacing(150)} ${theme.spacing(100)} ${theme.spacing(300)}`,
		marginTop: theme.spacing(300),
		borderRadius: theme.borderRadius.roundedFull,
	},
}));

const FooterControls = styled(Box)(({ theme }) => ({
	display: 'none',
	flex: 1,
	[theme.breakpoints.up('md')]: {
		display: 'inline-flex',
		gap: theme.spacing(200),
	},
}));

const FooterControl = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: theme.spacing(50),
	fontSize: 10,
	color: theme.palette.uc.text.main,
}));

/**
 * @param {Theme} theme
 */
export function keyboardIconStyles(theme) {
	return {
		display: 'none',
		width: 24,
		height: 24,
		padding: 4,
		borderRadius: theme.borderRadius.roundedXS,
		color: theme.palette.uc.text.active,
		backgroundColor: theme.palette.uc.bg.active,
		boxShadow: `0 1px 0 0 ${theme.palette.uc.border.strong}`,
		'@media (min-width: 768px)': {
			display: 'inline-block',
		},
	};
}

const StyledArrowIcon = styled(Box)(({ theme }) => ({
	...keyboardIconStyles(theme),
	backgroundColor: theme.palette.uc.bg.main,
	border: `1px solid ${theme.palette.uc.border.strong}`,
	color: theme.palette.uc.text.main,
}));

const StyledEscIcon = styled(Typography)(({ theme }) => ({
	...keyboardIconStyles(theme),
	backgroundColor: theme.palette.uc.bg.main,
	border: `1px solid ${theme.palette.uc.border.strong}`,
	color: theme.palette.uc.text.main,
	width: 'auto',
	fontSize: 10,
	padding: `${theme.spacing(50)} ${theme.spacing(100)}`,
	fontWeight: 500,
}));

const FooterAction = styled(Link)(({ theme }) => ({
	borderRadius: theme.borderRadius.roundedFull,
	backgroundColor: theme.palette.uc.bg.active,
	color: theme.palette.uc.text.active,
	fontWeight: 600,
	lineHeight: '18px',
	padding: `${theme.spacing(100)} ${theme.spacing(200)}`,
	textDecoration: 'none',
	flex: 1,
	textAlign: 'center',
	whiteSpace: 'nowrap',

	position: 'relative',
	[theme.breakpoints.up('md')]: {
		flex: 0,
		display: 'inline-flex',
		alignItems: 'center',
	},
}));

const StyledReturnIcon = styled(ReturnIcon)(({ theme }) => ({
	...keyboardIconStyles(theme),
	right: 0,
	top: '50%',
	transform: 'scale(0.8)',
	color: theme.palette.uc.text.main,
	backgroundColor: theme.palette.uc.bg.main,
	border: `1px solid ${theme.palette.uc.border.strong}`,
	transition: 'opacity 0.2s ease, margin-right 0.2s ease',
	marginLeft: 8,
}));

/**
 * Footer component for the search modal
 * Displays keyboard navigation hints and a link to see all results
 * @param {SearchFooterProps} props
 * @returns {JSX.Element} SearchFooter component
 */
export default function SearchFooter({ query, activeInput }) {
	const wwwHostname = useSelector(appConfigSelectors.getWwwHostname);

	return (
		<Footer>
			<FooterControls aria-hidden>
				<FooterControl>
					<StyledArrowIcon as={ArrowUpIcon} />
					<StyledArrowIcon as={ArrowDownIcon} />
					to navigate
				</FooterControl>
				<FooterControl>
					<StyledEscIcon>Esc</StyledEscIcon> to close
				</FooterControl>
			</FooterControls>
			<FooterAction
				href={`${wwwHostname}${SEARCH_PATH}${encodeURIComponent(query)}`}
				target="_blank"
			>
				See all results
				<StyledReturnIcon
					sx={{
						opacity: activeInput ? 1 : 0,
						marginRight: (theme) =>
							activeInput ? 0 : `calc(-1 * ${theme.spacing(400)})`,
					}}
				/>
			</FooterAction>
		</Footer>
	);
}
