// @ts-check
import React, { useMemo, useState } from 'react';

import Box from 'undercurrent/Box';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Stack from 'undercurrent/Stack';
import Typography from 'undercurrent/Typography';
import { formatCurrency } from 'banana-stand/format';
import LWForm from 'components/LWForm';
import ControlPanelIcon from 'components/standard/ControlPanelIcon';
import DataLossConfirmation from 'components/standard/DataLossConfirmation';
import HookedCheckbox from 'components/standard/Form/HookedCheckbox';
import HookedSubmit from 'components/standard/Form/HookedSubmit';
import KeySpec from 'components/standard/KeySpec';
import OSIcon from 'components/standard/OSIcon';
import { HookedServerSpecsCard } from 'components/standard/SeverSpecsCard';
import SpecsCardsDivider from 'components/standard/SpecsCardsDivider';
import hostingDetailsAlsowith from 'containers/pages/servers/details/hostingDetailsAlsowith';
import useAssetDetails from 'modules/queries/asset/useDetails';
import useProductFlatDetails from 'modules/queries/product/useFlatDetails';
import useServerTemplateList from 'modules/queries/server/template/useList';
import useServerReimage from 'modules/queries/server/useReimage';
import Button from 'undercurrent/Button';
import CardFooter from 'undercurrent/CardFooter';
import DrawerContent from 'undercurrent/DrawerContent';
import DrawerFooter from 'undercurrent/DrawerFooter';
import TypographyOverflow from 'undercurrent/TypographyOverflow';
import FormInputs from './FormInputs';
import {
	checkIsWpOptimized,
	createOsDescription,
	managementMap,
} from './helpers';
import Loading from './Loading';
import WPOptimizedChip from './WPOptimizedChip';
import Wrapper from './Wrapper';

/**
 * @param {Omit<import('@mui/material').DrawerProps, 'onClose'> & {
 *   onClose: () => void,
 *   uniqId: string,
 * }} drawerProps
 * */
const DrawerReimage = ({ uniqId, onClose, ...drawerProps }) => {
	const initialFormValues = {
		os: '',
		management: '',
		controlPanel: '',
		force: false,
		confirm: false,
	};
	const [formValues, setFormValues] = useState(initialFormValues);
	const [formErrors, setFormErrors] = useState({});

	const { open } = drawerProps;

	const assetDetailsEnabled = Boolean(open && uniqId);
	const { data: assetDetails, isLoading: isLoadingAssetDetails } =
		useAssetDetails(
			{
				uniq_id: uniqId,
				alsowith: hostingDetailsAlsowith,
			},
			{ enabled: assetDetailsEnabled },
		);

	const {
		region_id: regionId,
		type: serverType,
		os,
		zone,
	} = assetDetails || {};

	const isWindows = Boolean(os?.toLowerCase().includes('windows'));
	const zoneId = zone?.id;

	const templateListEnabled = Boolean(open && serverType && regionId && zoneId);

	const { data: templatesData, isLoading: isLoadingTemplateList } =
		useServerTemplateList(
			{
				page_size: 9999,
				available: 1,
				region_id: regionId,
				server_type: serverType,
				zone_id: Number(zoneId),
			},
			{ enabled: templateListEnabled },
		);

	const productDetailsEnabled = Boolean(open && serverType);
	const { data: productDetails, isLoading: isLoadingProductDetails } =
		useProductFlatDetails(
			{
				product_code: serverType || '',
			},
			{ enabled: productDetailsEnabled },
		);

	const isLoading =
		isLoadingAssetDetails || isLoadingTemplateList || isLoadingProductDetails;

	// remove duplicates
	const uniqueTemplates = useMemo(() => {
		return templatesData?.items?.filter(
			(template, index, self) =>
				index ===
				self.findIndex(
					(predicateTemplate) => predicateTemplate.name === template.name,
				),
		);
	}, [templatesData]);

	const { mutate: reimage, isLoading: isReimaging } = useServerReimage();

	/** @type {{ osName: string, osVersion: string, osBit: string }} */
	const {
		osName: selectedOsName,
		osVersion: selectedOsVersion,
		osBit: selectedOsBit,
	} = JSON.parse(formValues.os || '{}');

	const selectedManagement = formValues.management;
	const selectedControlPanel = formValues.controlPanel;

	const selectedTemplate = uniqueTemplates?.find(
		({
			os_name: osName,
			os_bit: osBit,
			os_version: osVersion,
			management,
			control_panel: controlPanel,
		}) =>
			selectedOsName === osName &&
			selectedOsVersion === osVersion &&
			selectedOsBit === osBit &&
			selectedManagement === management &&
			(selectedControlPanel === controlPanel ||
				// allow no control panel if the template has no control panel
				(selectedControlPanel === 'None' && !controlPanel) ||
				(!selectedControlPanel && !controlPanel)),
	)?.name;

	// this will be the same for all templates - use the first one
	const templateKey = uniqueTemplates?.[0]?.type;

	const selectedTemplateProductOptionValue = productDetails?.flat_options
		?.find((option) => option.key === templateKey)
		?.values.find((value) => value.value === selectedTemplate);

	const selectedPrice =
		selectedTemplateProductOptionValue?.region_id_prices?.[regionId || 1];
	const selectedPriceTimeUnit =
		selectedTemplateProductOptionValue?.price_time_unit;
	const timeUnitDisplay =
		selectedPriceTimeUnit === 'month' ? 'mo' : selectedPriceTimeUnit;

	const showPrice = !selectedTemplate || typeof selectedPrice !== 'undefined';

	const handleClose = () => {
		setFormValues(initialFormValues);
		setFormErrors({});
		onClose();
	};

	const handleSubmit = () => {
		if (!selectedTemplate) return;

		reimage(
			{
				template_name: selectedTemplate,
				uniq_id: uniqId,
				force: formValues.force ? 1 : undefined,
			},
			{
				onSuccess: () => {
					handleClose();
				},
			},
		);
	};

	if (isLoading) {
		return <Loading onClose={handleClose} {...drawerProps} />;
	}

	return (
		<Wrapper onClose={handleClose} {...drawerProps}>
			<LWForm
				errors={formErrors}
				onErrorsChange={setFormErrors}
				onSubmit={handleSubmit}
				// @ts-expect-error
				onValuesChange={setFormValues}
				values={formValues}
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					height: '100%',
				}}
			>
				<DrawerContent>
					<Stack gap={200}>
						<Typography variant="label-xl">
							Configure your {isWindows ? 'Windows template' : 'template'}
						</Typography>
						<FormInputs
							onValuesChange={setFormValues}
							formValues={formValues}
							templates={uniqueTemplates || []}
							productOptionsData={productDetails?.flat_options}
						/>
					</Stack>

					<Stack gap={200}>
						<Typography variant="label-xl">Template</Typography>

						<Stack>
							<Card>
								<Box
									sx={{
										padding: 200,
										display: 'flex',
										flexDirection: 'column',
										gap: 200,
									}}
								>
									<Box
										sx={{
											display: 'flex',
											gap: 100,
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: 100,
											}}
										>
											{selectedOsName ? (
												<OSIcon os={selectedOsName} />
											) : (
												<Skeleton
													variant="circular"
													animation={false}
													sx={{
														height: ({ size }) => size.sm,
														width: ({ size }) => size.sm,
													}}
												/>
											)}

											{selectedOsName && selectedOsVersion ? (
												<Box
													sx={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														minWidth: 0,
														gap: 100,
													}}
												>
													<TypographyOverflow
														variant="title-xs"
														fontWeight={500}
													>
														{createOsDescription({
															osName: selectedOsName,
															osVersion: selectedOsVersion,
															isWindows,
														})}
													</TypographyOverflow>
													{checkIsWpOptimized(selectedOsName) && (
														<WPOptimizedChip />
													)}
												</Box>
											) : (
												<Skeleton
													variant="rounded"
													animation={false}
													sx={{
														height: ({ size }) => size.xS,
														width: '112px',
													}}
												/>
											)}
										</Box>

										{selectedOsBit ? (
											<Typography
												variant="label-lg"
												sx={{ color: ({ palette }) => palette.uc.text.weak }}
											>
												{selectedOsBit}-bit
											</Typography>
										) : (
											<Skeleton
												variant="rounded"
												animation={false}
												sx={{
													height: ({ size }) => size.xS,
													width: '35px',
												}}
											/>
										)}
									</Box>

									<Box sx={{ display: 'flex', gap: 500 }}>
										<KeySpec
											label="Management"
											value={
												managementMap[selectedManagement]?.label || (
													<Skeleton
														variant="rounded"
														animation={false}
														sx={{
															height: ({ size }) => size.xS,
															width: '96px',
														}}
													/>
												)
											}
										/>
										<KeySpec
											label="Control panel"
											icon={
												selectedControlPanel && (
													<ControlPanelIcon
														controlPanel={selectedControlPanel}
														sx={{
															height: ({ size }) => size.xS,
															width: ({ size }) => size.xS,
														}}
													/>
												)
											}
											value={
												selectedTemplate && !selectedControlPanel
													? 'None'
													: selectedControlPanel || (
															<Skeleton
																variant="rounded"
																animation={false}
																sx={{
																	height: ({ size }) => size.xS,
																	width: '96px',
																}}
															/>
														)
											}
										/>
										{showPrice && (
											<KeySpec
												label="Price"
												value={
													selectedTemplate ? (
														`${formatCurrency(selectedPrice)}/${timeUnitDisplay}`
													) : (
														<Skeleton
															variant="rounded"
															animation={false}
															sx={{
																height: ({ size }) => size.xS,
																width: '72px',
															}}
														/>
													)
												}
											/>
										)}
									</Box>
								</Box>
							</Card>

							<SpecsCardsDivider />

							<HookedServerSpecsCard
								uniqId={uniqId}
								cardHeaderTitle="Selected server"
							>
								<CardFooter
									sx={{
										alignItems: 'normal',
									}}
								>
									<HookedCheckbox
										label="Rebuild filesystem"
										name="force"
										description="Use if having problems rebooting server"
									/>
								</CardFooter>
							</HookedServerSpecsCard>
						</Stack>
					</Stack>

					{selectedTemplate && <DataLossConfirmation />}
				</DrawerContent>

				<DrawerFooter sx={{ gap: 100 }}>
					<HookedSubmit disabled={!selectedTemplate} loading={isReimaging}>
						Reimage
					</HookedSubmit>
					<Button variant="ghost" onClick={handleClose}>
						Cancel
					</Button>
				</DrawerFooter>
			</LWForm>
		</Wrapper>
	);
};

export default DrawerReimage;
