// @ts-check

/**
 * @typedef StatusData
 * @property {'on' | 'settingUp' | 'problem' | 'off'} level
 * @property {string} label
 * */

/**
 * @typedef ServerStatusMap
 * @property {StatusData} new
 * @property {StatusData} activating
 * @property {StatusData} running
 * @property {StatusData} stopped
 * @property {StatusData} starting
 * @property {StatusData} stopping
 * @property {StatusData} shuttingDown
 * @property {StatusData} rebooting
 * @property {StatusData} resetting
 * @property {StatusData} active
 * @property {StatusData} terminating
 * @property {StatusData} error
 * */

/** @type {ServerStatusMap} */
const serverStatusMap = {
	running: { level: 'on', label: 'Running' },
	active: { level: 'on', label: 'Active' },

	activating: { level: 'settingUp', label: 'Activating' },
	starting: { level: 'settingUp', label: 'Staring' },
	stopping: { level: 'settingUp', label: 'Stopping' },
	shuttingDown: { level: 'settingUp', label: 'Shutting down' },
	rebooting: { level: 'settingUp', label: 'Rebooting' },
	resetting: { level: 'settingUp', label: 'Resetting' },
	terminating: { level: 'settingUp', label: 'Terminating' },

	new: { level: 'off', label: 'New' },
	stopped: { level: 'off', label: 'Stopped' },

	error: { level: 'problem', label: 'Error' },
};

/**
 * @param {import('club-sauce/public/asset').LWApiPublicAssetStatusT} status
 * @param {import('modules/queries/asset/useDetails').AssetDetails['powerStatus']} [powerStatus]
 * */
const createServerStatus = (status, powerStatus) => {
	switch (status) {
		case 'New':
			return serverStatusMap.new;
		case 'Pending-Activation':
			return serverStatusMap.activating;
		case 'Active':
			switch (powerStatus) {
				case 'on':
					return serverStatusMap.running;
				case 'off':
					return serverStatusMap.stopped;
				case 'start':
					return serverStatusMap.starting;
				case 'stop':
					return serverStatusMap.stopping;
				case 'shutdown':
					return serverStatusMap.shuttingDown;
				case 'reboot':
					return serverStatusMap.rebooting;
				case 'reset':
					return serverStatusMap.resetting;
				default:
					return serverStatusMap.active;
			}
		case 'Pending-Termination':
			return serverStatusMap.terminating;
		default:
			return serverStatusMap.error;
	}
};

export default createServerStatus;
