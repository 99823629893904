import React from 'react';
import Box from 'undercurrent/Box';
import Stack from 'undercurrent/Stack';
import useLeftHandLayout, { LeftHandLayoutProvider } from './context';
import SmallNav from './SmallNav';
import LeftNav from './LeftNav';
import TopNav from './TopNav';

const desktopMainProps = {
	margin: 100,
	boxShadow: 1,
};

/**
 * @param {React.ComponentProps<typeof Stack>} props
 */
export function LeftHandLayoutContent(props) {
	const { children, ...stackProps } = props;

	const { screenSize, leftSize, topSize } = useLeftHandLayout();

	return (
		<Stack
			paddingLeft={`${leftSize}px`}
			bgcolor={(theme) => theme.palette.uc.bg.weak}
			{...stackProps}
		>
			{screenSize === 'small' ? (
				<SmallNav />
			) : (
				<>
					<LeftNav />
					<TopNav />
				</>
			)}
			<Box
				component="main"
				flex="0% 1 1"
				display="flex"
				flexDirection="column"
				borderRadius={(theme) => theme.borderRadius.roundedSm}
				paddingTop={`${topSize}px`}
				bgcolor={(theme) => theme.palette.uc.bg.main}
				{...(screenSize === 'small' ? {} : desktopMainProps)}
			>
				{children}
			</Box>
		</Stack>
	);
}

/**
 * @typedef {React.ComponentProps<typeof Stack> & React.ComponentProps<typeof LeftHandLayoutProvider>} LeftHandLayoutPropsI
 */

/**
 * @param {LeftHandLayoutPropsI} props
 */
export function LeftHandLayout(props) {
	const { appPath, linkComponent, linkProp, roles, ...stackProps } = props;

	return (
		<LeftHandLayoutProvider
			appPath={appPath}
			linkComponent={linkComponent}
			linkProp={linkProp}
			roles={roles}
		>
			<LeftHandLayoutContent {...stackProps} />
		</LeftHandLayoutProvider>
	);
}

export default LeftHandLayout;
