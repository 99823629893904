import { all, call, take, race } from 'redux-saga/effects';

import { USER_FOUND, USER_EXPIRED } from 'redux-oidc';

import storyOrTest from 'utility/tools/storyOrTest';

import account from './account/accountSagas';
import apiRootSaga from './api/apiRootSaga';
import { sagas as assetSagas } from './asset';
import authSagas from './auth/authSagas';
import { sagas as availableAssets } from './availableAssets';
import { sagas as basket } from './basket';
import { sagas as cart } from './cart';
import { sagas as cartStack } from './cartStack';
import { sagas as dnsSagas } from './dns';
import { sagas as favorites } from './favorites';
import gtmSagas from './gtm/sagas';
import paymentForm from './paymentForm/sagas';
import navTrackerSagas from './navTracker/sagas';
import wpSagas from './www-api/wwwSagas';
import { sagas as contact } from './contact';
import internalOnlySagas from './internalOnly/sagas';
import maintenanceModeSagas from './maintenanceMode/sagas';
import manageRedirectSagas from './manageRedirect/sagas';
import notificationsSagas from './notifications/sagas';
import blockStorageSagas from './blockStorage/sagas';
import openidSagas from './openid/openidSagas';
import { sagas as payment } from './payment';
import renewDialogSagas from './renewDialog/sagas';
import { sagas as routeSagas } from './route';
import { sagas as primaryCategoryMappingSagas } from './primaryCategoryMapping';
import { sagas as serverSagas } from './server';
import serviceWorkerSagas from './serviceWorker/sagas';
import sshkeysSagas from './sshkeys/sagas';
import statusSheetSagas from './statusSheet/sagas';
import { sagas as threatStack } from './threatStack';
import usertaskSagas from './usertask/sagas';
import { sagas as reactGator } from './reactGator';
import { sagas as utility } from './utility';
import vmware from './vmware/sagas';
import { domainRegistrationSagas } from './domainRegistration';
import watchCartAbandon from './cart/watchCartAbandon';
import adminCartsSagas from './adminCarts/sagas';

// Sagas that require the user authentication to be completed first
function* authenticatedSagas() {
	yield take(USER_FOUND);

	yield race({
		parallel: all([
			call(navTrackerSagas),
			call(paymentForm),
			call(notificationsSagas),
			call(account),
		]),
		cancelled: take(USER_EXPIRED),
	});
}

export default function* rootSaga() {
	yield all([
		call(adminCartsSagas),
		call(apiRootSaga),
		call(assetSagas),
		call(authenticatedSagas),
		call(authSagas),
		call(availableAssets),
		call(basket),
		call(blockStorageSagas),
		call(cart),
		call(cartStack),
		call(contact),
		call(dnsSagas),
		call(domainRegistrationSagas),
		call(favorites),
		// We don't want this injected js in our tests or stories
		!storyOrTest() && call(gtmSagas),
		call(internalOnlySagas),
		call(maintenanceModeSagas),
		call(manageRedirectSagas),
		call(openidSagas),
		call(payment),
		call(primaryCategoryMappingSagas),
		call(reactGator),
		call(renewDialogSagas),
		call(routeSagas),
		call(serverSagas),
		call(serviceWorkerSagas),
		call(sshkeysSagas),
		call(statusSheetSagas),
		call(threatStack),
		call(usertaskSagas),
		call(utility),
		call(vmware),
		call(watchCartAbandon),
		call(wpSagas),
	]);
}
