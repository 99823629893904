// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/server/template/index.raw').LWApiPublicServerTemplateListParamsRaw} params
 * @param {object} [options]
 * @param {import('@tanstack/react-query').UseQueryOptions['enabled']} [options.enabled]
 */
const useServerTemplateList = (params, options) =>
	useQuery({
		queryKey: ['/server/template/list', params],
		queryFn: () => api.server.template.apiRaw.list(params),
		...options,
	});

export default useServerTemplateList;
