// @ts-check
import React from 'react';

import CloudIcon from 'undercurrent/icons/lucide/Cloud';
import Layers2Icon from 'undercurrent/icons/lucide/Layers2';
import ServerIcon from 'undercurrent/icons/lucide/Server';

/**
 * @param {import('@mui/material').SvgIconProps & { assetType: import('modules/queries/asset/useDetails').AssetDetails['assetType']; type: import('modules/queries/asset/useDetails').AssetDetails['type'] }} props
 */
const AssetTypeIcon = ({ assetType, type, ...rest }) => {
	const typeLowerCase = type.toLowerCase();

	if (typeLowerCase.includes('vps')) {
		return <Layers2Icon {...rest} />;
	}

	switch (assetType) {
		case 'cloud':
			return <CloudIcon {...rest} />;
		default:
			return <ServerIcon {...rest} />;
	}
};

export default AssetTypeIcon;
