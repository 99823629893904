/**
 * Useful links:
 * APIS:       https://developer.salesforce.com/docs/service/messaging-web/guide/api-overview.html
 * Settings:   https://developer.salesforce.com/docs/service/messaging-web/references/m4w-reference/settingsAPI.html
 */

import { useEffect, useState } from 'react';
import api from 'modules/queries/api';

/** @typedef ChatMiawConfig
 * @property {string} orgId - The Salesforce organization ID
 * @property {string} deploymentId - The deployment ID for the chat service
 * @property {string} communityURL - The community URL for the chat service
 * @property {string} scrt2URL - The SCRT2 URL for the chat service
 * @property {string} messagingScriptURL - The URL of the messaging script
 * @property {string} storageDomain - The domain used for storage
 */

/** @typedef ChatMiawParams
 * @property {string} [authToken] - The authentication token for the chat service
 * @property {boolean} [enabled] - Whether the chat is enabled
 */

/**
 * Custom hook to manage the chat initialization and authentication
 * @param {ChatMiawConfig} config - The chat configuration
 * @param {ChatMiawParams} params - Additional parameters for chat configuration
 * @returns {{ ready: boolean }} - Object containing the ready state of the chat
 */
export default function useChatMiaw(config, params) {
	const { authToken, enabled = false } = params;
	const [ready, setReady] = useState(false);

	useEffect(() => {
		if (!authToken) return;
		window.lwSettings = {
			...(window.lwSettings || {}),
			auth: {
				...(window.lwSettings?.auth || {}),
				access_token: authToken,
			},
			api: {
				...(window.lwSettings?.api || {}),
				host: api.apiUrl,
			},
		};
	}, [authToken]);

	useEffect(() => {
		if (ready || !enabled) return;
		window.lwSettings = {
			...(window.lwSettings || {}),
			chat: config,
		};
		const loadChatWidget = async () => {
			// Dynamically import the chat widget bundle
			await import('./chat-widget.bundled');
			setReady(true);
		};

		loadChatWidget();
	}, [enabled, config, ready]);

	return { ready };
}
