import React from 'react';

import Button from 'undercurrent/Button';
import { useForm } from 'components/LWForm/LWForm';

/**
 * Submit button to be used within context of LWForm
 * @param {import('undercurrent/Button/Button').ButtonProps} props
 */
const HookedSubmit = ({ disabled, ...rest }) => {
	const { isValid } = useForm();

	return <Button type="submit" disabled={!isValid || disabled} {...rest} />;
};

export default HookedSubmit;
