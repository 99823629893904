// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/product/index.raw').LWApiPublicProductFlatDetailsParamsRawI} params
 * @param {object} [options]
 * @param {import('@tanstack/react-query').UseQueryOptions['staleTime']} [options.staleTime]
 * @param {import('@tanstack/react-query').UseQueryOptions['enabled']} [options.enabled]
 */
const useProductFlatDetails = (params, { staleTime, enabled } = {}) =>
	useQuery({
		queryKey: ['/product/flatDetails', params],
		queryFn: () => api.product.apiRaw.flatDetails(params),
		staleTime,
		enabled,
	});

/** @param {import('club-sauce/public/product/index.raw').LWApiPublicProductFlatDetailsResultRawI | undefined} data */
export const createSslDvOptionValues = (data) => {
	/** @type {Record<string, import('club-sauce/public/product/index.raw').LWApiPublicProductFlatDetailsResultFlatOptionValueRawI>} */
	const optionValues = {};

	if (!data?.flat_options) {
		return optionValues;
	}

	data.flat_options.forEach((option) => {
		option.values.forEach((value) => {
			optionValues[value.value] = value;
		});
	});

	return optionValues;
};

/** @param {import('club-sauce/public/product/index.raw').LWApiPublicProductFlatDetailsResultRawI} data */
const selectSslDvFlatOptions = (data) => {
	const optionValues = createSslDvOptionValues(data);

	return { ...data, optionValues };
};

export const useSslDvFlatDetails = () =>
	useQuery({
		queryKey: ['/product/flatDetails', { product_code: 'SSL.DV' }],
		queryFn: () => api.product.apiRaw.flatDetails({ product_code: 'SSL.DV' }),
		staleTime: Infinity,
		select: selectSslDvFlatOptions,
	});

export default useProductFlatDetails;
