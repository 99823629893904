import React from 'react';

import Box from 'undercurrent/Box';
import TabBarLoader from './TabBarLoader';

/**
 * A wrapper for MUI Tabs component. Styles can be ovridden with sx prop.
 * @param {import('@mui/material').BoxProps & { dividerProps?: import('@mui/material').BoxProps, isLoading?: boolean }} props
 */
const TabBar = ({ children, dividerProps, isLoading, sx, ...rest }) => {
	const { sx: dividerSx, ...restDividerProps } = dividerProps || {};

	return (
		<Box
			sx={(theme) => ({
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				position: 'relative',
				...(typeof sx === 'function' ? sx(theme) : sx),
			})}
			{...rest}
		>
			{isLoading ? <TabBarLoader /> : children}
			<Box
				sx={(theme) => ({
					borderTopColor: 'divider',
					borderTopWidth: '1px',
					borderTopStyle: 'solid',
					bottom: 0,
					height: '1px',
					position: 'absolute',
					width: '100%',
					...(typeof dividerSx === 'function' ? dividerSx(theme) : dividerSx),
				})}
				{...restDividerProps}
			/>
		</Box>
	);
};

export default TabBar;
