// @ts-check
import React from 'react';

import Dialog from 'undercurrent/Dialog';
import DialogActions from 'undercurrent/DialogActions';
import DialogContent from 'undercurrent/DialogContent';
import DialogTitle from 'undercurrent/DialogTitle';
import Typography from 'undercurrent/Typography';
import useServerPowerStart from 'modules/queries/server/power/useStart';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { useDispatch } from 'react-redux';
import Button from 'undercurrent/Button';
import ServerSpecsCard from '../SeverSpecsCard';

/** @param {Omit<import('undercurrent/Dialog').DialogProps, 'onClose'> & { onClose: () => void, uniqId: string; domain: string; ip: string }} dialogProps */
const DialogStartServer = ({ domain, ip, onClose, uniqId, ...dialogProps }) => {
	const dispatch = useDispatch();
	const { mutate: startServer, isLoading } = useServerPowerStart();

	const handleConfirm = () => {
		startServer(
			{ uniq_id: uniqId },
			{
				onSuccess: () => {
					onClose();
					dispatch(
						snackbarActions.pushMessage({
							variant: 'success',
							message: 'Server starting.',
						}),
					);
				},
			},
		);
	};

	return (
		<Dialog onClose={onClose} {...dialogProps}>
			<DialogTitle onClose={onClose}>Start server</DialogTitle>
			<DialogContent
				sx={{ display: 'flex', flexDirection: 'column', gap: 200 }}
			>
				<Typography variant="body-md">
					Are you sure sure you want to start this server?
				</Typography>
				<ServerSpecsCard
					cardHeaderTitle="Selected server"
					domain={domain}
					ip={ip}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="secondary" onClick={onClose}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleConfirm} loading={isLoading}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DialogStartServer;
