import React from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from 'undercurrent/Typography';
import { useAddFavorite } from 'modules/queries/account/user/stateData/useAdd';
import useStateDataListForKey from 'modules/queries/account/user/stateData/useListForKey';
import { useRemoveFavorite } from 'modules/queries/account/user/stateData/useRemove';
import { useLocation } from 'react-router-dom';
import StarIcon from 'undercurrent/icons/lucide/Star';
import StarOffIcon from 'undercurrent/icons/lucide/StarOff';

const SStar = () => (
	<StarIcon sx={(theme) => ({ height: theme.size.xS, width: theme.size.xS })} />
);

const SStarOff = () => (
	<StarOffIcon
		sx={(theme) => ({ height: theme.size.xS, width: theme.size.xS })}
	/>
);

const FavoriteMenuItem = ({ onClick = () => {}, favoritesDisplayName }) => {
	const location = useLocation();

	const { data, isLoading } = useStateDataListForKey({ key: 'favorites' });
	const { mutate: addFavorite } = useAddFavorite();
	const { mutate: removeFavorite } = useRemoveFavorite();

	const favorite = data?.data.find((fav) => fav.path === location.pathname);

	const handleClick = () => {
		onClick();

		if (favorite) {
			removeFavorite({ uuid: favorite.uuid });
		} else {
			addFavorite({
				path: location.pathname,
				displayName: favoritesDisplayName,
			});
		}
	};

	return (
		<MenuItem disabled={isLoading} onClick={handleClick}>
			<ListItemIcon>{favorite ? <SStarOff /> : <SStar />}</ListItemIcon>
			<Typography variant="label-lg">
				{favorite ? 'Unfavorite' : 'Favorite'}
			</Typography>
		</MenuItem>
	);
};

export default FavoriteMenuItem;
