import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from 'undercurrent/Menu';

/** Only use this hook if `IconButtonMenu` and `IconButtonMenuItem` is not sufficient */
export const useIconButtonMenu = () => {
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const handleAnchorClick = (event) => {
		setMenuAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setMenuAnchorEl(null);
	};
	const open = Boolean(menuAnchorEl);

	return { handleAnchorClick, handleCloseMenu, menuAnchorEl, open };
};

/**
 * @typedef IconButtonMenuProps
 * @property {React.ReactNode} children
 * @property {React.ReactNode} icon
 * @property {import('@mui/material').IconButtonProps} [IconButtonProps]
 * @property {import('@mui/material').MenuProps} [MenuProps]
 */

/**
 * Children should be MUI `MenuItem`s
 * @param {IconButtonMenuProps} props
 */
const IconButtonMenu = ({ children, IconButtonProps, MenuProps, icon }) => {
	return (
		<>
			<IconButton variant="primary" size="medium" {...IconButtonProps}>
				{icon}
			</IconButton>
			<Menu {...MenuProps}>{children}</Menu>
		</>
	);
};

export default IconButtonMenu;
