// @ts-check
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSnackbarError from 'utility/hooks/useSnackbarError';
import api from 'modules/queries/api';

const useServerReimage = () => {
	const snackbarError = useSnackbarError();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/server/index.raw').LWApiPublicServerReimageParamsRaw} params */
			(params) => api.server.apiRaw.reimage(params),

		onError: snackbarError,

		onSuccess: (data) => {
			queryClient.invalidateQueries({
				queryKey: [
					'/asset/details',
					{
						uniq_id: data.uniq_id,
					},
				],
			});
		},
	});
};

export default useServerReimage;
