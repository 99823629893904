// @ts-check
import React from 'react';

import hostingDetailsAlsowith from 'containers/pages/servers/details/hostingDetailsAlsowith';
import useAssetDetails from 'modules/queries/asset/useDetails';
import { useParams } from 'react-router';
import ActionsLoader from './ActionsLoader';
import ActionsContent from './ActionsContent';

/**
 * @param {object} props
 * @param {string} props.favoritesDisplayName
 */
const Actions = ({ favoritesDisplayName }) => {
	// @ts-expect-error
	const { uniqId } = useParams();
	const { data, isLoading, isError } = useAssetDetails(
		{
			alsowith: hostingDetailsAlsowith,
			uniq_id: uniqId,
		},
		{ enabled: Boolean(uniqId) },
	);

	if (isError) {
		return null;
	}

	if (isLoading) {
		return <ActionsLoader />;
	}

	return (
		<ActionsContent {...data} favoritesDisplayName={favoritesDisplayName} />
	);
};

export default Actions;
