import React, { useMemo, useState } from 'react';

import Popover, { popoverClasses } from '@mui/material/Popover';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Stack from 'undercurrent/Stack';
import Tooltip from '@mui/material/Tooltip';

import BellIcon from 'undercurrent/icons/lucide/Bell';

import useNotificationsCurrent from 'modules/queries/notification/useCurrent';
import useLeftHandLayout from 'containers/LeftHandLayout/context';
import AlertDetails from './AlertDetails';
import AlertList from './AlertList';
import DismissAlertDialog from './DismissAlertDialog';
import DismissAllAlertsDialog from './DismissAllAlertsDialog';

const AlertMenu = () => {
	const [menuElement, setMenuElement] = useState();
	const [dismissAllOpen, setDismissAllOpen] = useState(false);
	const [dismissNotificationId, setDismissNotificationId] = useState(null);
	const [notificationId, setNotificationId] = useState(null);

	const { roles } = useLeftHandLayout();
	const open = Boolean(menuElement);

	const { data: alertsData } = useNotificationsCurrent(undefined, {
		enabled: roles.loggedIn,
		refetchInterval: 30000,
	});

	const alerts = useMemo(() => {
		const notificationOrder = {
			Critical: 1,
			Error: 2,
			Warning: 3,
			Notification: 4,
		};

		const notificationSort = (notificationA, notificationB) => {
			const notificationPriorityA =
				notificationOrder[notificationA?.severity] || 99;
			const notificationPriorityB =
				notificationOrder[notificationB?.severity] || 99;
			return notificationPriorityA - notificationPriorityB;
		};

		return (
			alertsData?.items
				?.filter((item) => (item.uniq_id && item.domain) || !item.uniq_id)
				?.filter(
					(item) =>
						!(
							item?.severity === 'Critical' &&
							item?.last_alert ===
								'Activate / deactivate firewall rules Aborted'
						),
				)
				?.sort(notificationSort) || []
		);
	}, [alertsData]);

	const alert = useMemo(
		() => alerts?.find((item) => item?.id === notificationId),
		[alerts, notificationId],
	);

	if (!roles.loggedIn) {
		return null;
	}

	return (
		<>
			<Tooltip title="Alerts">
				<IconButton
					variant="ghost"
					onClick={({ currentTarget }) => setMenuElement(currentTarget)}
					sx={(theme) => ({
						backgroundColor: open ? theme.palette.uc.bg.medium : 'inherit',
					})}
				>
					<Badge
						color={alerts?.length > 0 ? 'error' : undefined}
						overlap="circular"
						variant="dot"
					>
						<BellIcon
							sx={(theme) => ({ height: theme.size.xS, width: theme.size.xS })}
						/>
					</Badge>
				</IconButton>
			</Tooltip>
			<Popover
				open={open}
				anchorEl={menuElement}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				onClose={() => {
					setMenuElement(undefined);
					setDismissNotificationId(null);
					setNotificationId(null);
				}}
				component={Stack}
				sx={(theme) => ({
					[`.${popoverClasses.paper}`]: {
						width: '320px',
						minHeight: '200px',
						display: 'flex',
						flexDirection: 'column',
						gap: theme.spacing(100),
						paddingTop: theme.spacing(200),
						paddingBottom: 0,
						paddingX: theme.spacing(200),
					},
				})}
			>
				{alert ? (
					<AlertDetails
						alert={alert}
						onCloseClick={() => setNotificationId(null)}
						onDismissClick={(id) => {
							setMenuElement(undefined);
							setDismissNotificationId(id);
							setNotificationId(null);
						}}
						onActionClick={() => {
							setMenuElement(undefined);
							setNotificationId(null);
						}}
					/>
				) : (
					<AlertList
						alerts={alerts}
						onItemDismissClick={(id) => {
							setMenuElement(undefined);
							setDismissNotificationId(id);
						}}
						onItemClick={(id) => setNotificationId(id)}
						onDismissAllClick={() => {
							setMenuElement(undefined);
							setDismissAllOpen(true);
						}}
					/>
				)}
			</Popover>
			<DismissAlertDialog
				open={Boolean(dismissNotificationId)}
				notificationId={dismissNotificationId}
				onCloseClick={() => setDismissNotificationId(null)}
			/>
			<DismissAllAlertsDialog
				open={dismissAllOpen}
				notificationIds={alerts?.map((item) => item.id)}
				onCloseClick={() => setDismissAllOpen(false)}
			/>
		</>
	);
};

export default AlertMenu;
