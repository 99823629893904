import React, { useState } from 'react';
import Box from 'undercurrent/Box';
import Stack from 'undercurrent/Stack';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { drawerClasses } from '@mui/material/Drawer';
import { dividerClasses } from '@mui/material/Divider';
import pseudoClasses from 'undercurrent/classes/pseudo';
import CartButton from '../common/CartButton';
import CreateMenuContent from '../common/CreateMenuContent';

export function SmallNavCreateFooter(props) {
	const [open, setOpen] = useState(false);

	return (
		<Stack {...props} direction="row" height={64} padding={150} gap={200}>
			<Button
				color="highlight"
				variant="secondary"
				fullWidth
				onClick={() => setOpen(true)}
			>
				Create and deploy
			</Button>
			<CartButton />
			<SwipeableDrawer
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				anchor="bottom"
				sx={{
					[`& .${drawerClasses.paper}${pseudoClasses.important}`]: {
						padding: 100,
						paddingBottom: 0,
						minWidth: '280px',
						display: 'flex',
						flexDirection: 'column',
						gap: 100,
					},
					[`& .${dividerClasses.root}${pseudoClasses.important}`]: {
						marginX: -100,
						marginBottom: -100,
					},
					// After divider
					[`& .${dividerClasses.root} ~ *`]: {
						marginX: -100,
						paddingX: 300,
						paddingY: 200,
					},
				}}
			>
				<Box
					sx={(theme) => ({
						width: 114,
						height: 4,
						backgroundColor: theme.palette.uc.icon.weak,
						marginX: 'auto',
						marginBottom: 200,
					})}
				/>
				<CreateMenuContent
					onClose={() => {
						setOpen(false);
					}}
				/>
			</SwipeableDrawer>
		</Stack>
	);
}

export default SmallNavCreateFooter;
