const addItemForParentActions = {
	BASKET_ADD_ITEM_FOR_PARENT: 'BASKET_ADD_ITEM_FOR_PARENT',
	/**
	 * Adds a child for a private parent to the basket.
	 * @param {object} param0
	 * @param {string} param0.uniqId - the uniqId of the parent to which we are adding the server.
	 */
	addItemForParent: ({ uniqId }) => ({
		type: addItemForParentActions.BASKET_ADD_ITEM_FOR_PARENT,
		uniqId,
	}),
};

export default addItemForParentActions;
