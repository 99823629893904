// @ts-check
import React from 'react';

import hostingDetailsAlsowith from 'containers/pages/servers/details/hostingDetailsAlsowith';
import useAssetDetails from 'modules/queries/asset/useDetails';
import ServerSpecsCard from './ServerSpecsCard';

/**
 * @param {import('react').ComponentProps<typeof ServerSpecsCard> & {
 *   uniqId: string
 * }} props
 * */
const HookedServerSpecsCard = ({ uniqId, ...rest }) => {
	const enabled = Boolean(uniqId);

	const { data: assetDetails, isLoading } = useAssetDetails(
		{
			uniq_id: uniqId,
			alsowith: hostingDetailsAlsowith,
		},
		{ enabled },
	);

	const {
		assetType,
		cpu,
		displayType,
		domain = '',
		ip,
		ramFormatted,
		template,
		type,
	} = assetDetails || {};

	const { os_name: osName, os_version: osVersion } = template || {};

	return (
		<ServerSpecsCard
			assetType={assetType}
			cpu={cpu}
			displayType={displayType}
			domain={domain}
			ip={ip}
			osName={osName}
			osVersion={osVersion}
			ramFormatted={ramFormatted}
			type={type}
			isLoading={isLoading && enabled}
			{...rest} // allow overriding the fetched data
		/>
	);
};

export default HookedServerSpecsCard;
