// @ts-check
import React from 'react';

import CpanelIcon from 'undercurrent/icons/platform/CPanel';
import InterworxIcon from 'undercurrent/icons/platform/Interworx';

/**
 * @param {import('undercurrent/types/svgTypes').FixedColorSvgProps & {
 *   controlPanel: string
 * }} props
 * */
const ControlPanelIcon = ({ controlPanel, ...rest }) => {
	const controlPanelLowerCase = controlPanel.toLowerCase();

	if (controlPanelLowerCase.includes('cpanel')) {
		return <CpanelIcon {...rest} />;
	}

	if (controlPanelLowerCase.includes('interworx')) {
		return <InterworxIcon {...rest} />;
	}

	return null;
};

export default ControlPanelIcon;
