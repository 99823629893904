// @ts-check
import React from 'react';

import Drawer from '@mui/material/Drawer';
import Typography from 'undercurrent/Typography';
import cartActions from 'modules/cart/actions';
import { useDispatch } from 'react-redux';
import Button from 'undercurrent/Button';
import DrawerContent from 'undercurrent/DrawerContent';
import DrawerFooter from 'undercurrent/DrawerFooter';
import DrawerHeader from 'undercurrent/DrawerHeader';

/**
 * @typedef DrawerAbandonCartContentsProps
 * @property {() => void} onClose
 * @property {() => void} onAbandoned
 */

/** @param {DrawerAbandonCartContentsProps} props */
export const DrawerAbandonCartContents = ({ onClose, onAbandoned }) => {
	const dispatch = useDispatch();

	const handleConfirm = () => {
		onClose();
		dispatch(
			cartActions.abandon({
				onAbandoned,
			}),
		);
	};

	return (
		<>
			<DrawerHeader onClose={onClose}>Abandon Cart?</DrawerHeader>
			<DrawerContent>
				<Typography>
					You are attempting to add a product to a quoted cart, which is not
					possible. The quoted cart will need to be abandoned so a new cart can
					be created in order to proceed.
				</Typography>
				<Typography>
					Would you like to abandon the quoted cart and add the product to a new
					cart?
				</Typography>
			</DrawerContent>
			<DrawerFooter sx={{ display: 'flex', flexDirection: 'column', gap: 100 }}>
				<Button variant="primary" onClick={handleConfirm}>
					Abandon Cart
				</Button>
				<Button variant="ghost" onClick={onClose}>
					Cancel
				</Button>
			</DrawerFooter>
		</>
	);
};

/** @param {Omit<import('@mui/material').DrawerProps, 'onClose'> & DrawerAbandonCartContentsProps} props */
const DrawerAbandonCart = ({ onAbandoned, onClose, ...drawerProps }) => {
	return (
		<Drawer anchor="right" onClose={onClose} {...drawerProps}>
			<DrawerAbandonCartContents onAbandoned={onAbandoned} onClose={onClose} />
		</Drawer>
	);
};

export default DrawerAbandonCart;
