import React from 'react';

import Drawer from '@mui/material/Drawer';
import DrawerHeader from 'undercurrent/DrawerHeader';

/** @param {import('@mui/material').DrawerProps} props */
const Wrapper = ({ onClose, children, ...rest }) => {
	return (
		<Drawer anchor="right" onClose={onClose} {...rest}>
			<DrawerHeader onClose={onClose}>Image templates</DrawerHeader>
			{children}
		</Drawer>
	);
};

export default Wrapper;
