// @ts-check
import React from 'react';

import Button from 'undercurrent/Button';
import Dialog from 'undercurrent/Dialog';
import DialogActions from 'undercurrent/DialogActions';
import DialogContent from 'undercurrent/DialogContent';
import DialogTitle from 'undercurrent/DialogTitle';
import Typography from 'undercurrent/Typography';

import useNotificationResolve from 'modules/queries/notification/useResolve';

/**
 * @typedef DismissAllAlertsDialogProps
 * @property {number[]} notificationIds
 * @property {boolean} open
 * @property {() => void} onCloseClick
 */

/**
 * @param {DismissAllAlertsDialogProps & import('undercurrent/Dialog').DialogProps} props
 */
const DismissAllAlertsDialog = ({
	notificationIds = [],
	open,
	onCloseClick,
	...rest
}) => {
	const { mutate: resolveNotification, isLoading } = useNotificationResolve();

	const handleSubmit = () => {
		notificationIds.forEach((id) => resolveNotification({ id }));
		onCloseClick();
	};

	return (
		<Dialog open={open} onClose={onCloseClick} {...rest}>
			<DialogTitle onClose={onCloseClick}>Dismiss all alerts</DialogTitle>
			<DialogContent>
				<Typography variant="body-md">
					Are you sure you wish to dismiss all of your current alerts? You
					won&apos;t be able to access them again once dismissed.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button variant="ghost" onClick={onCloseClick} loading={isLoading}>
					Cancel
				</Button>
				<Button color="danger" onClick={handleSubmit} loading={isLoading}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DismissAllAlertsDialog;
