import Cookies from 'js-cookie';
import {
	all,
	select,
	put,
	take,
	takeEvery,
	call,
	delay,
	race,
} from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import { actions as openidLogoutActions } from 'modules/openid/logoutModule';
import {
	hasAuthToken as hasAuthTokenSelector,
	username as usernameSelector,
	roles as rolesSelector,
	group as groupSelector,
	isBasketAdmin as getIsBasketAdmin,
	getIsRedirecting,
	getIsTokenExpired,
	isMasquerade as getIsMasquerade,
	email as getEmail,
	username as getUserName,
	sub as getSub,
	givenName as givenNameSelector,
	familyName as familyNameSelector,
} from 'modules/auth/authSelectors';
import {
	actions as userDetailsActions,
	moduleKeys as userDetailsModuleKeys,
} from 'modules/api/account/user/detailsModule';
import { additionalSagas as assetListAdditionalSagas } from 'modules/api/asset/listModule';
import {
	actions as accountDetailActions,
	selectors as accountDetailsSelectors,
} from 'modules/api/account/detailsModule';
import { selectors as routeSelectors } from 'modules/route';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import storyOrTest from 'utility/tools/storyOrTest';

import disallowedUserActions from 'modules/disallowedUser/actions';

import { CAUSES as disallowedUserCauses } from 'modules/disallowedUser/constants';
import { USER_FOUND, USER_EXPIRED } from 'redux-oidc';
import {
	REDIRECT_PATH,
	OPP_ID,
	COUPON_CODE,
	AUTH_TOKEN_TIMEOUT_MS,
} from 'utility/constants/auth';
import { CART_UUID } from 'utility/constants/baskets';

import { fromApiBoolean } from 'club-sauce';
import authActions from './authActions';

function* handleUserManagerInit() {
	const userManager = yield select(appConfigSelectors.getUserManager);
	yield call([userManager, 'clearStaleState']);
}

function* storeQueryParams() {
	const { opp_id: oppId, apply_coupon: couponCode } = yield select(
		routeSelectors.getQueryParams,
	);

	if (oppId) {
		yield call([sessionStorage, 'setItem'], OPP_ID, oppId);
	}

	if (couponCode) {
		yield call([sessionStorage, 'setItem'], COUPON_CODE, couponCode);
	}
}

/**
 * Calls signinRedirect from the userManager in appConfigSelectors if the user is an an unauthed route or "force" is passed in.
 * @param {object} param0
 * @param {boolean} param0.force - forces signinRedirect to happen even for unauthed routes.
 */
function* signinRedirect({ force } = {}) {
	const isRedirecting = yield select(getIsRedirecting);
	if (isRedirecting) return; // NEWMAN-1909
	/** @type {Oidc.UserManager} */
	const userManager = yield select(appConfigSelectors.getUserManager);
	const allowUnauth = yield select(routeSelectors.getAllowUnauth);
	const {
		forceAuth = force, // the arg and the param mean the same thing, so lets combine them here.
	} = yield select(routeSelectors.getQueryParams);

	if ((!allowUnauth || forceAuth) && storyOrTest() !== 'storybook') {
		const redirectPath = yield select(routeSelectors.getFullPathName);
		yield call([sessionStorage, 'setItem'], REDIRECT_PATH, redirectPath);
		yield call(storeQueryParams);

		yield put(authActions.setUserRedirecting(true));
		yield call([userManager, 'signinRedirect'], { useReplaceToNavigate: true });
	}
}

function* waitForUserFound(timeout = AUTH_TOKEN_TIMEOUT_MS) {
	const { userFound } = yield race({
		userFound: take(USER_FOUND),
		timedOut: delay(timeout),
	});

	return Boolean(userFound);
}

function* maybeDoPostLoginRedirect() {
	const storedRedirectPath = yield call(
		[sessionStorage, 'getItem'],
		REDIRECT_PATH,
	);

	if (!storedRedirectPath) return;

	yield call([sessionStorage, 'removeItem'], REDIRECT_PATH);

	if (storedRedirectPath !== '/') {
		yield put(replace(storedRedirectPath));
		return;
	}

	if (yield select(getIsBasketAdmin)) {
		yield put(replace('/shop/marketplace'));
		return;
	}

	// Users having certain products should land
	// directly at the server list instead of the
	// homepage.
	const serverListRedirectOverrideCategories = ['CloudHosting'];
	const { data } = yield call(
		assetListAdditionalSagas.fetchDirectly,
		{
			category: serverListRedirectOverrideCategories,
		},
		undefined,
		{ justReturnRes: true },
	);
	if (data?.items?.length) {
		yield put(replace('/servers'));
		return;
	}

	yield put(replace('/'));
}

function* handleRouteChange({ payload, type }) {
	if (type === USER_FOUND)
		yield put(authActions.setWaitingForToken({ waitingForToken: false }));
	if (type === USER_EXPIRED) {
		yield put(authActions.setWaitingForToken({ waitingForToken: false }));
		yield put(authActions.setUserExpired({ userExpired: true }));
		const userFound = yield call(waitForUserFound);
		if (userFound) {
			return;
		}
	}
	if (payload?.isFirstRendering) return; // on initial app load, initial route occurs before authentication is initialized;
	const userManager = yield select(appConfigSelectors.getUserManager);

	const user = yield call([userManager, 'getUser']);
	if (user) {
		// Handle leftover expired sessions
		const tokenIsExpired = yield select(getIsTokenExpired);
		if (tokenIsExpired) {
			yield call(signinRedirect);
			// escape hatch so we don't remove the redirect path signinRedirect will have just added!
			return;
		}

		if (type === USER_FOUND) yield call(maybeDoPostLoginRedirect);
	} else {
		yield call(signinRedirect);
	}
}

function* sendIdentity() {
	const isBasketAdmin = yield select(getIsBasketAdmin);
	const isMasquerade = yield select(getIsMasquerade);
	const sub = yield select(getSub);
	const givenName = yield select(givenNameSelector);
	const familyName = yield select(familyNameSelector);
	const email = yield select(getEmail);
	const username = yield select(getUserName);
	const accountNumber = yield select(accountDetailsSelectors.getAccnt);
	const customerLifecycle = yield select(
		accountDetailsSelectors.getCustomerLifecycle,
	);
	yield put(
		authActions.gtmIdentify({
			idData: {
				isBasketAdmin,
				isMasquerade,
				id: sub,
				givenName,
				familyName,
				email,
				username,
				accountNumber,
				newAccount: fromApiBoolean(customerLifecycle?.new_account),
			},
		}),
	);
}

function* handleLogin() {
	yield take(USER_FOUND);
	const roles = yield select(rolesSelector);
	const group = yield select(groupSelector);
	const isBasketAdmin = yield select(getIsBasketAdmin);
	if (!isBasketAdmin && group && (!roles || !roles.includes('AccountLogin'))) {
		yield put(
			disallowedUserActions.setCause(disallowedUserCauses.NO_ACCOUNT_LOGIN),
		);
	}

	if (!isBasketAdmin) {
		yield all([
			take([accountDetailActions.setType, accountDetailActions.errorType]),
			put(
				accountDetailActions.fetch({
					alsowith: [
						'businessUnit',
						'customerLifecycle',
						'highlights',
						'managementPortal',
						'referAFriend',
					],
				}),
			),
		]);
	}

	const managementPortal = yield select(
		accountDetailsSelectors.managementPortal,
	);
	if (managementPortal !== 'manage') {
		yield put(authActions.userDetailsInit());
	} else {
		yield put(
			disallowedUserActions.setCause(disallowedUserCauses.WRONG_PORTAL),
		);
	}

	const accountStatus = yield select(accountDetailsSelectors.getAccountStatus);

	switch (accountStatus) {
		case 'suspended': {
			const path = yield select(routeSelectors.getPathName);
			if (
				!path.startsWith('/account/billing') &&
				!path.startsWith('/billing')
			) {
				// Don't want to redirect if they are already at a billing page
				yield put(push('/account/billing'));
			}
			break;
		}
		default:
	}

	yield call(sendIdentity);
}

function* handleSessionLogout() {
	// clear specific things in local storage if needed.
	const isBasketAdmin = yield select(getIsBasketAdmin);
	const isMasquerade = yield select(getIsMasquerade);
	if (isBasketAdmin || isMasquerade) {
		// NEWMAN-2840
		Cookies.remove(CART_UUID);
	}

	// logout
	const userManager = yield select(appConfigSelectors.getUserManager);
	yield call([userManager, 'signoutRedirect']);
	yield put(replace('/'));
}

function* watchSessionEnd() {
	yield take(USER_FOUND); // Never start this saga until a logged in user is encountered
	yield takeEvery(
		[
			openidLogoutActions.setType,
			USER_EXPIRED,
			authActions.ACCOUNT_AUTH_TOKEN_CLEAR,
		],
		handleSessionLogout,
	);
}

function* handleInitUserDetails({ timeout }) {
	const username = yield select(usernameSelector);
	const isBasketAdmin = yield select(getIsBasketAdmin);
	const hasAuthToken = yield select(hasAuthTokenSelector);
	if (!isBasketAdmin && hasAuthToken) {
		yield put(
			userDetailsActions.fetch(
				{ username, timeout },
				userDetailsModuleKeys.LOGGED_IN_USER,
			),
		);
	}
}

function* initUserDetails() {
	yield takeEvery([authActions.USER_DETAILS_INIT], handleInitUserDetails);
}

function* listenRouteChanges() {
	yield takeEvery(
		['@@router/LOCATION_CHANGE', USER_FOUND, USER_EXPIRED], // since inital route happens before auth is initialized, also need to trigger this when auth is ready
		handleRouteChange,
	);
}

export { signinRedirect, handleLogin, waitForUserFound, storeQueryParams };
export default function* rootSaga() {
	yield all([
		handleUserManagerInit(),
		handleLogin(),
		watchSessionEnd(),
		initUserDetails(),
		listenRouteChanges(),
	]);
}
