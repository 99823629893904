// @ts-check

import React from 'react';
import { useSelector } from 'react-redux';
import Stack from 'undercurrent/Stack';
import Typography from 'undercurrent/Typography';
import Tooltip from 'undercurrent/Tooltip';
import { formatCurrency } from 'banana-stand/format';
import { hasAuthToken as getHasAuthToken } from 'modules/auth';
import useBillingBalance from 'modules/queries/billing/useBalance';
import useAccountDetails from 'modules/queries/account/useDetails';
import { IntroTarget, introTargets } from 'containers/introjs';
import TriangleAlertIcon from 'undercurrent/icons/lucide/TriangleAlert';
import Skeleton from '@mui/material/Skeleton';
import FooterMenu from './FooterMenu';

/**
 * @param {object} props
 * @param {boolean} props.isPastDue
 * @param {string} props.pastDue
 * @param {React.ReactElement} props.children
 * */
const PastDueTooltip = ({ isPastDue, pastDue, children }) => {
	if (isPastDue) {
		return (
			<Tooltip
				variant="fancy"
				arrow
				title="Past due"
				message={
					<>
						A friendly reminder your payment of <strong>{pastDue}</strong> is
						due
					</>
				}
				placement="top"
			>
				{children}
			</Tooltip>
		);
	}

	return children;
};

/**
 * @param {React.ComponentProps<typeof Stack>} props
 */
export function LeftNavFooter(props) {
	const hasAuthToken = useSelector(getHasAuthToken);
	const {
		data: billingBalanceData,
		isError: billingBalanceError,
		isLoading: isbillingBalanceLoading,
	} = useBillingBalance({
		enabled: hasAuthToken,
	});
	const {
		data: accountUserDetailsData,
		isError: accountUserDetailsError,
		isLoading: isAccountUserDetailsLoading,
	} = useAccountDetails({}, { enabled: hasAuthToken, retry: true });

	const accountNumber = accountUserDetailsData?.accnt;
	const balanceDue = formatCurrency(billingBalanceData?.accountBalanceDisplay);
	const pastDue = formatCurrency(billingBalanceData?.pastDue);
	const isPastDue = (billingBalanceData?.pastDue || 0) > 0;

	if (billingBalanceError && accountUserDetailsError) {
		// likely means the user does not have permission to view this data - so
		// just don't show anything
		return null;
	}

	return (
		<IntroTarget introId={introTargets.navAccountBalance}>
			<Stack flexDirection="column" {...props}>
				{!billingBalanceError && (
					<Stack
						direction="row"
						justifyContent="stretch"
						alignItems="center"
						padding={150}
						paddingBottom={200}
						borderRadius={(theme) =>
							`${theme.borderRadius.roundedSm} ${theme.borderRadius.roundedSm} 0 0`
						}
						marginBottom={-100}
						gap={100}
						useFlexGap
						bgcolor={(theme) =>
							!isPastDue
								? theme.palette.uc.bg.medium
								: theme.palette.uc.danger.bg.main
						}
					>
						<PastDueTooltip pastDue={pastDue} isPastDue={isPastDue}>
							<Stack
								direction="row"
								flex="100% 1 1"
								alignItems="center"
								gap={50}
								useFlexGap
							>
								{isPastDue && (
									<TriangleAlertIcon
										sx={(theme) => ({
											color: theme.palette.uc.danger.icon.strong,
											height: theme.size.xS,
											width: theme.size.xS,
										})}
									/>
								)}
								<Stack direction="row" gap={50}>
									<Typography variant="label-md">
										Total due <strong>{balanceDue}</strong>
									</Typography>
									{isbillingBalanceLoading && (
										<Skeleton
											variant="rounded"
											height={12}
											width={50}
											sx={({ palette }) => ({
												backgroundColor: palette.uc.bg.weak,
												paddingLeft: 50,
											})}
										/>
									)}
								</Stack>
							</Stack>
						</PastDueTooltip>
						<FooterMenu />
					</Stack>
				)}
				{!accountUserDetailsError && (
					<Stack
						flexDirection="row"
						justifyContent="stretch"
						alignItems="center"
						padding={150}
						border={(theme) => `1px solid ${theme.palette.uc.border.main}`}
						borderRadius={(theme) => theme.borderRadius.roundedSm}
						bgcolor={(theme) => theme.palette.uc.bg.weak}
						gap={100}
					>
						<Typography variant="label-md">
							Account # {accountNumber}
						</Typography>
						{isAccountUserDetailsLoading && (
							<Skeleton variant="rounded" height={12} width={50} />
						)}
					</Stack>
				)}
			</Stack>
		</IntroTarget>
	);
}

export default LeftNavFooter;
