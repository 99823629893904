// @ts-check
import React from 'react';

import Stack from 'undercurrent/Stack';
import TypographyOverflow from 'undercurrent/TypographyOverflow';

/**
 * @typedef KeySpecProps
 * @property {React.ReactNode} [icon]
 * @property {React.ReactNode} label
 * @property {React.ReactNode} value
 */

/** @param {import('@mui/material').StackProps & KeySpecProps} props */
const KeySpec = ({ icon, label, value, ...rest }) => (
	<Stack gap={50} {...rest}>
		<Stack direction="row" justifyContent="space-between" gap={100}>
			<TypographyOverflow
				variant="label-md"
				sx={{ color: (theme) => theme.palette.uc.text.weak }}
			>
				{label}
			</TypographyOverflow>
		</Stack>
		<Stack direction="row" gap={50} alignItems="center" py={50}>
			{icon}
			{typeof value === 'string' ? (
				<TypographyOverflow variant="label-md">{value}</TypographyOverflow>
			) : (
				value
			)}
		</Stack>
	</Stack>
);

export default KeySpec;
