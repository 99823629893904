// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/server/auth/index.raw').LWApiPublicServerAuthDetailsParamsRawI} params
 * @param {object} options
 * @param {import('@tanstack/react-query').UseQueryOptions['enabled']} [options.enabled]
 */
const useServerAuthDetails = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['/server/auth/details', params],
		queryFn: () => api.server.auth.apiRaw.details(params),
		enabled,
	});

export default useServerAuthDetails;
