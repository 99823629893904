// @ts-check
import React from 'react';
import { ReactComponent as LogoText } from 'images/lw-text-logo.svg';
import Collapse from '@mui/material/Collapse';
import Stack from 'undercurrent/Stack';
import { useNavTopInnerX, useNavTopOuterY, useNavTopSize } from '../navSetter';
import MobileMenuButton from '../common/MobileMenuButton';
import AlertMenu from '../common/AlertMenu';
import CartButton from '../common/CartButton';
import SupportMenu from '../common/SupportMenu';
import UserMenu from '../common/UserMenu';
import TopNavSearch from '../common/SearchButton';
import SmallNavDrawer from './NavDrawer';
import useLeftHandLayout from '../context';

const TOPNAV_SIZE_PX = 60;
const TOPNAV_INNER_X_PX = 24;
const TOPNAV_OUTER_Y_PX = 0;

export function LeftNav() {
	useNavTopSize(TOPNAV_SIZE_PX);
	useNavTopInnerX(TOPNAV_INNER_X_PX);
	useNavTopOuterY(TOPNAV_OUTER_Y_PX);

	const { mobileOpen, allowLeftNav } = useLeftHandLayout();

	return (
		<>
			<Stack
				component="nav"
				position="fixed"
				top={0}
				left={0}
				right={0}
				height={TOPNAV_SIZE_PX}
				overflow="hidden"
				direction="row"
				alignItems="center"
				paddingY={100}
				paddingX={`${TOPNAV_INNER_X_PX}px`}
				zIndex={(theme) => theme.zIndex.appBar}
				bgcolor={(theme) => theme.palette.uc.bg.weak}
				useFlexGap
				gap={100}
			>
				{allowLeftNav && <MobileMenuButton />}
				<Stack flex="0% 1 1" overflow="auto" direction="row">
					<LogoText />
				</Stack>
				{allowLeftNav ? (
					<>
						<Collapse in={mobileOpen} orientation="horizontal">
							<Stack direction="row" useFlexGap gap={100}>
								<SupportMenu />
								<UserMenu />
							</Stack>
						</Collapse>
						<Collapse in={!mobileOpen} orientation="horizontal">
							<Stack direction="row" useFlexGap gap={100}>
								<AlertMenu />
								<TopNavSearch />
							</Stack>
						</Collapse>
					</>
				) : (
					<>
						<AlertMenu />
						<CartButton />
						<UserMenu />
					</>
				)}
			</Stack>
			<SmallNavDrawer />
		</>
	);
}

export default LeftNav;
