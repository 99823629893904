// @ts-check

import React from 'react';
import { styled } from '@mui/material/styles';
import Box from 'undercurrent/Box';
import IconButton from '@mui/material/IconButton';
import SearchIcon from 'undercurrent/icons/lucide/Search';
import TextField from '@mui/material/TextField';
import ReturnIcon from 'undercurrent/icons/lucide/CornerDownLeft';
import { inputBaseClasses } from '@mui/material';
import { keyboardIconStyles } from './SearchFooter';

/**
 * @typedef SearchInputProps
 * @property {React.RefObject<HTMLInputElement>} inputRef - Reference to the input element
 * @property {Object} inputProps - Props to be spread on the input element
 * @property {boolean} activeInput - Whether the input is currently active/focused
 * @property {(event: React.KeyboardEvent) => void} onKeyDown - Keyboard event handler
 * @property {() => void} onClose - Function to close the search modal
 */

const SearchInputContainer = styled(Box)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	gap: theme.spacing(150),
}));

const InputWrapper = styled(Box)({
	width: '100%',
	position: 'relative',
});

const StyledInput = styled(TextField)(({ theme }) => ({
	width: '100%',
	position: 'relative',
	[`& .${inputBaseClasses.root}`]: {
		marginTop: 0,
		marginBottom: 0,
		'&.Mui-focused': {
			outline: 'none',
		},
	},

	[`& .${inputBaseClasses.input}`]: {
		padding: theme.spacing(200),
		paddingLeft: `calc(${theme.spacing(200)} + ${theme.size.xS} + ${theme.spacing(100)})`,
		'&::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration':
			{
				display: 'none',
			},
	},
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
	position: 'absolute',
	left: theme.spacing(200),
	top: '50%',
	transform: 'translateY(-50%)',
	opacity: 0.5,
	width: theme.size.xS,
	height: theme.size.xS,
}));

const StyledReturnIcon = styled(ReturnIcon)(({ theme }) => ({
	...keyboardIconStyles(theme),
	position: 'absolute',
	right: theme.spacing(200),
	transition: 'margin 0.2s ease, opacity 0.2s ease',
	top: '50%',
	transform: 'translateY(-50%)',
	color: theme.palette.uc.border.strong,
	backgroundColor: theme.palette.uc.bg.main,
	border: `1px solid ${theme.palette.uc.border.strong}`,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
	border: 'none',
	color: theme.palette.uc.text.medium,
	cursor: 'pointer',
	display: 'block',
	margin: 0,
	padding: theme.spacing(100),
	textAlign: 'center',
	backgroundColor: 'transparent',
	fontSize: 18,
	'@media (min-width: 768px)': {
		display: 'none',
	},
}));

/**
 * Search input component for the search modal
 * @param {SearchInputProps} props
 */
export default function SearchInput({
	inputRef,
	inputProps,
	activeInput,
	onKeyDown,
	onClose,
}) {
	return (
		<SearchInputContainer>
			<InputWrapper>
				<StyledSearchIcon />
				<StyledInput
					inputRef={inputRef}
					{...inputProps}
					type="search"
					placeholder="Search Help Docs"
					autoComplete="off"
					onKeyDown={onKeyDown}
					autoFocus
				/>
				<StyledReturnIcon
					sx={{
						opacity: activeInput ? 1 : 0,
						marginRight: activeInput
							? 0
							: (theme) => `calc(-1 * ${theme.spacing(200)})`,
					}}
				/>
			</InputWrapper>
			<CloseButton onClick={onClose} size="small">
				Close
			</CloseButton>
		</SearchInputContainer>
	);
}
