// @ts-check

import React from 'react';
import { styled } from '@mui/material/styles';
import Box from 'undercurrent/Box';
import Typography from 'undercurrent/Typography';
import Link from '@mui/material/Link';
import ReturnIcon from 'undercurrent/icons/lucide/CornerDownLeft';
import { getHighlightedSnippet } from '../utils';
import { keyboardIconStyles } from './SearchFooter';

/**
 * @typedef {ReturnType<typeof import('@algolia/autocomplete-core').createAutocomplete>} Autocomplete
 * @typedef {import('@algolia/autocomplete-core').AutocompleteApi<SearchResultItemType>} AutocompleteApi
 * @typedef {import('react').HTMLAttributes<HTMLLIElement>} HTMLLIAttributes
 */

/**
 * @typedef ContentType
 * @property {string} [icon] - URL of the content type icon
 * @property {string} [label] - Label describing the content type
 */

/**
 * @typedef SearchResultItemType
 * @property {string} objectID
 * @property {string} [permalink]
 * @property {string} post_title
 * @property {SnippetResult} [_snippetResult]
 * @property {ContentType} [content_type]
 */

/**
 * @typedef SnippetContent
 * @property {string} value - The snippet text value
 */

/**
 * @typedef SnippetResult
 * @property {SnippetContent} content - Content with highlighted snippet
 */

/**
 * @typedef SearchResultItemProps
 * @property {SearchResultItemType} item - The search result item data
 * @property {HTMLLIAttributes} itemProps - Props to be spread on the list item
 */

const ResultsList = styled('ul')(({ theme }) => ({
	listStyle: 'none',
	padding: 0,
	margin: 0,
	borderRadius: theme.borderRadius.roundedSm,
	border: `1px solid ${theme.palette.uc.border.weak}`,
	overflow: 'hidden',
}));

const ResultItem = styled('li')({
	textDecoration: 'none',
	display: 'flex',
	padding: 0,
	cursor: 'pointer',
	outlineOffset: -1,
});

const ItemLink = styled(Link)(({ theme }) => ({
	display: 'flex',
	gap: theme.spacing(300),
	padding: `${theme.spacing(200)} ${theme.spacing(400)}`,
	justifyContent: 'space-between',
	alignItems: 'center',
	textDecoration: 'none',
	width: '100%',
	color: 'inherit',
}));

const ItemContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
});

const ItemTitle = styled(Typography)(({ theme }) => ({
	fontWeight: 700,
	color: theme.palette.uc.text.main,
}));

const ItemExcerpt = styled(Typography)(({ theme }) => ({
	color: theme.palette.uc.text.medium,
}));

const ItemActions = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: 0,
	marginRight: `calc(-1 * ${theme.spacing(500)})`,
	marginLeft: theme.spacing(500),
	transition: 'margin 0.2s ease, gap 0.2s ease',
}));

const ItemType = styled(Box)(({ theme }) => ({
	display: 'none',
	height: 'fit-content',
	color: theme.palette.uc.text.main,
	width: 'max-content',
	padding: `${theme.spacing(100)} ${theme.spacing(200)}`,
	backgroundColor: theme.palette.uc.bg.medium,
	borderRadius: theme.borderRadius.roundedFull,
	fontSize: 14,
	whiteSpace: 'nowrap',
	[theme.breakpoints.up('md')]: {
		display: 'flex',
		alignItems: 'center',
	},
}));

const ContentTypeIcon = styled('img')(({ theme }) => ({
	width: theme.size.xS,
	height: theme.size.xS,
	marginRight: theme.spacing(50),
}));

const NoResults = styled(Typography)(({ theme }) => ({
	padding: `${theme.spacing(400)} ${theme.spacing(200)}`,
	textAlign: 'center',
	color: theme.palette.uc.text.weak,
}));

const StyledReturnIcon = styled(ReturnIcon)(({ theme }) => ({
	...keyboardIconStyles(theme),
	opacity: 0,
	transition: 'opacity 0.2s ease',
	marginLeft: theme.spacing(200),
}));

const StyledResultItem = styled(ResultItem)(({ theme }) => ({
	'&[aria-selected="true"], &:focus': {
		'& a': {
			outline: 'none',
			backgroundColor: theme.palette.uc.bg.weak,
		},
	},
	'&:active': {
		'& a': {
			backgroundColor: theme.palette.uc.bg.active,
			'& span': {
				color: theme.palette.uc.text.active,
			},
		},
		'& .item-type': {
			backgroundColor: theme.palette.uc.bg.main,
			color: theme.palette.uc.text.main,
		},
		'& .return-icon': {
			backgroundColor: theme.palette.uc.bg.main,
			color: theme.palette.uc.text.main,
		},
	},
	[theme.breakpoints.up('md')]: {
		'&[aria-selected="true"], &:focus': {
			'& .item-actions': {
				marginRight: 0,
				marginLeft: 0,
			},
			'& .return-icon': {
				display: 'block',
				opacity: 1,
			},
		},
	},
}));

/**
 * Individual search result item component
 * Displays a single search result with title, excerpt, and content type
 * @param {SearchResultItemProps} props
 * @returns {JSX.Element} SearchResultItem component
 */
function SearchResultItem({ item, itemProps }) {
	const { _snippetResult: snippetResult } = item;

	return (
		<StyledResultItem key={item.objectID} {...itemProps}>
			<ItemLink href={item.permalink} target="_blank">
				<ItemContent>
					<ItemTitle variant="label-xl">{item.post_title}</ItemTitle>
					{snippetResult?.content ? (
						<ItemExcerpt variant="body-md">
							{getHighlightedSnippet(snippetResult)}
						</ItemExcerpt>
					) : (
						<ItemExcerpt variant="body-md">{item.post_title}</ItemExcerpt>
					)}
				</ItemContent>
				<ItemActions className="item-actions">
					{item.content_type?.icon && (
						<ItemType className="item-type">
							{item.content_type.icon && (
								<ContentTypeIcon
									src={item.content_type.icon}
									alt={item.content_type.label}
								/>
							)}
							<span>{item.content_type.label}</span>
						</ItemType>
					)}
					<StyledReturnIcon className="return-icon" />
				</ItemActions>
			</ItemLink>
		</StyledResultItem>
	);
}

/**
 * @typedef Collection
 * @property {Array<SearchResultItemType>} items - Array of search result items
 * @property {Object} source - Source of the collection
 * @property {string} source.sourceId - Unique identifier for the source
 */

/**
 * @typedef SearchResultsProps
 * @property {Array<Collection>} collections - Collections of search results
 * @property {AutocompleteApi} autocomplete - Autocomplete instance with list management methods
 * @property {string} [query] - Current search query
 */

/**
 * Search results component that displays grouped search results
 * Renders lists of search results with proper keyboard navigation support
 * @param {SearchResultsProps} props
 * @returns {JSX.Element} SearchResults component
 */
export default function SearchResults({ collections, autocomplete, query }) {
	return (
		<>
			{collections.map((collection) => {
				const { items } = collection;
				return (
					<div key={collection.source.sourceId}>
						{items.length > 0 ? (
							<ResultsList {...autocomplete.getListProps()}>
								{items.map((item, itemIndex) => {
									const { onClick, ...rawItemProps } =
										autocomplete.getItemProps({
											item,
											// @ts-expect-error - Algolia's types are too strict for our implementation
											source: collection,
											index: itemIndex,
										});

									// Convert event types to React event types
									const itemProps = {
										...rawItemProps,
										/** @param {import('react').MouseEvent} event */
										onMouseDown: (event) =>
											rawItemProps.onMouseDown(event.nativeEvent),
										/** @param {import('react').MouseEvent} event */
										onMouseMove: (event) =>
											rawItemProps.onMouseMove(event.nativeEvent),
									};

									return (
										<SearchResultItem
											key={item.objectID}
											item={item}
											itemProps={itemProps}
										/>
									);
								})}
							</ResultsList>
						) : (
							<NoResults>No results found for &ldquo;{query}&rdquo;</NoResults>
						)}
					</div>
				);
			})}
		</>
	);
}
