import React from 'react';
import UnblockIPDialog from 'containers/structural/UnblockIp';
import ReferAFriendDialog from 'containers/structural/ReferAFriendDialog';
import DisallowedUserDialog from 'containers/structural/DisallowedUserDialog';
import RenewDialog from 'components/structural/RenewDialog';
import ProjectAddDialog from 'containers/pages/projects/ProjectAddDialog';
import DeleteProjectDialog from 'containers/pages/projects/DeleteDialog';
import ValidateAddressDialog from 'components/structural/ValidateAddressDialog';
import EditNameDialog from 'containers/pages/projects/EditNameDialog';
import TaskSchedulerDialog from 'containers/structural/TaskSchedulerDialog';
import StatusSheet from 'components/common/StatusSheet';
import ResetScrollUponNav from 'components/structural/ResetScrollUponNav';
import Dialogs from 'components/common/Dialogs';
import Drawer from 'components/common/Drawer';
import GlobalStyles from 'components/styles/GlobalStyles';
import SnackbarConnection from 'components/common/Snackbar/SnackbarConnection';

const GlobalComponents = () => {
	return (
		<>
			<GlobalStyles />
			<Dialogs />
			<RenewDialog />
			<ResetScrollUponNav />
			<UnblockIPDialog />
			<ProjectAddDialog />
			<DeleteProjectDialog />
			<EditNameDialog />
			<ReferAFriendDialog />
			<TaskSchedulerDialog />
			<SnackbarConnection />
			<DisallowedUserDialog />
			<StatusSheet />
			<ValidateAddressDialog />
			<Drawer />
		</>
	);
};

export default GlobalComponents;
