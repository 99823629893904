// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/network/ip/index.raw').LWApiPublicNetworkIpListPublicParamsRaw} params
 * @param {object} [options]
 * @param {import('@tanstack/react-query').UseQueryOptions['keepPreviousData']} [options.keepPreviousData]
 * @param {import('@tanstack/react-query').UseQueryOptions['enabled']} [options.enabled]
 */
const useNetworkIpListPublic = (params, { keepPreviousData, enabled } = {}) =>
	useQuery({
		queryKey: ['/network/ip/listPublic', params],
		queryFn: () => api.network.ip.apiRaw.listPublic(params),
		keepPreviousData,
		enabled,
	});

/**
 * @param {import('club-sauce/public/network/ip/index.raw').LWApiPublicNetworkIpListPublicParamsRaw} params
 * @param {object} [options]
 * @param {import('@tanstack/react-query').UseQueryOptions['enabled']} [options.enabled]
 */
export const usePollNetworkIpListPublic = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['/network/ip/listPublic', params],
		queryFn: () => api.network.ip.apiRaw.listPublic(params),
		enabled,
		refetchInterval: 30000,
	});

export default useNetworkIpListPublic;
