import {
	actions as resetActions,
	selectors as resetSelectors,
} from 'modules/api/server/power/resetModule';
import {
	actions as resizeActions,
	selectors as resizeSelectors,
} from 'modules/api/server/resizeModule';
import {
	actions as rebootActions,
	selectors as rebootSelectors,
} from 'modules/api/server/power/rebootModule';

const supportedTasks = {
	resize: {
		label: 'Resize',
		action: resizeActions,
		selectors: resizeSelectors,
		redirectPath: ({ uniq_id: uniqId }) => `/servers/${uniqId}`,
	},
	reboot: {
		label: 'Reboot',
		action: rebootActions,
		selectors: rebootSelectors,
		redirectPath: ({ uniq_id: uniqId }) => `/servers/${uniqId}`,
	},
	reset: {
		label: 'Reset',
		action: resetActions,
		selectors: resetSelectors,
		redirectPath: ({ uniq_id: uniqId }) => `/servers/${uniqId}`,
	},
};

export default supportedTasks;
