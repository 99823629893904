// @ts-check
import React from 'react';

import Dialog from 'undercurrent/Dialog';
import DialogActions from 'undercurrent/DialogActions';
import DialogContent from 'undercurrent/DialogContent';
import DialogTitle from 'undercurrent/DialogTitle';
import Typography from 'undercurrent/Typography';
import Stack from 'undercurrent/Stack';
import useServerShutdown from 'modules/queries/server/power/useShutdown';
import { useDispatch } from 'react-redux';
import Button from 'undercurrent/Button';
import snackbarActions from 'modules/snackbar/snackbarActions';
import FlagIcon from 'undercurrent/icons/lucide/Flag';
import ServerSpecsCard from '../SeverSpecsCard';

/** @param {Omit<import('undercurrent/Dialog').DialogProps, 'onClose'> & { onClose: () => void; uniqId: string; domain: string; ip: string }} dialogProps */
const DialogShutdownServer = ({
	domain,
	ip,
	onClose,
	uniqId,
	...dialogProps
}) => {
	const dispatch = useDispatch();
	const { mutate: shutdownServer, isLoading } = useServerShutdown();

	const handleConfirm = () => {
		shutdownServer(
			{ uniq_id: uniqId },
			{
				onSuccess: () => {
					onClose();
					dispatch(
						snackbarActions.pushMessage({
							variant: 'success',
							message: 'Server is shutting down',
						}),
					);
				},
			},
		);
	};

	return (
		<Dialog onClose={onClose} {...dialogProps}>
			<DialogTitle onClose={onClose}>Shutdown server</DialogTitle>
			<DialogContent>
				<Typography
					variant="body-md"
					sx={{ marginBottom: 200, display: 'block' }}
				>
					Are you sure sure you want to shutdown this server?
				</Typography>
				<ServerSpecsCard
					cardHeaderTitle="Selected server"
					cardProps={{
						sx: {
							borderBottomLeftRadius: 0,
							borderBottomRightRadius: 0,
							borderBottom: 'none',
						},
					}}
					domain={domain}
					ip={ip}
				/>
				<Stack
					sx={{
						paddingX: 200,
						paddingY: 100,
						backgroundColor: (theme) => theme.palette.uc.highlight.bg.weak,
						border: 'solid 1px',
						borderColor: (theme) => theme.palette.uc.highlight.border.weak,
						borderBottomLeftRadius: (theme) => theme.borderRadius.rounded,
						borderBottomRightRadius: (theme) => theme.borderRadius.rounded,
						flexDirection: 'row',
						gap: 150,
						alignItems: 'center',
					}}
				>
					<FlagIcon
						sx={{ color: (theme) => theme.palette.uc.highlight.icon.main }}
					/>
					<Typography variant="body-sm">
						You will continue to be invoiced while server is inactive
					</Typography>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button variant="secondary" onClick={onClose}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleConfirm} loading={isLoading}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DialogShutdownServer;
