import React from 'react';

import Counter from 'undercurrent/Counter';

import { useInput } from 'components/LWForm/LWForm';

/**
 * A counter input component to be used within the context of LWForm
 * @param {Omit<import('undercurrent/Counter').CounterProps, 'value'>} props
 */
const HookedCounter = ({
	helperText,
	label,
	name,
	onBlur,
	onChange,
	error: errorProp,
	required,
	...rest
}) => {
	const { value, onValueChange, error, validate, validateSimple } =
		useInput(name);

	if (!name) {
		return null;
	}

	/** @param {number} newValue */
	const handleChange = (newValue) => {
		if (!newValue && newValue !== 0) {
			return;
		}

		if (onChange) {
			onChange(newValue);
		}

		onValueChange(newValue);

		// validate on input change only if there is an existing error
		if (!error) {
			return;
		}

		validateSimple(newValue);
	};

	/** @param {React.FocusEvent<HTMLInputElement, Element>} event */
	const handleBlur = (event) => {
		if (!event.target) {
			return;
		}

		if (onBlur) {
			onBlur(event);
		}

		validate(event.target);
	};

	return (
		<Counter
			helperText={error || errorProp || helperText}
			label={label}
			name={name}
			onBlur={handleBlur}
			onChange={handleChange}
			error={Boolean(error || errorProp)}
			required={required}
			{...rest}
			value={value}
		/>
	);
};

export default HookedCounter;
