import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/notifications/index.raw').LWApiPublicNotificationCurrentParamsRawI} params
 * @param {Parameters<typeof useQuery>[2]?} options
 */
const useNotificationsCurrent = (params, options = {}) =>
	useQuery({
		...options,
		queryKey: ['/notifications/current', params],
		queryFn: () => api.notifications.apiRaw.current(params),
	});

/**
 * @param {import('club-sauce/public/notifications/index.raw').LWApiPublicNotificationCurrentParamsRawI} params
 */
export const usePollNotificationsCurrent = (params) =>
	useQuery({
		queryKey: ['/notifications/current', params],
		queryFn: () => api.notifications.apiRaw.current(params),
		refetchInterval: 30000,
	});

export default useNotificationsCurrent;
