// @ts-check
import React, { useEffect, useRef, useState } from 'react';

import Box from 'undercurrent/Box';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import PencilIcon from 'undercurrent/icons/lucide/Pencil';
import combineSx from 'undercurrent/utility/combineSx';

/**
 * @typedef {Omit<import('react').DetailedHTMLProps<import('react').InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'value'> & {
 *   label: import('react').ReactNode
 *   value: string
 *   collapsed: boolean
 *   onEditClick: () => void
 *   labelProps?: import('@mui/material').BoxProps & React.LabelHTMLAttributes<HTMLLabelElement>
 * }} OptionProps
 * */

/**
 * @param {OptionProps} props
 * */
const Option = ({
	label,
	collapsed,
	onEditClick,
	value,
	onChange,
	onClick,
	labelProps,
	...props
}) => {
	return (
		<Box
			component="label"
			sx={{
				position: 'relative',
				display: 'block',
				cursor: collapsed ? 'default' : 'pointer',
				padding: 200,
				paddingRight: collapsed ? 500 : 200,

				'&:has(input:focus-visible)': {
					backgroundColor: ({ palette }) => palette.uc.bg.weak,
				},
			}}
			{...labelProps}
		>
			{label}
			<input
				type="radio"
				style={{
					position: 'absolute',
					opacity: 0,
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					height: '0px',
					width: '0px',
				}}
				value={value}
				onChange={onChange}
				{...props}
			/>
			{collapsed && (
				<IconButton
					onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();
						onEditClick();
					}}
					variant="barebones"
					size="small"
					sx={{
						position: 'absolute',
						top: '50%',
						right: ({ spacing }) => spacing(200),
						transform: 'translateY(-50%)',
					}}
				>
					<PencilIcon
						sx={{ height: ({ size }) => size.xS, width: ({ size }) => size.xS }}
					/>
				</IconButton>
			)}
		</Box>
	);
};

/**
 * @typedef CollapsingRadioTilesProps
 * @property {Omit<OptionProps, 'collapsed' | 'onEditClick'>[]} options
 * @property {string} name
 * @property {string} value
 * @property {(event: import('react').ChangeEvent<HTMLInputElement>) => void} [onChange]
 * @property {import('react').ReactNode} [lockedLabel] if provided, the tiles are collapsed and just the locked label is visible
 * @property {() => void} [onEditClick]
 * */

/** @param {CollapsingRadioTilesProps & Omit<import('@mui/material').CardProps, 'onChange'>} props */
const CollapsingRadioTiles = ({
	options,
	name,
	value,
	onChange,
	lockedLabel,
	onEditClick,
	sx,
	...rest
}) => {
	const [isFocused, setIsFocused] = useState(false);
	const ref = useRef(/** @type {HTMLDivElement | null} */ (null));

	const handleEditClick = () => {
		onChange?.(
			// @ts-ignore We're creating a minimal
			// synthetic event here to clear the
			// value. This keeps the onChange
			// handler interface simple
			{ target: { value: '' } },
		);
		onEditClick?.();
	};

	/** @param {import('react').KeyboardEvent<HTMLInputElement>} event */
	const handleKeyDown = (event) => {
		if (value && event.key === 'Enter') {
			event.preventDefault();
			setIsFocused(false);
		}

		if (value && (event.key === ' ' || event.key === 'Tab')) {
			setIsFocused(false);
		}
	};

	useEffect(() => {
		/** @param {MouseEvent} event */
		const handleClickOutside = (event) => {
			if (
				ref.current &&
				!ref.current.contains(
					// @ts-expect-error
					event.target,
				)
			) {
				setIsFocused(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const isCollapsed = Boolean(value) && !isFocused;

	return (
		<Card
			sx={combineSx(
				{
					display: 'flex',
					flexDirection: 'column',
					borderStyle: options.length > 0 || lockedLabel ? '1px solid' : 'none',
					borderColor: ({ palette }) =>
						value && !lockedLabel ? palette.uc.border.main : undefined,
					overflow: 'hidden',
				},
				sx,
			)}
			ref={ref}
			{...rest}
		>
			<TransitionGroup>
				{lockedLabel ? (
					<Collapse>
						<Box
							sx={{
								padding: 200,
								typography: 'title-xs',
								fontWeight: 500,
								color: ({ palette }) => palette.uc.text.weak,
							}}
						>
							{lockedLabel}
						</Box>
					</Collapse>
				) : (
					options
						.filter((option) => {
							if (isCollapsed) {
								return option.value === value;
							}

							return option;
						})
						.map(({ value: optionValue, labelProps, ...restOptionProps }) => (
							<Collapse
								key={optionValue}
								sx={{
									':not(:last-child)': {
										borderBottom: '1px solid',
										borderColor: ({ palette }) => palette.uc.border.weak,
									},
								}}
							>
								<Option
									name={name}
									value={optionValue}
									checked={value === optionValue}
									onChange={(event) => {
										if (event.target.matches(':focus-visible')) {
											setIsFocused(true);
										}

										onChange?.(event);
									}}
									collapsed={isCollapsed}
									onEditClick={handleEditClick}
									labelProps={{
										onMouseUp: () => {
											setIsFocused(false);
										},
										...labelProps,
									}}
									onKeyDown={handleKeyDown}
									{...restOptionProps}
								/>
							</Collapse>
						))
				)}
			</TransitionGroup>
		</Card>
	);
};

export default CollapsingRadioTiles;
