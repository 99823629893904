// @ts-check
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'modules/queries/api';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { useDispatch } from 'react-redux';
import useSnackbarError from 'utility/hooks/useSnackbarError';

const useStateDataRemove = () => {
	const queryClient = useQueryClient();
	const snackbarError = useSnackbarError();

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/account/user/stateData/index.raw').LWApiPublicAccountUserStateDataRemoveParamsRaw} params */
			(params) => api.account.user.stateData.apiRaw.remove(params),

		onSuccess: (data, vars) => {
			queryClient.invalidateQueries({
				queryKey: ['/account/user/statedata/listforkey', { key: vars.key }],
			});
		},

		onError: snackbarError,
	});
};

export const useRemoveFavorite = () => {
	const queryClient = useQueryClient();
	const snackbarError = useSnackbarError();
	const dispatch = useDispatch();

	return useMutation({
		mutationFn:
			/** @param {{ uuid: string }} params */
			({ uuid }) =>
				api.account.user.stateData.apiRaw.remove({
					key: 'favorites',
					uuid,
				}),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['/account/user/statedata/listforkey', { key: 'favorites' }],
			});

			dispatch(
				snackbarActions.pushMessage({
					variant: 'success',
					message: 'Removed from favorites',
				}),
			);
		},

		onError: snackbarError,
	});
};

export default useStateDataRemove;
