import React from 'react';

import Stack from 'undercurrent/Stack';
import Typography from 'undercurrent/Typography';

import CheckIcon from 'undercurrent/icons/lucide/Check';

const NoAlerts = () => (
	<Stack flex={1} justifyContent="center" alignItems="center" spacing={200}>
		<CheckIcon
			sx={(theme) => ({
				color: theme.palette.uc.icon.weak,
				width: theme.size.md,
				height: theme.size.md,
			})}
		/>
		<Typography
			variant="body-md"
			sx={(theme) => ({
				color: theme.palette.uc.text.weak,
			})}
		>
			No alerts
		</Typography>
	</Stack>
);

export default NoAlerts;
