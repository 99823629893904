const prod = {
	baseURL: 'https://api.liquidweb.com/',
	session: {
		length: 900,
		warningTime: 150,
	},
	hosts: {
		www: 'www.liquidweb.com',
		cart: 'cart.liquidweb.com',
		manage: 'manage.liquidweb.com',
		salesforce: 'liquidweb.lightning.force.com/lightning/r',
		customer_community: 'help.liquidweb.com/s',
		managed_apps: 'app.liquidweb.com',
		authorize_net: 'accept.authorize.net',
		gator: 'gator.liquidweb.com',
	},
	gaTrackerId: 'UA-363336-2',
	gtmContainerId: 'GTM-5GC5R3B',
	cartStack: {
		siteId: 'k5FaX1dK',
		trackingScriptUrl:
			'https://api.cartstack.com/js/customer-tracking/cart.liquidweb.com_71f77bbc0c9db256f407bbc6821d6994.js',
	},
	guestAuth: {
		username: 'remote-signup',
		password: 's|gnm3up',
	},
	cart: {
		checkoutURL: 'https://www.liquidweb.com/cart/checkout',
	},
	chatConfig: {
		enabled: true,
		orgId: '00D30000000pmDy',
		deploymentId: 'Liquid_Web_Support_Portal_MIAW',
		communityURL:
			'https://liquidweb.my.site.com/ESWLiquidWebSupportPor1740147560083',
		scrt2URL: 'https://liquidweb.my.salesforce-scrt.com',
		messagingScriptURL:
			'https://liquidweb.my.site.com/ESWLiquidWebSupportPor1740147560083/assets/js/bootstrap.min.js',
		settings: {
			language: 'en_US',
			chatButtonPosition: '32px,32px',
		},
		storageDomain: 'liquidweb.com',
	},
	openId: {
		client_id: 'HxSmx8sSSTKOuvDEKYRdgw',
		authority: 'https://login.liquidweb.com',
		accessTokenExpiringNotificationTime: 1200,
	},
	algolia: {
		applicationId: 'J360JFWYSQ',
		searchOnlyApiKey: '0b82cb616431ca0e42b6818bd8259bb8',
	},
};

export default prod;
