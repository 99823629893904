// @ts-check
import React, { useState } from 'react';

import Box from 'undercurrent/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from 'undercurrent/Stack';
import TextField from '@mui/material/TextField';
import Typography from 'undercurrent/Typography';
import { useTaskScheduler } from 'components/common/TaskScheduler';
import { HookedServerSpecsCard } from 'components/standard/SeverSpecsCard';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import useServerPowerReboot from 'modules/queries/server/power/useReboot';
import useServerPowerReset from 'modules/queries/server/power/useReset';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { useDispatch } from 'react-redux';
import Button from 'undercurrent/Button';
import DrawerContent from 'undercurrent/DrawerContent';
import DrawerFooter from 'undercurrent/DrawerFooter';
import DrawerHeader from 'undercurrent/DrawerHeader';
import FormControlLabel from 'undercurrent/FormControlLabel';

dayjs.extend(duration);

/** @param {Omit<import('@mui/material').DrawerProps, 'onClose'> & { onClose: () => void; uniqId: string }} drawerProps */
const DrawerReboot = ({ uniqId, open, onClose, ...drawerProps }) => {
	const dispatch = useDispatch();

	const [isForceReboot, setIsForceReboot] = useState(false);
	const {
		deferError,
		deferUntil,
		deferUntilDayjs,
		isValidDeferTime,
		mode,
		resetDeferUntil,
		setDeferUntil,
		setMode,
	} = useTaskScheduler();

	const { mutate: rebootServer, isLoading: rebootIsLoading } =
		useServerPowerReboot();
	const { mutate: forceRebootServer, isLoading: forceRebootIsLoading } =
		useServerPowerReset();

	const handleClose = () => {
		resetDeferUntil();
		setIsForceReboot(false);
		setMode('immediate');

		onClose();
	};

	/** @param {import('react').FormEvent<HTMLFormElement>} event */
	const handleSubmit = (event) => {
		event.preventDefault();

		const now = dayjs();
		const deferSeconds = Math.floor(
			dayjs.duration(deferUntilDayjs.diff(now)).asSeconds(),
		).toString();

		const metaTimingDefer = mode === 'deferred' ? deferSeconds : undefined;

		if (isForceReboot) {
			forceRebootServer(
				{ uniq_id: uniqId, metaTimingDefer },
				{
					onSuccess: () => {
						handleClose();
						dispatch(
							snackbarActions.pushMessage({
								variant: 'success',
								message: metaTimingDefer
									? 'Sever force reboot scheduled'
									: 'Server force reboot has started',
							}),
						);
					},
				},
			);

			return;
		}

		rebootServer(
			{ uniq_id: uniqId, metaTimingDefer },
			{
				onSuccess: () => {
					handleClose();
					dispatch(
						snackbarActions.pushMessage({
							variant: 'success',
							message: metaTimingDefer
								? 'Server reboot scheduled'
								: 'Server reboot has started',
						}),
					);
				},
			},
		);
	};

	return (
		<Drawer anchor="right" open={open} onClose={handleClose} {...drawerProps}>
			<form
				onSubmit={handleSubmit}
				style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
			>
				<DrawerHeader onClose={handleClose} gap={50}>
					<Typography fontWeight={500} variant="title-xs">
						Reboot Server
					</Typography>
					<Typography
						variant="body-sm"
						sx={{ color: (theme) => theme.palette.uc.text.medium }}
					>
						Restart your server right away or schedule it for later
					</Typography>
				</DrawerHeader>
				<DrawerContent>
					<RadioGroup
						name="mode"
						value={mode}
						onChange={(e, newValue) => setMode(newValue)}
						sx={{ gap: 300 }}
					>
						<FormControlLabel
							value="immediate"
							label="Reboot now"
							control={<Radio />}
						/>
						<Stack>
							<FormControlLabel
								value="deferred"
								label="Schedule for later"
								description="Allows you to set a date and time to reboot"
								control={<Radio />}
							/>
							<Collapse in={mode === 'deferred'}>
								<TextField
									fullWidth
									type="datetime-local"
									name="deferUntil"
									label="Pick a date"
									value={deferUntil}
									onChange={({ target }) => setDeferUntil(target.value)}
									slotProps={{
										htmlInput: {
											min: dayjs().format('YYYY-MM-DDTHH:mm'),
											max: dayjs().add(7, 'day').format('YYYY-MM-DDTHH:mm'),
										},
									}}
									error={Boolean(deferError)}
									helperText={deferError || ''}
									sx={{ marginTop: 300 }}
								/>
							</Collapse>
						</Stack>
					</RadioGroup>

					<HookedServerSpecsCard
						uniqId={uniqId}
						cardHeaderTitle="Selected server to reboot"
					>
						<Box
							sx={{
								backgroundColor: (theme) => theme.palette.uc.bg.weak,
								padding: 200,
							}}
						>
							<FormControl>
								<FormControlLabel
									label="Force Reboot"
									description="Enable this if the server is unresponsive"
									control={
										<Checkbox
											name="forceReboot"
											checked={isForceReboot}
											onChange={(e, newChecked) => setIsForceReboot(newChecked)}
										/>
									}
								/>
							</FormControl>
						</Box>
					</HookedServerSpecsCard>
				</DrawerContent>
				<DrawerFooter
					sx={{ display: 'flex', flexDirection: 'column', gap: 100 }}
				>
					<Button
						variant="primary"
						disabled={!isValidDeferTime}
						type="submit"
						loading={rebootIsLoading || forceRebootIsLoading}
					>
						{mode === 'deferred' ? 'Schedule' : 'Reboot'}
					</Button>
					<Button onClick={handleClose} variant="ghost">
						Cancel
					</Button>
				</DrawerFooter>
			</form>
		</Drawer>
	);
};

export default DrawerReboot;
