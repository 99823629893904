import React from 'react';
import DOMPurify from 'dompurify';

/**
 * @param {object} props
 * @param {string} props.html
 * @param {DOMPurify.Config} [props.domPurifyConfig]
 * */
const PurifyHtml = ({ html, domPurifyConfig }) => {
	if (DOMPurify.isSupported) {
		return (
			<div
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(html, {
						FORBID_ATTR: ['style'],
						FORBID_TAGS: ['style'],
						...domPurifyConfig,
					}),
				}}
			/>
		);
	}

	return <div>{html}</div>;
};

export default PurifyHtml;
