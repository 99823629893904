// @ts-check
import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Drawer from '@mui/material/Drawer';
import Stack from 'undercurrent/Stack';
import Typography from 'undercurrent/Typography';
import LWForm from 'components/LWForm';
import { HookedServerSpecsCard } from 'components/standard/SeverSpecsCard';
import hostingDetailsAlsowith from 'containers/pages/servers/details/hostingDetailsAlsowith';
import { actions as cloneActions } from 'modules/basket/addItemForClone';
import cartSelectors from 'modules/cart/selectors';
import useAssetDetails from 'modules/queries/asset/useDetails';
import { useDispatch, useSelector } from 'react-redux';
import DrawerContent from 'undercurrent/DrawerContent';
import DrawerFooter from 'undercurrent/DrawerFooter';
import DrawerHeader from 'undercurrent/DrawerHeader';
import { DrawerAbandonCartContents } from '../DrawerAbandonCart/DrawerAbandonCart';
import { HookedCheckbox, HookedSubmit } from '../Form';

/** @param {Omit<import('@mui/material').DrawerProps, 'onClose'> & { onClose: () => void, uniqId: string }} drawerProps */
const DrawerCloneServer = ({ onClose, uniqId, open, ...drawerProps }) => {
	const dispatch = useDispatch();

	/** @type {import('components/LWForm/LWForm').FormValues} */
	const initialValues = { confirm: false };
	const [formValues, setFormValues] = useState(initialValues);
	const [formErrors, setFormErrors] = useState({});
	const [showAbandonCartContents, setShowAbandonCartContents] = useState(false);

	const { data: assetDetails } = useAssetDetails(
		{
			uniq_id: uniqId,
			alsowith: hostingDetailsAlsowith,
		},
		{ enabled: Boolean(open) },
	);

	const { displayType, featureDetails, type = '' } = assetDetails || {};

	const isCartLocked = useSelector(cartSelectors.getIsCartLocked);

	const handleClose = () => {
		onClose();
		setShowAbandonCartContents(false);
		setFormValues({ confirm: false });
		setFormErrors({});
	};

	const addItemForClone = () => {
		dispatch(
			cloneActions.addItemForClone({
				uniqId,
				force: !featureDetails,
				productCode: type,
			}),
		);
	};

	const handleSubmit = () => {
		if (isCartLocked) {
			setShowAbandonCartContents(true);
		} else {
			handleClose();
			addItemForClone();
		}
	};

	if (showAbandonCartContents) {
		return (
			<Drawer anchor="right" onClose={handleClose} open={open} {...drawerProps}>
				<DrawerAbandonCartContents
					onAbandoned={addItemForClone}
					onClose={handleClose}
				/>
			</Drawer>
		);
	}

	return (
		<Drawer anchor="right" onClose={handleClose} open={open} {...drawerProps}>
			<DrawerHeader onClose={handleClose}>Clone Server</DrawerHeader>
			<LWForm
				values={formValues}
				onValuesChange={setFormValues}
				errors={formErrors}
				onErrorsChange={setFormErrors}
				onSubmit={handleSubmit}
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					height: '100%',
				}}
			>
				<DrawerContent>
					<Typography variant="body-md">
						A new {displayType} will be added to your cart with an identical
						configuration to this one. You will be able to change it before
						checkout.
					</Typography>

					<HookedServerSpecsCard
						uniqId={uniqId}
						cardHeaderTitle="Selected server to clone"
					/>

					<Card>
						<CardContent
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: 200,
								padding: 200,
							}}
						>
							<Stack gap={50}>
								<Typography fontWeight={500} variant="body-md">
									Potential for lost data
								</Typography>
								<Typography variant="body-sm">
									After checkout, all data will be copied from the original
									server to the clone server.{' '}
									<b>
										If the current server is busy, this could lead to cloning
										incomplete files and corrupted data.
									</b>
								</Typography>
							</Stack>
							<HookedCheckbox
								name="confirm"
								label="Confirm and acknowledge"
								required
							/>
						</CardContent>
					</Card>
				</DrawerContent>
				<DrawerFooter sx={{ gap: 100 }}>
					<HookedSubmit variant="primary">Add to cart</HookedSubmit>
					<Button variant="ghost" onClick={handleClose}>
						Cancel
					</Button>
				</DrawerFooter>
			</LWForm>
		</Drawer>
	);
};

export default DrawerCloneServer;
