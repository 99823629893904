/**
 * @typedef SkipValidations
 * @property {boolean} [badInput]
 * @property {boolean} [customError]
 * @property {boolean} [patternMismatch]
 * @property {boolean} [rangeOverflow]
 * @property {boolean} [rangeUnderflow]
 * @property {boolean} [stepMismatch]
 * @property {boolean} [tooLong]
 * @property {boolean} [tooShort]
 * @property {boolean} [typeMismatch]
 * @property {boolean} [valueMissing]
 * */

/**
 * @param {ValidityState} validityState
 * @param {SkipValidations} skipValidations
 */
export const checkValidity = (validityState, skipValidations = {}) => {
	if (validityState.valid) return true;

	const {
		badInput,
		customError,
		patternMismatch,
		rangeOverflow,
		rangeUnderflow,
		stepMismatch,
		tooLong,
		tooShort,
		typeMismatch,
		valueMissing,
	} = validityState;

	if (badInput && !skipValidations.badInput) return false;
	if (customError && !skipValidations.customError) return false;
	if (patternMismatch && !skipValidations.patternMismatch) return false;
	if (rangeOverflow && !skipValidations.rangeOverflow) return false;
	if (rangeUnderflow && !skipValidations.rangeUnderflow) return false;
	if (stepMismatch && !skipValidations.stepMismatch) return false;
	if (tooLong && !skipValidations.tooLong) return false;
	if (tooShort && !skipValidations.tooShort) return false;
	if (typeMismatch && !skipValidations.typeMismatch) return false;
	if (valueMissing && !skipValidations.valueMissing) return false;

	return true;
};

/**
 * @param {HTMLInputElement | HTMLTextAreaElement | null} field
 * @param {SkipValidations} skipValidations set validity key to `true` to ignore
 * @author Chris Fernandi https://css-tricks.com/form-validation-part-2-constraint-validation-api-javascript/
 * */
export default function inputHasError(field, skipValidations = {}) {
	// Don't validate submits, buttons, file and reset inputs, and disabled fields
	if (
		!field ||
		field.disabled ||
		field.type === 'file' ||
		field.type === 'reset' ||
		field.type === 'submit' ||
		field.type === 'button'
	)
		return '';

	// Get validity
	const { validity, validationMessage } = field;

	const valid = checkValidity(validity, skipValidations);

	if (valid) return '';

	return validationMessage;
}
