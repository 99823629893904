import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { roles } from 'utility/constants/roles';
import { withRouter } from 'react-router';
import startChatProgrammatically from 'utility/chat/startChatProgrammatically';

const ChatRouter = ({ history }) => {
	// Opens chat and redirects user to home
	useEffect(() => {
		startChatProgrammatically();
		history.push('/');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Switch>
			<RoleRestrictedRoute
				exact
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				path="/chat/"
				component={() => <div />}
			/>
		</Switch>
	);
};

export default withRouter(ChatRouter);
