// @ts-check
import React from 'react';

import AttributesLoader from 'components/standard/PageLayout/AttributesLoader';
import hostingDetailsAlsowith from 'containers/pages/servers/details/hostingDetailsAlsowith';
import useAssetDetails from 'modules/queries/asset/useDetails';
import { useParams } from 'react-router';
import AttributesContent from './AttributesContent';

const Attributes = () => {
	// @ts-expect-error
	const { uniqId } = useParams();
	const { data, isLoading, isError } = useAssetDetails(
		{
			alsowith: hostingDetailsAlsowith,
			uniq_id: uniqId,
		},
		{ enabled: Boolean(uniqId) },
	);

	if (isError) {
		return null;
	}

	if (isLoading) {
		return <AttributesLoader />;
	}

	return <AttributesContent {...data} />;
};

export default Attributes;
