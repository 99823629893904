import React from 'react';

import TextField from '@mui/material/TextField';
import { useInput } from 'components/LWForm/LWForm';

/**
 * A text input component to be used within the context of LWForm
 * @param {import('@mui/material').TextFieldProps} props
 */
const HookedTextField = ({
	helperText,
	label,
	name,
	onBlur,
	onChange,
	placeholder,
	error: errorProp,
	select,
	required,
	...rest
}) => {
	const { value, onValueChange, error, validate, validateSimple } =
		useInput(name);

	if (!name) {
		return null;
	}

	/** @param {React.ChangeEvent<HTMLInputElement>} event */
	const handleChange = (event) => {
		if (!event.target) {
			return;
		}

		if (onChange) {
			onChange(event);
		}

		onValueChange(event.target.value);

		// validate on input change only if there is an existing error
		if (!error) {
			return;
		}

		if (select) {
			validateSimple(value, required);
		} else {
			validate(event.target);
		}
	};

	/** @param {React.FocusEvent<HTMLInputElement, Element>} event */
	const handleBlur = (event) => {
		if (!event.target) {
			return;
		}

		if (onBlur) {
			onBlur(event);
		}

		if (select) {
			validateSimple(value, required);
		} else {
			validate(event.target);
		}
	};

	return (
		<TextField
			helperText={error || errorProp || helperText}
			label={label}
			name={name}
			onBlur={handleBlur}
			onChange={handleChange}
			placeholder={placeholder || label}
			error={Boolean(error || errorProp)}
			select={select}
			required={required}
			{...rest}
			value={value}
		/>
	);
};

export default HookedTextField;
