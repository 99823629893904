import React, { useRef, useState } from 'react';

import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from 'undercurrent/Stack';
import TextField from '@mui/material/TextField';
import CirclePlusIcon from 'undercurrent/icons/lucide/CirclePlus';
import { useInput } from 'components/LWForm/LWForm';

/**
 * A text input with multiple values
 * @param {import('@mui/material').TextFieldProps} props
 */
const HookedTextField = ({
	helperText,
	label,
	name,
	placeholder,
	error: errorProp,
	select,
	required,
	...rest
}) => {
	const [inputValue, setInputValue] = useState('');
	const [inputError, setInputError] = useState('');
	const { value, validate, onValueChange } = useInput(name);

	const inputRef = useRef(null);

	if (!Array.isArray(value)) {
		throw new Error('HookedTextFieldChips: value must be an array');
	}

	if (!name) {
		throw new Error('HookedTextFieldChips: name is required');
	}

	const handleChange = ({ target }) => {
		if (inputError) {
			setInputError(validate(target));
		}

		setInputValue(target.value);
	};

	const handleAddChip = () => {
		const newError = validate(inputRef.current);
		if (newError) {
			setInputError(newError);
			return;
		}

		const newItem = inputRef.current?.value;

		if (!newItem || value.includes(newItem)) {
			return;
		}

		onValueChange([...value, newItem]);
		setInputValue('');
	};

	const handleRemoveChip = (chipToRemove) => {
		onValueChange(value.filter((chip) => chip !== chipToRemove));
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();

			handleAddChip();
		}
	};

	return (
		<Stack gap={100}>
			<TextField
				helperText={inputError || errorProp || helperText}
				label={label}
				name={name}
				placeholder={placeholder || label}
				error={Boolean(inputError || errorProp)}
				select={select}
				required={required}
				onKeyDown={handleKeyDown}
				slotProps={{
					input: {
						endAdornment: (
							<InputAdornment position="end" sx={{ cursor: 'pointer' }}>
								<CirclePlusIcon onClick={handleAddChip} />
							</InputAdornment>
						),
					},
					htmlInput: {
						className: 'input-HookedTextFieldChips',
					},
				}}
				inputRef={inputRef}
				{...rest}
				value={inputValue}
				onChange={handleChange}
			/>

			<Stack direction="row" gap={100} flexWrap="wrap">
				{value.map((chip) => (
					<Chip
						key={chip}
						label={chip}
						onDelete={() => handleRemoveChip(chip)}
					/>
				))}
			</Stack>
		</Stack>
	);
};

export default HookedTextField;
