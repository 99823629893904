// @ts-check
import React, { useEffect, useMemo } from 'react';

import Box from 'undercurrent/Box';
import Stack from 'undercurrent/Stack';
import { formatCurrency } from 'banana-stand/format';
import { useInput } from 'components/LWForm';
import ControlPanelIcon from 'components/standard/ControlPanelIcon';
import HookedCollapsingRadioTiles from 'components/standard/Form/HookedCollapsingRadioTiles';
import OSIcon from 'components/standard/OSIcon';
import Tooltip from 'undercurrent/Tooltip';
import InfoIcon from 'undercurrent/icons/lucide/Info';
import OptionLabel from './OptionLabel';
import WPOptimizedChip from './WPOptimizedChip';
import {
	checkIsWpOptimized,
	createOsDescription,
	createOsValue,
	managementMap,
} from './helpers';

const Spacer = () => (
	<Box sx={{ display: 'flex', justifyContent: 'center' }}>
		<Box
			sx={{
				height: '36px',
				width: '1px',
				backgroundColor: ({ palette }) => palette.uc.border.weak,
			}}
		/>
	</Box>
);

/**
 * @typedef OsOption
 * @property {string} description
 * @property {string} osName
 * @property {string} osVersion
 * @property {string} osBit
 * @property {string} value
 * @property {boolean} wpOptimized
 * */

/**
 * @param {{
 *   onValuesChange: import('react').Dispatch<React.SetStateAction<{
 *     os: string;
 *     management: string;
 *     controlPanel: string;
 *     force: boolean;
 *     confirm: boolean;
 *   }>>
 *   formValues: Record<string, string | boolean>,
 *   templates: import('club-sauce/public/server/template/index.raw').LWApiPublicServerTemplateListResultRaw['items']
 *   productOptionsData?: import('club-sauce/public/product/index.raw').LWApiPublicProductFlatDetailsResultFlatOptionRawI[],
 * }} props
 * */
const FormInputs = ({
	onValuesChange,
	formValues,
	templates,
	productOptionsData,
}) => {
	const osOptions = useMemo(() => {
		/** @type {OsOption[]} */
		const toReturn = [];

		templates.forEach(
			({
				os_name: osName,
				os_version: osVersion,
				os_bit: osBit,
				os_type: osType,
			}) => {
				const description = createOsDescription({
					osName,
					osVersion,
					isWindows: osType.toLowerCase().includes('windows'),
				});

				const value = createOsValue({
					osName,
					osVersion,
					osBit,
				});

				const wpOptimized = checkIsWpOptimized(osName);

				if (!toReturn.some((os) => os.value === value)) {
					toReturn.push({
						description,
						osName,
						osVersion,
						osBit,
						value,
						wpOptimized,
					});
				}
			},
		);

		return toReturn;
	}, [templates]);

	const managementOptions = useMemo(() => {
		/** @type {string[]} */
		const toReturn = [];

		templates
			.filter(({ os_name: osName, os_version: osVersion, os_bit: osBit }) => {
				if (formValues.os) {
					return (
						formValues.os ===
						createOsValue({
							osName,
							osVersion,
							osBit,
						})
					);
				}

				return true;
			})
			.forEach(({ management }) => {
				if (management && !toReturn.includes(management)) {
					toReturn.push(management);
				}
			});

		return toReturn;
	}, [formValues.os, templates]);

	const controlPanelOptions = useMemo(() => {
		/** @type {{value: string, price: number | undefined}[]} */
		const toReturn = [];

		const filteredTemplates = templates.filter(
			({
				os_name: osName,
				os_version: osVersion,
				os_bit: osBit,
				management,
			}) => {
				if (formValues.os && formValues.management) {
					return (
						formValues.os ===
							createOsValue({
								osName,
								osVersion,
								osBit,
							}) && formValues.management === management
					);
				}

				if (formValues.os && !formValues.management) {
					return (
						formValues.os ===
						createOsValue({
							osName,
							osVersion,
							osBit,
						})
					);
				}

				return true;
			},
		);

		// the cheapest option left
		const controlPanelBasePrice = filteredTemplates.reduce((acc, current) => {
			const price = productOptionsData
				?.find((option) => option.key === current.type)
				?.values?.find((value) => value.value === current.name)
				?.region_id_prices?.[current.region_id];

			if (typeof price === 'undefined') {
				return acc;
			}

			if (Number(price) < acc) {
				return Number(price);
			}

			return acc;
		}, Infinity);

		filteredTemplates.forEach(
			({
				name: templateName,
				control_panel: controlPanel,
				type,
				region_id: regionId,
			}) => {
				if (
					controlPanel &&
					!toReturn.some(({ value }) => value === controlPanel)
				) {
					const templatePrice = productOptionsData
						?.find((option) => option.key === type)
						?.values?.find((value) => value.value === templateName)
						?.region_id_prices?.[regionId];

					const controlPanelPrice =
						typeof templatePrice === 'undefined'
							? undefined
							: Number(templatePrice) - controlPanelBasePrice;

					toReturn.push({
						value: controlPanel,
						price: controlPanelPrice,
					});
				}
			},
		);

		const hasNoneOption =
			filteredTemplates.length > 1 &&
			filteredTemplates.some((template) => !template.control_panel);

		if (hasNoneOption) {
			toReturn.push({
				value: 'None',
				price: 0,
			});
		}

		return toReturn;
	}, [templates, formValues.os, formValues.management, productOptionsData]);

	// handle single option effects
	const { onValueChange: changeMangement } = useInput('management');
	const { onValueChange: changeControlPanel } = useInput('controlPanel');

	useEffect(() => {
		if (
			formValues.os &&
			managementOptions.length === 1 &&
			!formValues.management
		) {
			changeMangement(managementOptions[0]);
		}
	}, [
		managementOptions,
		formValues.management,
		changeMangement,
		formValues.os,
	]);

	useEffect(() => {
		if (
			formValues.os &&
			formValues.management &&
			controlPanelOptions.length === 1 &&
			!formValues.controlPanel
		) {
			changeControlPanel(controlPanelOptions[0].value);
		}
	}, [
		controlPanelOptions,
		formValues.controlPanel,
		changeControlPanel,
		formValues.os,
		formValues.management,
	]);

	const showControlPanel = controlPanelOptions.length > 0;

	const osOptionsSorted = [...osOptions].sort((a, b) => {
		const descriptionCompare = a.description.localeCompare(b.description);
		if (descriptionCompare !== 0) return descriptionCompare;
		// If descriptions are equal, put non-wpOptimized first
		return Number(a.wpOptimized) - Number(b.wpOptimized);
	});

	const managementOptionsSorted = [...managementOptions].sort(
		(a, b) => managementMap[a].displayOrder - managementMap[b].displayOrder,
	);

	const controlPanelOptionsSorted = [...controlPanelOptions].sort((a, b) => {
		if (a.value === 'None') return -1;

		// If either price is undefined, sort undefined values to the top
		if (typeof a.price === 'undefined') return -1;
		if (typeof b.price === 'undefined') return 1;

		return a.price - b.price;
	});

	return (
		<Stack>
			<HookedCollapsingRadioTiles
				options={osOptionsSorted.map(
					({ description, osName, osBit, value, wpOptimized }) => ({
						label: (
							<OptionLabel
								description={description}
								icon={<OSIcon os={osName} />}
								detail={`${osBit}-bit`}
								chip={wpOptimized && <WPOptimizedChip />}
							/>
						),
						value,
					}),
				)}
				name="os"
				onEditClick={() => {
					onValuesChange((prev) => ({
						...prev,
						management: '',
						controlPanel: '',
					}));
				}}
			/>

			<Spacer />

			<HookedCollapsingRadioTiles
				options={managementOptionsSorted.map((management) => ({
					label: (
						<OptionLabel
							description={managementMap[management].label}
							detail={
								<Tooltip
									arrow
									variant="fancy"
									placement="left"
									title="What is this?"
									message={managementMap[management].tooltipMessage}
								>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<InfoIcon
											sx={{
												height: ({ size }) => size.xS,
												width: ({ size }) => size.xS,
											}}
										/>
									</Box>
								</Tooltip>
							}
						/>
					),
					value: management,
				}))}
				name="management"
				lockedLabel={!formValues.os && 'Choose management level'}
				onEditClick={() => {
					onValuesChange((prev) => ({
						...prev,
						controlPanel: '',
					}));
				}}
			/>

			{showControlPanel && (
				<>
					<Spacer />
					<HookedCollapsingRadioTiles
						options={controlPanelOptionsSorted.map(({ value, price }) => ({
							label: (
								<OptionLabel
									description={value}
									icon={<ControlPanelIcon controlPanel={value} />}
									detail={
										typeof price === 'number'
											? `${formatCurrency(price, {
													plusSign: true,
												})}/mo`
											: null
									}
								/>
							),
							value,
						}))}
						name="controlPanel"
						lockedLabel={
							(!formValues.os || !formValues.management) &&
							'Choose control panel'
						}
					/>
				</>
			)}
		</Stack>
	);
};

export default FormInputs;
