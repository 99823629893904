// @ts-check

/** @type {import("club-sauce/public/asset/index.raw").LWApiPublicAssetDetailsParamsRawI['alsowith']} */
const hostingDetailsAlsowith = [
	'nocworxRemoteDetails',
	'privateParent',
	'featureDetails',
	'categories',
	'capabilities',
	'networkSummary',
	'children',
	'backupsAndStorage',
	'resourceState',
	'diskDetails',
	'mesDetails',
	'powerStatus',
	'zone',
	'instance',
	'machine',
	'region',
	'template',
];

export default hostingDetailsAlsowith;
