import React from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import Stack from 'undercurrent/Stack';
import { useInput } from 'components/LWForm/LWForm';
import CollapsingRadioTiles from 'components/standard/CollapsingRadioTiles';
import combineSx from 'undercurrent/utility/combineSx';

/**
 * @param {Omit<import('react').ComponentProps<typeof CollapsingRadioTiles>, 'value'> & {
 *   helperTextProps?: import('@mui/material').FormHelperTextProps;
 * }} props
 * */
const HookedCollapsingRadioTiles = ({
	name,
	onChange,
	helperTextProps,
	...rest
}) => {
	const { sx: formHelperTextSx, ...restHelperTextProps } =
		helperTextProps || {};
	const { value, onValueChange, error, validate } = useInput(name);

	if (!name) {
		return null;
	}

	/** @param {import('react').ChangeEvent<HTMLInputElement>} event */
	const handleChange = (event) => {
		if (onChange) {
			onChange(event);
		}

		onValueChange(event.target.value);

		if (error) {
			validate(event.target);
		}
	};

	return (
		<Stack gap={150}>
			<CollapsingRadioTiles
				name={name}
				onChange={handleChange}
				value={value}
				sx={{
					borderColor: ({ palette }) =>
						error ? palette.uc.danger.border.strong : 'undefined',
				}}
				{...rest}
			/>
			{error && (
				<FormHelperText
					error
					sx={combineSx(formHelperTextSx, {
						color: ({ palette }) => palette.uc.danger.text.strong,
						typography: ({ typography }) => typography['label-lg'],
						margin: 0,
					})}
					{...restHelperTextProps}
				>
					{error}
				</FormHelperText>
			)}
		</Stack>
	);
};

export default HookedCollapsingRadioTiles;
