// @ts-check

/** @param {string} osName */
export const checkIsWpOptimized = (osName) => {
	return osName.toLowerCase().includes('wordpress optimized');
};

/**
 * removes square backets and contents from osName (for example:
 * [App:WordPress Optimized])
 * @param {string} osName */
export const stripOsName = (osName) => {
	return osName.replace(/\s*\[.*?\]\s*/g, '');
};

/**
 * - removes square backets and contents from osName (for example:
 * [App:WordPress Optimized])
 * - just returns the osVersion if is windows
 * @param {object} params
 * @param {string} params.osName
 * @param {string} params.osVersion
 * @param {boolean} params.isWindows
 * */
export const createOsDescription = ({ osName, osVersion, isWindows }) => {
	const osNameStripped = stripOsName(osName);
	return isWindows ? osVersion : `${osNameStripped} ${osVersion}`;
};

/**
 * @param {object} params
 * @param {string} params.osName
 * @param {string} params.osVersion
 * @param {string} params.osBit
 * @returns {string}
 */
export const createOsValue = ({ osName, osVersion, osBit }) =>
	JSON.stringify({ osName, osVersion, osBit });

/** @type {{[key: string]: {label: string, tooltipMessage: string, displayOrder: number}}} */
export const managementMap = {
	'Self-Managed': {
		label: 'Self managed',
		tooltipMessage:
			'Management and support of the system and applications is provided by the user',
		displayOrder: 1,
	},
	'Core-Managed': {
		label: 'Core managed',
		tooltipMessage:
			'Includes installation, maintenance, and support of core software packages',
		displayOrder: 2,
	},
	'Fully-Managed': {
		label: 'Fully managed',
		tooltipMessage:
			'Our support team monitors the server, intervenes during detected outages, and helps maintain security through managed updates',
		displayOrder: 3,
	},
};
