import React from 'react';

import Box from 'undercurrent/Box';
import Typography from 'undercurrent/Typography';
import TypographyOverflow from 'undercurrent/TypographyOverflow';

/**
 * @param {object} props
 * @param {import('react').ReactNode} [props.icon]
 * @param {import('react').ReactNode} props.description
 * @param {import('react').ReactNode} [props.chip]
 * @param {import('react').ReactNode} [props.detail]
 */
const OptionLabel = ({ icon, description, chip, detail }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				gap: 100,
			}}
		>
			<Box
				sx={{ display: 'flex', alignItems: 'center', gap: 100, minWidth: 0 }}
			>
				{icon}
				<TypographyOverflow variant="title-xs" fontWeight={500}>
					{description}
				</TypographyOverflow>
				{chip}
			</Box>
			<Typography
				variant="label-lg"
				sx={{
					color: ({ palette }) => palette.uc.text.weak,
					whiteSpace: 'nowrap',
				}}
			>
				{detail}
			</Typography>
		</Box>
	);
};

export default OptionLabel;
