// @ts-check
import { useMutation } from '@tanstack/react-query';
import api from 'modules/queries/api';
import useSnackbarError from 'utility/hooks/useSnackbarError';

const useVmWareVCloudVdcLoginUrl = () => {
	const snackbarError = useSnackbarError('Failed to fetch VMWare login URL');

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/vmWare/vCloud/vDC/index.raw').LWApiPublicVmWareVCloudVdcLoginUrlParamsRawI} params */
			(params) => api.vmWare.vCloud.vdc.apiRaw.loginUrl(params),

		onError: snackbarError,
	});
};

export default useVmWareVCloudVdcLoginUrl;
