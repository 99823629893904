import React from 'react';

import { Link } from 'react-router-dom';

import Box from 'undercurrent/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from 'undercurrent/Stack';
import Typography from 'undercurrent/Typography';
import PurifyHtml from 'components/atoms/PurifyHtml';

import Button from 'undercurrent/Button';
import ChevronLeftIcon from 'undercurrent/icons/lucide/ChevronLeft';
import CircleXIcon from 'undercurrent/icons/lucide/CircleX';
import TriangleAlertIcon from 'undercurrent/icons/lucide/TriangleAlert';

import {
	CRITICAL_SEVERITY,
	ERROR_SEVERITY,
	WARNING_SEVERITY,
} from 'utility/constants/notificationConstants';

/**
 * @typedef AlertDetailsProps
 * @property {import('club-sauce/public/notifications/index.raw').LWApiPublicNotificationDetailsResultRawI} alert
 */

/**
 * @param {AlertDetailsProps & import('undercurrent/types/svgTypes').LucideSvgProps} props
 */
const StatusIcon = ({ alert, ...rest }) => {
	if ([CRITICAL_SEVERITY, ERROR_SEVERITY].includes(alert?.severity)) {
		return (
			<CircleXIcon
				sx={(theme) => ({
					color: theme.palette.uc.danger.icon.strong,
					flexShrink: 0,
				})}
				{...rest}
			/>
		);
	}
	if (WARNING_SEVERITY === alert?.severity) {
		return (
			<TriangleAlertIcon
				sx={(theme) => ({
					color: theme.palette.uc.warning.icon.strong,
					flexShrink: 0,
				})}
				{...rest}
			/>
		);
	}

	if (!alert?.enddate) {
		return <CircularProgress size={16} sx={{ flexShrink: 0 }} />;
	}

	return null;
};

/**
 * @typedef AlertDetailsPropsI
 * @property {import('club-sauce/public/notifications/index.raw').LWApiPublicNotificationDetailsResultRawI} alert
 * @property {() => void} onActionClick
 * @property {() => void} onCloseClick
 * @property {(id: string) => void} onDismissClick
 */

/**
 * @param {AlertDetailsPropsI} props
 */
const AlertDetails = ({
	alert,
	onActionClick,
	onCloseClick,
	onDismissClick,
}) => {
	const replaceLineBreaks = (content) => content?.replace(/\n/g, '<br>');
	return (
		<>
			<Box>
				<Button
					variant="link"
					sx={{ padding: 'initial', height: 'initial' }}
					onClick={onCloseClick}
				>
					<ChevronLeftIcon
						sx={(theme) => ({
							color: theme.palette.uc.icon.weak,
							marginRight: theme.spacing(50),
						})}
					/>
					<Typography
						variant="label-md"
						sx={(theme) => ({ color: theme.palette.uc.text.weak })}
						fontWeight={500}
					>
						All alerts
					</Typography>
				</Button>
			</Box>
			<Stack flex={1} spacing={200}>
				{alert?.domain && (
					<Typography variant="body-md" fontWeight={500}>
						{alert?.domain}
					</Typography>
				)}
				{alert?.last_alert && (
					<Stack spacing={50}>
						<Typography variant="body-sm" fontWeight={500}>
							Status
						</Typography>
						<Stack direction="row" spacing={50} alignItems="center">
							<StatusIcon alert={alert} />

							<Typography component="div" variant="body-sm">
								<PurifyHtml html={replaceLineBreaks(alert?.last_alert)} />
							</Typography>
						</Stack>
					</Stack>
				)}
				{alert?.description && (
					<Stack spacing={50}>
						<Typography variant="body-sm" fontWeight={500}>
							Description
						</Typography>
						<Typography component="div" variant="body-sm">
							<PurifyHtml html={replaceLineBreaks(alert?.description)} />
						</Typography>
					</Stack>
				)}
			</Stack>
			<Stack
				sx={(theme) => ({
					position: 'sticky',
					bottom: 0,
					zIndex: 1,
					paddingTop: theme.spacing(100),
					paddingBottom: theme.spacing(200),
					backgroundColor: theme.palette.uc.bg.main,
				})}
				spacing={100}
			>
				{alert?.uniq_id && (
					<Button
						size="small"
						component={Link}
						to={`/servers/${alert?.uniq_id}`}
						onClick={onActionClick}
					>
						Go to server
					</Button>
				)}
				<Button
					variant="secondary"
					size="small"
					onClick={() => onDismissClick(alert.id)}
				>
					Dismiss
				</Button>
			</Stack>
		</>
	);
};

export default AlertDetails;
