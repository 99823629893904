import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import LWDialog from 'components/common/LWDialog';
import LWLink from 'components/common/LWLink';
import LWTypography from 'components/common/LWTypography';
import useAccountUserList from 'modules/queries/account/user/useList';
import { useLocation } from 'react-router-dom';

const InsecureVpnUsersDialog = () => {
	const { pathname } = useLocation();
	const [open, setOpen] = useState(false);
	const [hasSeenDialog, setHasSeenDialog] = useState(false);

	const { data: usersData } = useAccountUserList({
		page_size: 9999,
		active: 1,
		alsowith: ['acls'],
	});

	const vpnUsers =
		usersData?.items.filter((user) => user.roles.includes('VPNUser')) || [];

	const insecureVpnUsers = vpnUsers.filter(
		(user) => !user.tfa_enabled && !user?.acls?.length,
	);
	const hasInsecureVpnUsers = insecureVpnUsers.length > 0;

	useEffect(() => {
		if (hasSeenDialog) return;

		const shouldShowInsecureVpnUsersDialog =
			hasInsecureVpnUsers && !pathname.startsWith('/account/users');
		if (shouldShowInsecureVpnUsersDialog) {
			setOpen(true);
		}
	}, [hasInsecureVpnUsers, pathname, hasSeenDialog]);

	const handleClose = (event, reason) => {
		if (reason === 'backdropClick') return;
		setOpen(false);
		setHasSeenDialog(true);
	};

	return (
		<LWDialog open={open} onClose={handleClose} color="danger">
			<LWTypography bold BoxProps={{ mb: 1 }}>
				To continue using the CloudVPN, you must set up Two-factor
				Authentication for the following users:
			</LWTypography>
			<Box mb={1}>
				{insecureVpnUsers.map((user) => (
					<Box key={user.username}>
						<LWTypography>
							{user.username}{' '}
							<LWLink
								to={`/account/users/${user.username}/authentication`}
								onClick={handleClose}
							>
								&#40;Enable&#41;
							</LWLink>
						</LWTypography>
					</Box>
				))}
			</Box>
			<LWTypography>
				Two-factor Authentication enhances the security of your VPN by adding an
				extra layer of protection. Please complete this setup before proceeding.{' '}
				<LWLink to="https://www.liquidweb.com/help-docs/enabling-two-factor-authentication-2fa-for-cloudvpn/">
					Learn more.
				</LWLink>
			</LWTypography>
		</LWDialog>
	);
};

export default InsecureVpnUsersDialog;
