import React, { lazy } from 'react';
import get from 'lodash/get';

import Home from '@material-ui/icons/HomeRounded';
import Dns from '@material-ui/icons/DnsRounded';
import Language from '@material-ui/icons/LanguageRounded';
import SettingsSystemDaydream from '@material-ui/icons/SettingsSystemDaydreamRounded';
import AddShoppingCart from '@material-ui/icons/AddShoppingCartRounded';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Layers from '@material-ui/icons/LayersRounded';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import assetTypes from 'utility/constants/asset/assetTypes';
import ServersRouter from 'components/routers/ServersRouter';
import HomeRouter from 'components/routers/HomeRouter';
import ServicesRouter from 'components/routers/ServicesRouter';
import ShopRouter from 'components/routers/ShopRouter';
import DomainsRouter from 'components/routers/DomainsRouter';
import BillingRouter from 'components/routers/BillingRouter';
import AccountRouter from 'components/routers/AccountRouter';
import ProjectsRouter from 'components/routers/ProjectsRouter';
import SecureNotesBadge from 'containers/badges/SecureNotesBadge';
import {
	account,
	billing,
	domain,
	home,
	projects,
	servers,
	services,
	shop,
} from './navData';

const DomainSearch = lazy(
	() => import('containers/pages/domains/Register/Search'),
);

const HomePage = lazy(() => import('containers/pages/Home'));
const Servers = lazy(() => import('containers/pages/servers/Servers'));
const SSHKeys = lazy(() => import('containers/pages/servers/SSHKeys/Page'));
const ScheduledTasks = lazy(
	() => import('containers/pages/servers/ScheduledTasks'),
);
const Domains = lazy(() => import('containers/pages/domains/Domains'));
const DnsZones = lazy(() => import('containers/pages/domains/dns'));
const Ssls = lazy(() => import('containers/pages/domains/Ssls'));
const LoadBalancerList = lazy(
	() => import('containers/pages/services/LoadBalancersContainer'),
);

const AccountMain = lazy(() => import('containers/pages/account/AccountMain'));
const SecureNotes = lazy(
	() => import('containers/pages/account/secureNotes/SecureNotes'),
);
const Users = lazy(() => import('containers/pages/account/users/Users'));
const BillingPage = lazy(() => import('containers/pages/billing/BillingPage'));
const Policies = lazy(() => import('containers/pages/account/Policies'));
const History = lazy(() => import('containers/pages/account/History'));
const BillingPay = lazy(() => import('containers/pages/billing/BillingPay'));
const Transactions = lazy(
	() =>
		import(
			'containers/pages/billing/details/TransactionTimeline/TransactionTimelinePage'
		),
);
const PaymentMethodPage = lazy(
	() => import('containers/pages/billing/details/PaymentMethodPage'),
);
const ProjectCreate = lazy(
	() => import('containers/pages/projects/ProjectCreate'),
);
const ProjectList = lazy(() => import('containers/pages/projects/ProjectList'));

const CloudBlockStorageList = lazy(
	() =>
		import('containers/pages/services/CloudBlockStorage/CloudBlockStorageList'),
);

const ThreatDown = lazy(() => import('containers/pages/services/ThreatDown'));

const VPNs = lazy(() => import('containers/pages/services/VPNs'));

const Acronis = lazy(() => import('containers/pages/services/Acronis'));
const CloudPrivateNetworks = lazy(
	() => import('containers/pages/services/CloudPrivateNetworks'),
);
const AdvancedServices = lazy(
	() => import('containers/pages/services/AdvancedServices'),
);
const FirewallPresets = lazy(
	() => import('containers/pages/services/FirewallPresets'),
);
const ServicesPage = lazy(() => import('containers/pages/services/Services'));

const Marketplace = lazy(() => import('containers/pages/shop/Marketplace'));

const navMap = {
	home: {
		...home,
		icon: (props) => <Home {...props} />,
		isExact: true,
		router: () => <HomeRouter navData={get(navMap, 'home')} />,
		component: () => <HomePage navData={get(navMap, 'home')} />,
	},
	projects: {
		...projects,
		icon: (props) => <Layers {...props} />,
		component: () => <ProjectList navData={get(navMap, 'projects')} />,
		router: () => <ProjectsRouter navData={get(navMap, 'projects')} />,
		subNav: {
			create: {
				...projects.subNav.create,
				component: () => (
					<ProjectCreate
						navData={get(navMap, ['projects', 'subNav', 'create'])}
					/>
				),
			},
		},
	},
	servers: {
		...servers,
		icon: (props) => <Dns {...props} />,
		router: () => <ServersRouter navData={get(navMap, 'servers')} />,
		component: () => <Servers navData={get(navMap, 'servers')} />,
		subNav: {
			sshKeys: {
				...servers.subNav.sshKeys,
				component: () => (
					<SSHKeys navData={get(navMap, ['servers', 'subNav', 'sshKeys'])} />
				),
			},
			migrationCenter: {
				...servers.subNav.migrationCenter,
			},
			scheduledTasks: {
				...servers.subNav.scheduledTasks,
				component: () => <ScheduledTasks />,
			},
		},
	},

	domain: {
		...domain,
		icon: (props) => <Language {...props} />,
		component: () => (
			<Domains navData={get(navMap, 'domain')} assetType={assetTypes.ALL} />
		),
		router: () => <DomainsRouter navData={get(navMap, 'domain')} />,
		subNav: {
			registration: {
				...domain.subNav.registration,
				component: () => <DomainSearch />,
			},
			transfer: {
				...domain.subNav.transfer,
			},
			dns: {
				...domain.subNav.dns,
				component: () => <DnsZones />,
			},
			cloudFlare: {
				...domain.subNav.cloudFlare,
			},
			ssl: {
				...domain.subNav.ssl,
				component: () => <Ssls />,
			},
		},
	},
	services: {
		...services,
		icon: (props) => <SettingsSystemDaydream {...props} />,
		component: () => <ServicesPage navData={get(navMap, 'services')} />,
		router: () => <ServicesRouter navData={get(navMap, 'services')} />,
		subNav: {
			loadBalancers: {
				...services.subNav.loadBalancers,
				component: () => (
					<LoadBalancerList
						navData={get(navMap, ['services', 'subNav', 'loadBalancers'])}
					/>
				),
			},
			cloudPrivateNetworks: {
				...services.subNav.cloudPrivateNetworks,
				component: () => (
					<CloudPrivateNetworks
						navData={get(navMap, [
							'services',
							'subNav',
							'cloudPrivateNetworks',
						])}
					/>
				),
			},
			advancedServices: {
				...services.subNav.advancedServices,
				component: () => (
					<AdvancedServices
						navData={get(navMap, ['services', 'subNav', 'advancedServices'])}
					/>
				),
			},
			cloudBlockStorage: {
				...services.subNav.cloudBlockStorage,
				component: () => (
					<CloudBlockStorageList
						navData={get(navMap, ['services', 'subNav', 'cloudBlockStorage'])}
					/>
				),
			},
			firewallPresets: {
				...services.subNav.firewallPresets,
				component: () => (
					<FirewallPresets
						navData={get(navMap, ['services', 'subNav', 'firewallPresets'])}
					/>
				),
			},
			acronis: {
				...services.subNav.acronis,
				component: () => (
					<Acronis navData={get(navMap, ['services', 'subNav', 'acronis'])} />
				),
			},
			threatDown: {
				...services.subNav.threatDown,
				component: () => (
					<ThreatDown
						navData={get(navMap, ['services', 'subNav', 'threatDown'])}
					/>
				),
			},
			VPNs: {
				...services.subNav.VPNs,
				component: () => <VPNs />,
			},
		},
	},
	shop: {
		...shop,
		icon: (props) => <AddShoppingCart {...props} />,
		component: () => <Marketplace />,

		router: () => <ShopRouter navData={get(navMap, 'shop')} />,
		subNav: {
			domain: {
				...shop.subNav.domain,
				component: () => <DomainSearch />,
			},
			marketplace: {
				...shop.subNav.marketplace,
				component: () => <Marketplace />,
			},
			cart: {
				...shop.subNav.cart,
			},
			checkout: {
				...shop.subNav.checkout,
			},
		},
	},
	billing: {
		...billing,
		icon: (props) => <MonetizationOn {...props} />,
		component: () => <BillingPage navData={get(navMap, 'billing')} />,
		router: () => <BillingRouter navData={get(navMap, 'billing')} />,
		subNav: {
			pay: {
				...billing.subNav.pay,
				component: () => (
					<BillingPay
						parent={get(navMap, 'billing')}
						navData={get(navMap, ['billing', 'subNav', 'pay'])}
					/>
				),
			},
			transactions: {
				...billing.subNav.transactions,
				component: () => (
					<Transactions
						parent={get(navMap, 'billing')}
						navData={get(navMap, ['billing', 'subNav', 'transactions'])}
					/>
				),
			},
			paymentMethod: {
				...billing.subNav.paymentMethod,
				component: () => (
					<PaymentMethodPage
						parent={{
							name: 'Billing',
							path: '/billing',
						}}
						navData={get(navMap, ['billing', 'subNav', 'paymentMethod'])}
					/>
				),
			},
		},
	},
	account: {
		...account,
		icon: (props) => <AccountCircle {...props} />,
		component: () => <AccountMain navData={get(navMap, 'account')} />,
		router: () => <AccountRouter navData={get(navMap, 'account')} />,
		subNav: {
			users: {
				...account.subNav.users,
				component: () => (
					<RoleRestrictedRoute
						path="/account/users"
						component={Users}
						parent={{
							name: 'Account',
							path: '/account',
						}}
						navData={get(navMap, ['account', 'subNav', 'users'])}
					/>
				),
			},
			secureNotes: {
				...account.subNav.secureNotes,
				Badge: SecureNotesBadge,
				component: () => (
					<SecureNotes
						navData={get(navMap, ['account', 'subNav', 'secureNotes'])}
					/>
				),
			},
			billing: {
				...account.subNav.billing,
				icon: (props) => <MonetizationOn {...props} />,
				component: () => (
					<BillingPage
						navData={get(navMap, ['account', 'subNav', 'billing'])}
					/>
				),
				router: () => (
					<BillingRouter
						navData={get(navMap, ['account', 'subNav', 'billing'])}
					/>
				),
				subNav: {
					pay: {
						...account.subNav.billing.subNav.pay,
						component: () => (
							<BillingPay
								parent={get(navMap, ['account'])}
								navData={get(navMap, [
									'account',
									'subNav',
									'billing',
									'subNav',
									'pay',
								])}
							/>
						),
					},
					transactions: {
						...account.subNav.billing.subNav.transactions,
						component: () => (
							<Transactions
								parent={get(navMap, ['account'])}
								navData={get(navMap, [
									'account',
									'subNav',
									'billing',
									'subNav',
									'transactions',
								])}
							/>
						),
					},
					paymentMethod: {
						...account.subNav.billing.subNav.paymentMethod,
						component: () => (
							<PaymentMethodPage
								parent={{
									name: 'Account',
									path: '/account',
								}}
								navData={get(navMap, [
									'account',
									'subNav',
									'billing',
									'subNav',
									'paymentMethod',
								])}
							/>
						),
					},
				},
			},
			policies: {
				...account.subNav.policies,
				component: () => <Policies />,
			},
			history: {
				...account.subNav.history,
				component: () => <History />,
			},
			pay: {
				...account.subNav.pay,
				component: () => (
					<BillingPay
						parent={get(navMap, ['account'])}
						navData={get(navMap, [
							'account',
							'subNav',
							'billing',
							'subNav',
							'pay',
						])}
					/>
				),
			},
			transactions: {
				...account.subNav.transactions,
				component: () => (
					<Transactions
						parent={get(navMap, ['account'])}
						navData={get(navMap, [
							'account',
							'subNav',
							'billing',
							'subNav',
							'transactions',
						])}
					/>
				),
			},
			paymentMethod: {
				...account.subNav.paymentMethod,
				component: () => (
					<PaymentMethodPage
						parent={{
							name: 'Account',
							path: '/account',
						}}
						navData={get(navMap, [
							'account',
							'subNav',
							'billing',
							'subNav',
							'paymentMethod',
						])}
					/>
				),
			},
		},
	},
};

export { navMap };
export default navMap;
