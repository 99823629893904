import { spawn } from 'redux-saga/effects';
import { sagas as destroySagas } from './destroy';
import { sagas as ipSagas } from './ip';
import { sagas as resizeSagas } from './resize';
import { sagas as consoleSagas } from './console';
import { sagas as rebootSagas } from './reboot';
import { sagas as taskSchedulerSagas } from './taskScheduler';

export default function* sagas() {
	yield spawn(destroySagas);
	yield spawn(ipSagas);
	yield spawn(rebootSagas);
	yield spawn(resizeSagas);
	yield spawn(taskSchedulerSagas);
	yield spawn(consoleSagas);
}
