/**
 * Starts the chat programmatically using the ChatMiaw API
 * Includes a retry mechanism if the API is not immediately available
 */
const startChatProgrammatically = () => {
	const maxRetries = 10; // 5 seconds total (500ms * 10)
	let retryCount = 0;

	const tryOpenChat = () => {
		// @ts-ignore - ChatMiaw API is injected globally
		if (window.LC_API?.open_chat_window) {
			// @ts-ignore - ChatMiaw API is injected globally
			window.LC_API.open_chat_window();
			return true;
		}
		return false;
	};

	const retryOpenChat = () => {
		if (retryCount >= maxRetries) {
			// eslint-disable-next-line no-console
			console.warn('Reached max ChatMiaw retry attempts');
			return;
		}

		if (!tryOpenChat()) {
			retryCount += 1;
			setTimeout(retryOpenChat, 500); // Retry every 500ms
		}
	};

	if (!tryOpenChat()) {
		retryOpenChat();
	}
};

export default startChatProgrammatically;
