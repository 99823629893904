import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import compose from 'utility/compose';
import startChatProgrammatically from 'utility/chat/startChatProgrammatically';

export const styles = ({ palette, spacing }) => ({
	button: {
		'&&': {
			backgroundColor: palette.primary.main,
			textTransform: 'none',
			borderRadius: '360px',
			color: palette.secondary.contrastText,
			padding: `${spacing(1.5)}px ${spacing(3)}px`,
			fontSize: '18px',
			'&:hover': {
				background: palette.primary.main,
			},
		},
	},
	spinner: {
		marginLeft: '1em',
		color: palette.secondary.contrastText,
	},
});

const ChatButton = ({ classes }) => (
	<Button onClick={startChatProgrammatically} className={classes.button}>
		Chat with us
	</Button>
);

ChatButton.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(ChatButton);

export { ChatButton };
