// @ts-check
import React, { useState } from 'react';

import Box from 'undercurrent/Box';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Typography from 'undercurrent/Typography';
import LWForm from 'components/LWForm';
import hostingDetailsAlsowith from 'containers/pages/servers/details/hostingDetailsAlsowith';
import useAssetDetails from 'modules/queries/asset/useDetails';
import useNetworkIpListPublic from 'modules/queries/network/ip/useListPublic';
import useServerAuthDetails from 'modules/queries/server/auth/useDetails';
import useServerAuthUpdate from 'modules/queries/server/auth/useUpdate';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { useDispatch } from 'react-redux';
import Button from 'undercurrent/Button';
import DrawerContent from 'undercurrent/DrawerContent';
import DrawerFooter from 'undercurrent/DrawerFooter';
import DrawerHeader from 'undercurrent/DrawerHeader';
import InfoIcon from 'undercurrent/icons/lucide/Info';
import Tooltip from 'undercurrent/Tooltip';
import isPort from 'validator/es/lib/isPort';
import {
	HookedSubmit,
	HookedTextField,
	HookedTextFieldPassword,
} from '../Form';

const Loader = () => (
	<>
		<DrawerContent>
			<Skeleton variant="rounded" sx={{ height: '220px' }} />
			<Skeleton variant="rounded" sx={{ height: '312px' }} />
		</DrawerContent>
		<DrawerFooter sx={{ display: 'flex', flexDirection: 'column', gap: 100 }}>
			<Skeleton variant="rounded" sx={{ height: (theme) => theme.size.lg }} />
			<Skeleton variant="rounded" sx={{ height: (theme) => theme.size.lg }} />
		</DrawerFooter>
	</>
);

/**
 * @param {object} props
 * @param {() => void} props.onClose
 * @param {import('club-sauce/public/network/ip/index.raw').LWApiPublicNetworkIpListPublicResultRaw['items']} [props.publicIps]
 * @param {import('club-sauce/public/server/auth/index.raw').LWApiPublicServerAuthDetailsResultRawI} [props.serverAuthDetails]
 * @param {string} props.uniqId
 * */
const SupportAccessForm = ({
	onClose,
	publicIps,
	serverAuthDetails,
	uniqId,
}) => {
	const dispatch = useDispatch();
	const {
		special_instructions: initialSpecialInstructions,
		alternate_ssh_ip: initialSshIpRestrictions,
		alternate_ssh_port: initialSshPort,
		login_username: initialSshUsername,
	} = serverAuthDetails || {};

	const { data: assetDetails } = useAssetDetails({
		uniq_id: uniqId,
		alsowith: hostingDetailsAlsowith,
	});

	const { template } = assetDetails || {};
	const isWindows = Boolean(
		template?.os_type.toLowerCase().includes('windows'),
	);
	const command = isWindows ? '' : 'SSH ';

	const [formValues, setFormValues] = useState({
		rootPassword: '',
		sshPassword: '',
		whmAccessKey: '',
		specialInstructions: initialSpecialInstructions || '',
		sshIpRestrictions: initialSshIpRestrictions || '',
		sshPort: initialSshPort?.toString() || '',
		sshUsername: initialSshUsername || '',
	});
	const [formErrors, setFormErrors] = useState({});

	const { mutate: updateServerAuth, isLoading: isUpdatingServerAuth } =
		useServerAuthUpdate();

	const handleSubmit = () => {
		const {
			rootPassword,
			specialInstructions,
			sshIpRestrictions,
			sshPassword,
			sshPort,
			sshUsername,
			whmAccessKey,
		} = formValues;

		updateServerAuth(
			{
				alternate_ssh_ip: sshIpRestrictions,
				alternate_ssh_port: sshPort,
				login_password: sshPassword,
				login_username: sshUsername,
				password: rootPassword,
				special_instructions: specialInstructions,
				uniq_id: uniqId,
				whm_access_key: whmAccessKey,
			},
			{
				onSuccess: () => {
					onClose();
					dispatch(
						snackbarActions.pushMessage({
							variant: 'success',
							message: 'Successfully saved support access details',
						}),
					);
				},
			},
		);
	};

	return (
		<LWForm
			values={formValues}
			// @ts-expect-error
			onValuesChange={setFormValues}
			errors={formErrors}
			onErrorsChange={setFormErrors}
			onSubmit={handleSubmit}
			validators={{
				sshPort: (value) => {
					if (!value) return '';

					return isPort(value.toString()) ? '' : 'Not a valid port';
				},
			}}
		>
			<DrawerContent>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 300 }}>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: 200 }}>
						<Box sx={{ display: 'flex', gap: 100, alignItems: 'center' }}>
							<Typography fontWeight={500} variant="title-xs">
								Current Credentials
							</Typography>
							<Tooltip
								arrow
								variant="fancy"
								title="What is this?"
								message="This updates the password our support team has on file for this server, it
			does not change your server password"
							>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<InfoIcon />
								</Box>
							</Tooltip>
						</Box>

						<Typography variant="body-md">
							In order to provide you with support, we may need to log into your
							server. Keeping us up-to-date with login information allows us to
							be proactive and minimize delays in supporting you.
						</Typography>

						<Typography variant="body-sm">
							<i>
								If you have forgotten your password or need assistance changing
								it, please contact our Support team.
							</i>
						</Typography>
					</Box>
					<HookedTextFieldPassword
						name="rootPassword"
						label={isWindows ? 'Administrator password' : 'Root password'}
						placeholder="Current password"
					/>
					<Typography fontWeight={500} variant="body-md" textAlign="center">
						or
					</Typography>
					<HookedTextField
						name="sshUsername"
						label={`Alternative ${command}username`}
						placeholder="Username"
					/>
					<HookedTextFieldPassword
						name="sshPassword"
						label={`Alternative ${command}password`}
						placeholder="Password"
					/>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 300 }}>
					<Typography fontWeight={500} variant="title-xs">
						Optional additional info
					</Typography>
					<HookedTextField
						name="sshIpRestrictions"
						select
						label={`${command}IP restrictions`}
						slotProps={{ select: { displayEmpty: true } }}
					>
						<MenuItem value="">No restrictions</MenuItem>
						{publicIps?.map(({ ip }) => {
							if (!ip) return null;

							return (
								<MenuItem key={ip} value={ip}>
									{ip}
								</MenuItem>
							);
						})}
					</HookedTextField>
					<HookedTextField
						name="sshPort"
						label={command ? `${command}port` : 'Port'}
						placeholder="Port number"
					/>
					{!isWindows && (
						<HookedTextField
							name="whmAccessKey"
							multiline
							label="WHM access key"
							placeholder="Paste key here"
							minRows={4}
						/>
					)}
					<HookedTextField
						name="specialInstructions"
						multiline
						label="Special instructions"
						placeholder="Add any instructions here"
						minRows={4}
					/>
				</Box>
			</DrawerContent>
			<DrawerFooter sx={{ display: 'flex', flexDirection: 'column', gap: 100 }}>
				<HookedSubmit variant="primary" loading={isUpdatingServerAuth}>
					Submit
				</HookedSubmit>
				<Button variant="ghost" onClick={onClose}>
					Cancel
				</Button>
			</DrawerFooter>
		</LWForm>
	);
};

/** @param {Omit<import('@mui/material').DrawerProps, 'onClose'> & { onClose: () => void; uniqId: string }} drawerProps */
const DrawerSupportAccess = ({ onClose, uniqId, open, ...drawerProps }) => {
	const { data: publicIpsData, isLoading: publicIpsIsLoading } =
		useNetworkIpListPublic(
			{
				uniq_id: uniqId,
				expand_ips: 1,
				page_size: 9999,
			},
			{ enabled: Boolean(open) },
		);

	const { data: serverAuthDetailsData, isLoading: serverAuthDetailsIsLoading } =
		useServerAuthDetails(
			{
				uniq_id: uniqId,
			},
			{ enabled: Boolean(open) },
		);

	return (
		<Drawer anchor="right" onClose={onClose} open={open} {...drawerProps}>
			<DrawerHeader onClose={onClose}>Support access</DrawerHeader>
			{publicIpsIsLoading || serverAuthDetailsIsLoading ? (
				<Loader />
			) : (
				<SupportAccessForm
					publicIps={publicIpsData?.items}
					serverAuthDetails={serverAuthDetailsData}
					uniqId={uniqId}
					onClose={onClose}
				/>
			)}
		</Drawer>
	);
};

export default DrawerSupportAccess;
