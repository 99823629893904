// @ts-check
import React, { useState } from 'react';

import Box from 'undercurrent/Box';
import MuiCard from '@mui/material/Card';
import Drawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from 'undercurrent/Stack';
import Typography from 'undercurrent/Typography';
import ThirdpartyExclude from 'components/common/ThirdpartyExclude';
import useAccountChatPasswordGet from 'modules/queries/account/chatPassword/useGet';
import useAccountDetails from 'modules/queries/account/useDetails';
import CopyButton from 'undercurrent/CopyButton';
import DrawerContent from 'undercurrent/DrawerContent';
import DrawerHeader from 'undercurrent/DrawerHeader';
import EyeIcon from 'undercurrent/icons/animated/Eye';
import PhoneIcon from 'undercurrent/icons/lucide/Phone';

/**
 * @param {object} props
 * @param {string} [props.credentials]
 * @param {boolean} [props.isLoading]
 */
const Credentials = ({ credentials, isLoading }) => {
	const [showCredentials, setShowCredentials] = useState(false);

	return (
		<Stack
			direction="row"
			alignItems="center"
			gap={100}
			justifyContent="space-between"
		>
			{isLoading ? (
				<Skeleton variant="text" width={100} />
			) : (
				<>
					<ThirdpartyExclude>
						<Typography>
							{showCredentials ? credentials : '••••••••••••••••••••••••'}
						</Typography>
					</ThirdpartyExclude>
					<Box
						onClick={() => setShowCredentials((prev) => !prev)}
						sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
					>
						<EyeIcon
							show={showCredentials}
							sx={{
								color: ({ palette }) => palette.uc.icon.weak,
								height: ({ size }) => size.xS,
								width: ({ size }) => size.xS,
								'&:hover': {
									color: ({ palette }) => palette.uc.icon.main,
								},
							}}
						/>
					</Box>
				</>
			)}
		</Stack>
	);
};

/**
 * @param {object} props
 * @param {React.ReactNode} props.label
 * @param {React.ReactNode} props.value
 * @param {boolean} [props.isLoading]
 */
const KeyItem = ({ label, value, isLoading }) => (
	<Stack gap={50}>
		<Typography
			variant="label-xl"
			sx={{ color: ({ palette }) => palette.uc.text.weak }}
		>
			{label}
		</Typography>
		{isLoading ? <Skeleton variant="text" width={100} /> : value}
	</Stack>
);

/**
 * @param {object} props
 * @param {string} props.title
 * @param {React.ReactNode} props.children
 */
const Card = ({ title, children }) => (
	<MuiCard
		sx={{
			gap: 200,
			display: 'flex',
			flexDirection: 'column',
			padding: 200,
		}}
	>
		<Typography variant="title-xs" fontWeight={500}>
			{title}
		</Typography>
		{children}
	</MuiCard>
);

const USPhoneNumber = '1-800-580-4985';
const InternationalPhoneNumber = '1-517-322-0434';

/** @param {Omit<import('@mui/material').DrawerProps, 'onClose'> & { onClose: () => void }} drawerProps */
const DrawerSupportCall = ({ onClose, open, ...drawerProps }) => {
	const { data: chatPasswordData, isLoading: chatPasswordLoading } =
		useAccountChatPasswordGet({
			enabled: Boolean(open),
		});
	const { data: accountDetailsData, isLoading: accountDetailsLoading } =
		useAccountDetails({}, { enabled: Boolean(open) });

	/** @param {string} value */
	const handleCopy = (value) => {
		navigator.clipboard.writeText(value);
	};

	return (
		<Drawer anchor="right" onClose={onClose} open={open} {...drawerProps}>
			<DrawerHeader
				onClose={onClose}
				description="Everything you need to get the conversation started, all in one place"
			>
				Give us a call
			</DrawerHeader>

			<DrawerContent>
				<Card title="Contact info">
					<Stack gap={50}>
						<KeyItem
							label={
								<Stack direction="row" gap={50} alignItems="center">
									<PhoneIcon />
									US Phone
								</Stack>
							}
							value={
								<Stack
									direction="row"
									gap={100}
									alignItems="center"
									justifyContent="space-between"
								>
									<Link
										href={`tel:${USPhoneNumber}`}
										variant="body-md"
										fontWeight={500}
									>
										{USPhoneNumber}
									</Link>
									<CopyButton
										onClick={() => handleCopy(USPhoneNumber)}
										iconButtonProps={{ variant: 'barebones' }}
										tooltipProps={{ placement: 'right' }}
									/>
								</Stack>
							}
						/>
					</Stack>

					<Stack gap={50}>
						<KeyItem
							label={
								<Stack direction="row" gap={50} alignItems="center">
									<PhoneIcon />
									International Phone
								</Stack>
							}
							value={
								<Stack
									direction="row"
									gap={100}
									alignItems="center"
									justifyContent="space-between"
								>
									<Link
										href={`tel:${InternationalPhoneNumber}`}
										variant="body-md"
										fontWeight={500}
									>
										{InternationalPhoneNumber}
									</Link>
									<CopyButton
										onClick={() => handleCopy(InternationalPhoneNumber)}
										iconButtonProps={{ variant: 'barebones' }}
										tooltipProps={{ placement: 'right' }}
									/>
								</Stack>
							}
						/>
					</Stack>
				</Card>

				<Card title="Account info">
					<KeyItem
						label="Acct. ID"
						value={accountDetailsData?.accnt}
						isLoading={accountDetailsLoading}
					/>
					<KeyItem
						label="Support passphrase"
						value={
							<Credentials
								credentials={chatPasswordData?.password}
								isLoading={chatPasswordLoading}
							/>
						}
					/>
				</Card>
			</DrawerContent>
		</Drawer>
	);
};

export default DrawerSupportCall;
