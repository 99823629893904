import React, { useEffect, useRef, useState } from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from 'undercurrent/Typography';

/** @param {import('@mui/material').TypographyProps & { tooltipProps?: import('@mui/material').tooltipProps }} */
const TypographyOverflow = ({ children, sx, tooltipProps, ...rest }) => {
	const typographyRef = useRef(null);

	const [open, setOpen] = useState(false);
	const [hasOverflow, setHasOverflow] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setHasOverflow(
				typographyRef.current?.offsetWidth < typographyRef.current?.scrollWidth,
			);
		};

		const resizeObserver = new ResizeObserver(handleResize);
		resizeObserver.observe(typographyRef.current);

		return () => {
			resizeObserver.disconnect();
		};
	}, [typographyRef]);

	const handleOpen = () => {
		if (hasOverflow) {
			setOpen(true);
		}
	};

	return (
		<Tooltip
			title={children}
			open={open}
			onOpen={handleOpen}
			onClose={() => setOpen(false)}
			{...tooltipProps}
		>
			<Typography
				ref={typographyRef}
				sx={{
					overflow: 'hidden',
					textWrap: 'nowrap',
					textOverflow: 'ellipsis',
					minWidth: 0,
					...sx,
				}}
				{...rest}
			>
				{children}
			</Typography>
		</Tooltip>
	);
};

export default TypographyOverflow;
