import { useRef, useMemo, useCallback } from 'react';
import api from 'modules/queries/api';
import useAccountUserStatedataListforkey from './useListForKey';
import useAccountUserStatedataAdd from './useAdd';
import useAccountUserStatedataUpdate from './useUpdate';

window.resetMeta = () =>
	api.account.user.stateData.apiRaw.replace({ key: 'meta', data: '[]' });

/**
 * @typedef UserMetaStateStatusI
 * @prop {boolean} isLoading
 * @prop {boolean} isFetching
 * @prop {boolean} isBusy
 * @prop {boolean} isError
 * @prop {unknown} error
 */

/**
 * Account User Statedata helper hook
 * @template T
 * @param {string} name
 * @param {T} initialValue
 * @returns {[T, (value: T) => void, UserMetaStateStatusI]} [value, setValue, { isLoading, isBusy, isError }]
 */
export const useUserMetaState = (name, initialValue) => {
	const {
		data: listData,
		isLoading,
		isFetching,
		isError,
		error,
	} = useAccountUserStatedataListforkey({
		key: 'meta',
	});
	const { mutate: addMutate, isLoading: isAddLoading } =
		useAccountUserStatedataAdd();
	const { mutate: updateMutate, isLoading: isUpdateLoading } =
		useAccountUserStatedataUpdate();

	// Hold pending for isFetching state
	const pendingRef = useRef(initialValue);

	/** @type {{ uuid: string, name: string, value: T } | undefined} */
	const dataRow = useMemo(() => {
		if (!(listData?.data instanceof Array)) return undefined;

		const matches = listData.data.filter((row) => row.name === name);

		// Accidents happen, pick the most recent updated
		return matches.sort((a, b) => (b?.updated || 0) - (a?.updated || 0))[0];
	}, [name, listData]);
	const uuid = dataRow?.uuid;

	// Store setValue in a ref so it doesn't bother downstream dependancies
	const setValueRef = useRef();
	setValueRef.current = (value) => {
		pendingRef.current = value;

		if (!uuid) {
			addMutate({
				key: 'meta',
				data: JSON.stringify({
					value,
					name,
					updated: Date.now(),
				}),
			});
		} else {
			updateMutate({
				key: 'meta',
				data: JSON.stringify({
					uuid,
					value,
					name,
					updated: Date.now(),
				}),
			});
		}
	};

	const setValue = useCallback((value) => {
		setValueRef.current(value);
	}, []);

	const isBusy = isFetching || isAddLoading || isUpdateLoading;
	const value = isBusy || !dataRow ? pendingRef.current : dataRow.value;

	return [value, setValue, { isLoading, isFetching, isBusy, isError, error }];
};

export default useUserMetaState;
